<template>
	<div v-if="loading" style="display: flex; justify-content: center; flex-wrap: wrap; gap: 10px">
		<div v-for="card in 15" :key="card">
			<v-skeleton-loader type="image" width="200px" height="125px" />
		</div>
	</div>
	<div v-else>
		<h5 style="padding: 5px 10px">{{ title }}</h5>
		<div style="display: flex; justify-content: center; flex-wrap: wrap; gap: 10px">
			<div v-for="card in cards" :key="card.label">
				<v-card min-width="200px" :style="card.label === 'Pipeline' ? 'border: 1px solid orange' : ''">
					<v-card-text style="padding: 5px 10px">
						<v-row>
							<v-col sm="10" class="display-3" align="left">
								{{ card.total }}
							</v-col>
							<v-col sm="2" style="margin: 10px 0px 0px -50px">
								<v-icon v-if="card.icon" tag="span" color="grey" style="font-size: 3em">
									{{ card.icon }}
								</v-icon>
								<img v-if="card.image" :src="card.image" width="48px" />
							</v-col>
						</v-row>
						<v-row style="margin: -5px -12px">
							<v-col align="left">{{ card.label }}</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
	name: "NumberCards",
	props: ["loading", "title", "stats", "conversionList"],
	created() {
		if (this.stats) this.formatStats();
	},
	watch: {
		stats: function () {
			this.formatStats();
		},
	},
	data() {
		return { cards: [] };
	},
	methods: {
		formatStats() {
			this.cards = [];
			let total = 0;
			for (const group of this.stats) {
				const info = this.conversionList.find(e => e.key === group._id);

				this.cards.push({
					total: group.total,
					label: info.name,
					icon: info.icon,
					image: info.image,
				});

				total += group.total;
			}

			this.cards.unshift({
				total: total,
				label: "Total",
				icon: "mdi-all-inclusive",
				left: "-40px",
			});
		},
	},
});
</script>
