import { api } from "../utils/request";

async function getComments(group, ref) {
	let query = "";
	query += group ? `${query ? "&" : "?"}group=${group}` : "";
	query += ref ? `${query ? "&" : "?"}ref=${ref}` : "";

	const res = await api({
		method: "get",
		url: `/api/comments${query}`,
	});

	return res;
}

async function addComment(comment) {
	const res = await api({
		method: "post",
		url: "/api/comments",
		data: comment,
	});

	return res;
}

async function editComment(comment) {
	const res = await api({
		method: "put",
		url: `/api/comments/${comment._id}`,
		data: comment,
	});

	return res;
}

async function deleteComment(id) {
	const res = await api({
		method: "delete",
		url: `/api/comments/${id}`,
	});

	return res;
}

export { getComments, addComment, editComment, deleteComment };
