<style scoped>
.avatar {
	width: 64px;
	height: 64px;
	border-radius: 50%;
	overflow: hidden;
	margin: 10px;
}
.avatar img {
	width: 72px;
	margin-left: -7px;
}
</style>
<template>
	<v-card :style="`padding: ${inline ? 20 : 0}px`">
		<div style="display: flex; justify-content: flex-end; align-items: center">
			<v-tabs v-if="types" centered v-model="type">
				<v-tab value="">Tudo</v-tab>
				<v-tab
					v-for="t in types.filter(t =>
						features.listCommentsBroker ? true : !['broker', 'commercialDirector'].includes(t.value),
					)"
					:key="t.value"
					:value="t.value"
				>
					{{ t.name }}
				</v-tab>
			</v-tabs>
			<v-btn v-if="comments.length" icon @click="archive = !archive" style="margin-right: 15px">
				<v-icon>{{ archive ? "mdi-comment" : "mdi-archive" }}</v-icon>
			</v-btn>
		</div>
		<v-list three-line align="left" style="overflow: auto; max-height: 290px">
			<template v-for="comment in filteredComments">
				<v-list-item :key="comment._id">
					<div v-if="comment.user.image" class="avatar">
						<img :src="getFileUrl(comment.user.image)" />
					</div>
					<v-list-item-avatar v-else color="primary" size="64">
						<v-icon v-text="formatAvatarName(comment.user.name)" />
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title v-html="comment.message.replace(/\n/g, '<br />')" style="white-space: pre-wrap" />
						<v-list-item-subtitle>
							<b>{{ comment.user.name }}</b>
							{{ `・ ${formatDate(comment._created, "DD-MM-YYYY HH:mm")} ` }}
							<v-tooltip top>
								<template v-slot:activator="{ on, attrs }">
									<span v-bind="attrs" v-on="on">
										{{ comment.lastEditDate && "・ Editado" }}
									</span>
								</template>
								<span>{{ formatDate(comment.lastEditDate, "DD-MM-YYYY HH:mm") }}</span>
							</v-tooltip>
						</v-list-item-subtitle>
					</v-list-item-content>
					<v-list-item-action>
						<v-btn v-if="archive" icon @click.stop="() => handleDeleteComment(comment)">
							<v-icon>mdi-restore</v-icon>
						</v-btn>
						<span v-else>
							<v-btn v-if="user._id === comment.user._id" icon @click="handleEditComment(comment)">
								<v-icon>mdi-pencil</v-icon>
							</v-btn>
							<DeletionDialog
								v-if="user._id === comment.user._id"
								deletionText="este comentário"
								:onConfirm="() => handleDeleteComment(comment)"
							>
								<template v-slot:activator="{ onClick }">
									<v-btn icon @click="onClick">
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</template>
							</DeletionDialog>
						</span>
					</v-list-item-action>
				</v-list-item>
				<v-divider :key="`${comment._id}-divider`" />
			</template>
		</v-list>
		<div :style="`padding: 0px ${inline ? 0 : 10}px`">
			<v-textarea outlined v-model="comment" label="Comentário" min-height="145px" hide-details />
			<v-btn color="primary" width="100%" @click="handleSubmitComment" style="margin: 5px 0px">
				<v-icon dark>mdi-send</v-icon>
			</v-btn>
		</div>
	</v-card>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";

import { getComments, addComment, editComment, deleteComment } from "../api/comments";

import { formatDate, formatAvatarName, getFileUrl } from "../utils/utils";

import DeletionDialog from "../components/DeletionDialog.vue";

export default Vue.extend({
	name: "Comments",
	components: { DeletionDialog },
	props: ["group", "id", "types", "inline", "onSubmit"],
	created() {
		this.handleGetComments();

		if (this.types) {
			if (this.user.role === "creditManager") {
				this.type = 2;
			} else if (this.user.role === "processManager") {
				this.type = 1;
			}
		}
	},
	watch: {
		group: function () {
			this.handleGetComments();
		},
		id: function () {
			this.handleGetComments();
		},
		type: function () {
			this.filterComments();
		},
		archive: function () {
			this.filterComments();
		},
	},
	computed: {
		...mapState(["user", "company", "token", "features"]),
		apiUrl() {
			return process.env.VUE_APP_API_URL;
		},
	},
	data() {
		return {
			comments: [],
			filteredComments: [],

			archive: false,

			comment: "",
			type: 0,

			selectedComment: null,
		};
	},
	methods: {
		formatDate,
		formatAvatarName,
		getFileUrl,
		filterComments() {
			if (this.type) {
				this.filteredComments = this.comments.filter(
					c => c.active !== this.archive && c.type === this.types[this.type - 1].value,
				);
			} else {
				this.filteredComments = this.comments.filter(
					c =>
						c.active !== this.archive &&
						(this.features.listCommentsBroker ? true : !["broker", "commercialDirector"].includes(c.type)),
				);
			}
		},
		async handleGetComments() {
			const response = await getComments(this.group, this.id);

			if (response.status === 200) {
				this.comments = response.data;

				if (this.types && ["creditManager", "broker"].includes(this.user.role)) {
					this.type = 2;
				} else if (this.types && ["processManager", "commercialDirector"].includes(this.user.role)) {
					this.type = 1;
				}

				this.filterComments();
			}
		},
		async handleSubmitComment() {
			const { comment, selectedComment } = this;

			let response = null;
			if (selectedComment) {
				response = await editComment({ _id: selectedComment._id, message: comment });
			} else {
				response = await addComment({
					_id: this.id,
					group: this.group,
					ref: this.id,
					message: comment,
					type: this.type ? this.types[this.type - 1].value : null,
				});
			}

			if ([200, 201].includes(response.status)) {
				this.handleGetComments();
				if (!selectedComment && this.onSubmit) this.onSubmit(1);

				this.comment = "";
				this.selectedComment = null;
			}
		},
		async handleDeleteComment(item) {
			const response = await deleteComment(item._id);

			if (response.status === 200) {
				this.handleGetComments();
				if (this.onSubmit) this.onSubmit(this.archive ? 1 : -1);
			}
		},
		async handleEditComment(item) {
			this.comment = item.message;
			this.selectedComment = item;
		},
	},
});
</script>
