import { api } from "../utils/request";

async function getFindings(page, perPage, sortBy, sortDesc, filters) {
	const res = await api({
		method: "post",
		url: "/api/findings/search",
		data: { page, perPage, sortBy, sortDesc, filters },
	});

	return res;
}

async function getFindingsFilters() {
	const res = await api({
		method: "get",
		url: "/api/findings/filters",
	});

	return res;
}

async function getFindingInfo(url, ref) {
	const res = await api({
		method: "post",
		url: "/api/findings/info",
		data: { url, ref },
	});

	return res;
}

async function addFinding(finding) {
	const res = await api({
		method: "post",
		url: "/api/findings",
		data: finding,
	});

	return res;
}

async function editFinding(finding) {
	const res = await api({
		method: "put",
		url: `/api/findings/${finding._id}`,
		data: finding,
	});

	return res;
}

async function patchFinding(finding) {
	const res = await api({
		method: "patch",
		url: `/api/findings/${finding._id}`,
		data: finding,
	});

	return res;
}

async function deleteFinding(id) {
	const res = await api({
		method: "delete",
		url: `/api/findings/${id}`,
	});

	return res;
}

async function editComment(comment) {
	const res = await api({
		method: "put",
		url: `/api/comments/${comment._id}`,
		data: comment,
	});

	return res;
}

async function deleteComment(id) {
	const res = await api({
		method: "delete",
		url: `/api/comments/${id}`,
	});

	return res;
}

export {
	getFindings,
	getFindingsFilters,
	getFindingInfo,
	addFinding,
	editFinding,
	patchFinding,
	deleteFinding,
	editComment,
	deleteComment,
};
