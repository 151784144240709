import { api } from "../utils/request";

async function getTasks(ref, isUser, includeDone) {
	let query = "";
	query += ref ? `${query ? "&" : "?"}ref=${ref}` : "";
	query += isUser ? `${query ? "&" : "?"}isUser=${isUser}` : "";
	query += includeDone ? `${query ? "&" : "?"}includeDone=${includeDone}` : "";

	const res = await api({
		method: "get",
		url: `/api/tasks${query}`,
	});

	return res;
}

async function addTask(task) {
	const res = await api({
		method: "post",
		url: "/api/tasks",
		data: task,
	});

	return res;
}

async function editTask(task) {
	const res = await api({
		method: "put",
		url: `/api/tasks/${task._id}`,
		data: task,
	});

	return res;
}

async function patchTask(task) {
	const res = await api({
		method: "patch",
		url: `/api/tasks/${task._id}`,
		data: task,
	});

	return res;
}

async function deleteTask(id) {
	const res = await api({
		method: "delete",
		url: `/api/tasks/${id}`,
	});

	return res;
}

export { getTasks, addTask, editTask, patchTask, deleteTask };
