<template>
	<v-skeleton-loader v-if="loading" type="image,image" width="100%" />
	<v-card v-else>
		<v-card-title>Estatísticas Mensais</v-card-title>
		<apexchart
			v-if="totals.length"
			type="line"
			width="100%"
			height="350"
			:options="chartOptions"
			:series="totals"
		/>
	</v-card>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import dayjs from "dayjs";

import { getLeadStats } from "../../api/stats";

export default Vue.extend({
	name: "LineGraph",
	async created() {
		this.loading = true;

		await this.handleGetStats();

		this.loading = false;
	},
	watch: {
		"$vuetify.theme.dark": function () {
			this.setColors(this.$vuetify.theme.dark);
		},
		selectedOffices: function () {
			this.handleGetStats();
		},
		dateRange: function () {
			this.handleGetStats();
		},
		personal: function () {
			this.handleGetStats();
		},
		recruiters: function () {
			this.handleGetStats();
		},
		selectedSources: function () {
			this.handleGetStats();
		},
	},
	computed: mapState(["selectedOffices", "dateRange", "config"]),
	data() {
		return {
			loading: false,

			totals: [],
			chartOptions: {
				chart: {
					width: "100%",
					height: 350,
					type: "line",
					zoom: {
						enabled: false,
					},
					toolbar: {
						show: false,
					},
				},
				dataLabels: {
					enabled: true,
				},
				stroke: {
					width: 7,
					curve: "smooth",
				},
				legend: {
					labels: {
						colors: "#ffffff",
					},
				},
				grid: { show: false },
				xaxis: {
					labels: {
						style: {
							colors: "#ffffff",
						},
					},
					categories: [],
				},
				yaxis: {
					labels: {
						style: {
							colors: "#ffffff",
						},
					},
				},
				tooltip: {
					theme: "dark",
				},
			},
		};
	},
	methods: {
		async handleGetStats() {
			const response = await getLeadStats(this.personal, this.recruiters, this.selectedSources);

			if (response.status === 200) {
				this.setColors(this.$vuetify.theme.dark);

				this.totals = [];

				const firstDate = dayjs(this.dateRange[0]);
				const lastDate = dayjs(this.dateRange[1]);

				const days = lastDate.diff(firstDate, "days");
				let mode = null;
				if (days <= 30) {
					mode = "daily";
				} else {
					mode = "monthly";
				}

				const firstDay = 0;
				const lastDay = dayjs(lastDate).diff(dayjs(firstDate), mode === "monthly" ? "months" : "days");

				const dates = [];
				const cloneDate = firstDate.clone();
				const categories = [];
				for (let i = firstDay; i <= lastDay; i++) {
					const currentDate = mode === "monthly" ? cloneDate.add(i, "month") : cloneDate.add(i, "day");

					if (mode === "monthly") {
						const date = response.data.groupedByDate.find(
							p => p._id.month === currentDate.get("month") + 1 && p._id.year === currentDate.get("year"),
						);

						dates.push(date);
					} else {
						const date = response.data.groupedByDate.find(p => p._id.day === currentDate.get("date"));

						dates.push(date);
					}

					categories.push(mode === "monthly" ? currentDate.format("M-YYYY") : currentDate.format("DD-MMM"));
				}

				this.chartOptions = { ...this.chartOptions, xaxis: { ...this.chartOptions.xaxis, categories } };

				const goals = ["recruiterAssigned", "interviewScheduled", "interviewDone", "recruited"];
				for (const goal of goals) {
					this.totals.push({
						name: this.config.statTypes.find(t => t.key === goal).name,
						data: dates.map(d => (d ? d[goal] : 0)),
					});
				}
			}
		},
		setColors(darkMode) {
			const textColor = darkMode ? "#ffffff" : "#000000";

			this.chartOptions = {
				...this.chartOptions,
				legend: { labels: { colors: textColor } },
				xaxis: { labels: { style: { colors: textColor } } },
				yaxis: { labels: { style: { colors: textColor } } },
				tooltip: { theme: darkMode ? "dark" : "light" },
			};
		},
	},
});
</script>
