<template>
	<v-data-table
		:headers="headers"
		:items="referencedBy"
		:expanded="expanded"
		:search="search"
		:sort-by="sortBy"
		:sort-desc="sortDesc"
		:loading="loading"
		item-key="_id"
		show-expand
		must-sort
		no-data-text="Não há resultados"
		no-results-text="Não há resultados"
		:footer-props="{
			itemsPerPageOptions: [10, 20, 50, -1],
			itemsPerPageText: 'Linhas',
			itemsPerPageAllText: 'Tudo',
			showCurrentPage: true,
			showFirstLastPage: true,
		}"
		fixed-header
		height="810px"
	>
		<template v-slot:top>
			<v-toolbar>
				<v-toolbar-title>Referências</v-toolbar-title>
				<v-spacer />
				<v-checkbox outlined v-model="active" hide-details />
				<v-tooltip v-if="features.generateReferencesDrafts && !user.gmailRefreshToken" top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							v-bind="attrs"
							v-on="on"
							icon
							:href="`https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${redirect}/gmail&prompt=consent&access_type=offline&response_type=code&client_id=557887385987-ls6a5j9ep453c0qi02r8ddiumlhkb4m0.apps.googleusercontent.com&scope=https://www.googleapis.com/auth/gmail.compose https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events`"
						>
							<v-icon>mdi-gmail</v-icon>
						</v-btn>
					</template>
					<span>Ligar com o Gmail</span>
				</v-tooltip>
				<v-tooltip v-if="features.generateReferencesDrafts && user.gmailRefreshToken" top>
					<template v-slot:activator="{ on, attrs }">
						<v-btn v-bind="attrs" v-on="on" icon @click="handleGenerateReferencesDrafts">
							<v-icon>mdi-email</v-icon>
						</v-btn>
					</template>
					<span>Gerar emails das referências</span>
				</v-tooltip>
				<v-btn icon @click="copyAllReferences">
					<v-icon>mdi-content-copy</v-icon>
				</v-btn>
				<v-text-field
					outlined
					dense
					v-model="search"
					append-icon="mdi-magnify"
					label="Procurar"
					style="margin-right: 10px; max-width: 300px"
					hide-details
				/>
			</v-toolbar>
		</template>
		<template v-slot:item.name="{ item }">
			<span style="cursor: pointer" @click="openCurrentAccount(item)">{{ item.name }}</span>
			<v-badge v-if="!item.active" inline dot :value="!item.active" color="error" />
			<v-badge
				v-else-if="!item.offices.some(o => selectedOffices.includes(o))"
				inline
				dot
				:value="!item.offices.some(o => selectedOffices.includes(o))"
				color="primary"
			/>
		</template>
		<template v-slot:item.office="{ item }">
			{{ allOffices.find(o => o.key === item.offices[0]).name }}
		</template>
		<template v-slot:item.startDate="{ item }">
			<td>{{ formatDate(item.startDate, "DD-MM-YYYY") }}</td>
		</template>
		<template v-for="i in quarterKeys" :slot="`item.totals.${i}`" slot-scope="{ item }">
			<span v-if="item.totals[i]" :key="i">
				<span :style="calculateColor(item.totals[i], i, 'billing')">
					{{ formatNumber(item.totals[i].billing) }}
				</span>
				<br />
				<span :style="calculateColor(item.totals[i], i, 'billing')">
					{{ formatNumber(item.totals[i].commissions) }}
				</span>
				<br />
				<v-btn small icon @click="copyReferences(item, i)">
					<v-icon small>mdi-content-copy</v-icon>
				</v-btn>
			</span>
		</template>
		<template slot="body.append">
			<td style="text-align: left; padding-left: 15px; font-size: 1.5em">Total</td>
			<td v-if="selectedOffices.length > 1" />
			<td />
			<td v-for="quarter in quarterKeys" :key="quarter" style="text-align: right; padding-right: 15px">
				<span v-if="total[quarter]">
					<span :style="calculateColor(total[quarter], quarter, 'billing')">
						{{ formatNumber(total[quarter].billing) }}
					</span>
					<br />
					<span :style="calculateColor(total[quarter], quarter, 'billing')">
						{{ formatNumber(total[quarter].commissions) }}
					</span>
				</span>
			</td>
			<td />
		</template>
		<template v-slot:expanded-item="{ headers, item }">
			<td colspan="8" style="padding: 0">
				<tr v-for="reference in item.references" :key="reference._id">
					<td align="left" style="width: 185px; font-size: 0.85em; padding-left: 20px">
						{{ reference.name }}
						<v-badge v-if="!reference.active" inline dot :value="!reference.active" color="error" />
						<v-badge
							v-else-if="!item.offices.some(o => selectedOffices.includes(o))"
							inline
							dot
							:value="!item.offices.some(o => selectedOffices.includes(o))"
							color="primary"
						/>
					</td>
					<td style="width: 150px; font-size: 0.85em; padding-left: 20px">
						{{ formatDate(reference.startDate, "DD-MM-YYYY") }}
					</td>
					<td
						v-for="quarter in quarterKeys"
						:key="quarter"
						align="right"
						style="width: 105px; padding: 5px 15px 5px 0px"
					>
						<span v-if="reference.totals && reference.totals[quarter]">
							<span :style="calculateColor(reference.totals[quarter], quarter, 'billing')">
								{{ formatNumber(reference.totals[quarter].billing) }}
							</span>
							<br />
							<span :style="calculateColor(reference.totals[quarter], quarter, 'billing')">
								{{ formatNumber(reference.totals[quarter].commissions) }}
							</span>
						</span>
					</td>
				</tr>
			</td>
		</template>
	</v-data-table>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import chroma from "chroma-js";
import copy from "copy-to-clipboard";

import { getReferencesStats } from "../../api/stats";
import { generateReferencesDrafts } from "../../api/gmail";

import { formatDate, formatNumber } from "../../utils/utils";

export default Vue.extend({
	name: "References",
	created() {
		if (this.selectedOffices.length > 1) {
			this.headers.splice(1, 0, { text: "Agência", value: "office", width: 100 });
		} else {
			this.headers = this.headers.filter(h => h.value !== "office");
		}

		this.handleGetReferences();
	},
	watch: {
		selectedOffices: function () {
			const officeHeader = this.headers.find(h => h.value === "office");

			if (officeHeader) {
				if (this.selectedOffices.length === 1) {
					this.headers = this.headers.filter(h => h.value !== "office");
				}
			} else if (this.selectedOffices.length > 1) {
				this.headers.splice(1, 0, { text: "Agência", value: "office", width: 100 });
			}

			this.handleGetReferences();
		},
		dateRange: function () {
			this.handleGetReferences();
		},
		active: function () {
			this.handleGetReferences();
		},
	},
	computed: {
		...mapState(["dateRange", "allOffices", "selectedOffices", "features", "redirect", "user"]),
	},
	data() {
		const billingSort = (a, b) => ((a ? a.billing : 0) > (b ? b.billing : 0) ? 1 : -1);

		return {
			headers: [
				{ text: "Nome", value: "name", width: 185 },
				{ text: "Data de Entrada", value: "startDate", align: "center" },
				{ text: "1º Trimestre", value: "totals.1", align: "right", width: 105, sort: billingSort },
				{ text: "2º Trimestre", value: "totals.2", align: "right", width: 105, sort: billingSort },
				{ text: "3º Trimestre", value: "totals.3", align: "right", width: 105, sort: billingSort },
				{ text: "4º Trimestre", value: "totals.4", align: "right", width: 105, sort: billingSort },
				{ text: "Total", value: "totals.yearly", align: "right", width: 105, sort: billingSort },
				{ text: "", value: "data-table-expand" },
			],
			search: "",
			sortBy: "totals.yearly",
			sortDesc: true,
			loading: false,
			expanded: [],

			active: true,

			referencedBy: [],
			total: { yearly: {} },
			quarterInfo: {},

			quarterKeys: ["1", "2", "3", "4", "yearly"],

			scale: chroma.scale(["red", "orange", "goldenrod", "green"]),
		};
	},
	methods: {
		...mapMutations(["openToast", "login"]),
		formatDate,
		formatNumber,
		async handleGetReferences() {
			this.loading = true;

			const response = await getReferencesStats(this.active);

			if (response.status === 200) {
				this.quarterInfo = response.data.quarterInfo;
				this.referencedBy = [];

				for (const id in response.data.referencedBy) {
					const individual = response.data.referencedBy[id];

					if (id === "total") {
						this.total = individual;
					} else {
						this.referencedBy.push(individual);
					}
				}

				this.referencedBy.sort((a, b) =>
					(a.totals.yearly ? a.totals.yearly.billing : 0) < (b.totals.yearly ? b.totals.yearly.billing : 0)
						? 1
						: -1,
				);
			}

			this.loading = false;
		},
		calculateColor(value, period, type) {
			const number = value ? value[type] : 0;

			const ratio = number / (this.total[period] ? this.total[period].averageBilling : 0);

			const color = this.scale(ratio);

			return { color };
		},
		copyReferences(referencedBy, quarter) {
			let lines = "";

			for (const reference of referencedBy.references) {
				lines += "<tr>";
				lines += `<td>${reference.name}</td>`;
				lines += `<td align="right">
					<span>${
						reference.totals && reference.totals[quarter]
							? formatNumber(reference.totals[quarter].billing, false, true)
							: 0
					}</span>
				</td>
				<td align="right">
					<span>${
						reference.totals && reference.totals[quarter]
							? formatNumber(
									(reference.totals[quarter].billing / this.quarterInfo[quarter].total) * 100,
									false,
									true,
							  )
							: 0
					}%</span>
				</td>
				<td align="right">
					<span>${
						reference.totals && reference.totals[quarter]
							? formatNumber(reference.totals[quarter].commissions, false, true)
							: 0
					}</span>
				</td>`;
				lines += "</tr>";
			}

			lines += "<tr>";
			lines += "<td><b>Total</b></td>";
			lines += `<td align="right">
					<span>${formatNumber(referencedBy.totals[quarter].billing, false, true)}</span>
				</td>
				<td align="right">
					<span>${formatNumber(
						(referencedBy.totals[quarter].billing / this.quarterInfo[quarter].total) * 100,
						false,
						true,
					)}%</span>
				</td>
				<td align="right">
					<span>${formatNumber(referencedBy.totals[quarter].commissions, false, true)}</span>
				</td>`;
			lines += "</tr>";

			copy(
				`<style>
					table, th, td { border: 1px solid black; border-collapse: collapse; font-size: 0.9em }
				</style>
				<table>
					<tr>
						<th>Faturação Trimestre</th>
						<th>€ Disponibilizados</th>
					</tr>
					<tr>
						<td align="right">${formatNumber(this.quarterInfo[quarter].total, false, true)}</td>
						<td align="right">${formatNumber(this.quarterInfo[quarter].amountForReferences, false, true)}</td>
					</tr>
				</table>
				<br/>
				<table>
					<tr>
						<th>Membros</th>
						<th>Faturação</th>
						<th>%</th>
						<th>A Receber</th>
					</tr>
					${lines}
				</table>`,
				{ asHtml: true, format: "text/html" },
			);
		},
		copyAllReferences() {
			let lines = "";

			for (const consultant of this.referencedBy) {
				lines += "<tr>";
				lines += `<td>${consultant.name}</td>`;
				lines += `<td>${formatDate(consultant.startDate, "DD-MM-YYYY")}</td>`;

				for (const quarter of this.quarterKeys) {
					lines += `<td align="right">
						<span>${
							consultant.totals && consultant.totals[quarter]
								? formatNumber(consultant.totals[quarter].billing, false, true)
								: 0
						}</span>
						<br />
						<span>${
							consultant.totals && consultant.totals[quarter]
								? formatNumber(consultant.totals[quarter].commissions, false, true)
								: 0
						}</span>
					</td>`;
				}

				lines += "</tr>";

				for (const reference of consultant.references) {
					lines += "<tr>";
					lines += `<td> --- ${reference.name}</td>`;
					lines += `<td>${formatDate(reference.startDate, "DD-MM-YYYY")}</td>`;

					for (const quarter of this.quarterKeys) {
						lines += `<td align="right">
							<span>${
								reference.totals && reference.totals[quarter]
									? formatNumber(reference.totals[quarter].billing, false, true)
									: 0
							}</span>
							<br />
							<span>${
								reference.totals && reference.totals[quarter]
									? formatNumber(reference.totals[quarter].commissions, false, true)
									: 0
							}</span>
						</td>`;
					}

					lines += "</tr>";
				}

				lines += "</tr>";
			}

			lines += "<tr>";
			lines += "<td><b>Total</b></td>";
			lines += "<td />";

			for (const quarter of this.quarterKeys) {
				lines += `<td align="right">
					<span>${this.total[quarter] ? formatNumber(this.total[quarter].billing, false, true) : 0}</span>
					<br />
					<span>${this.total[quarter] ? formatNumber(this.total[quarter].commissions, false, true) : 0}</span>
				</td>`;
			}

			lines += "</tr>";

			copy(
				`<style>
					table, th, td { border: 1px solid black; border-collapse: collapse; font-size: 0.9em }
				</style>
				<table>
					<tr>
						<th>Faturação Anual</th>
						<th>€ Disponibilizados</th>
					</tr>
					<tr>
						<td align="right">${formatNumber(this.quarterInfo.yearly.total, false, true)}</td>
						<td align="right">${formatNumber(this.quarterInfo.yearly.amountForReferences, false, true)}</td>
					</tr>
				</table>
				<br/>
				<table>
					<tr>
						<th>Consultor</th>
						<th>Data de Entrada</th>
						<th>1º Trimestre</th>
						<th>2º Trimestre</th>
						<th>3º Trimestre</th>
						<th>4º Trimestre</th>
						<th>Total</th>
					</tr>
					${lines}
				</table>`,
				{ asHtml: true, format: "text/html" },
			);
		},
		async handleGenerateReferencesDrafts() {
			this.loading = true;

			const response = await generateReferencesDrafts(this.active);

			if (response.status === 401) {
				this.login({ user: { ...this.user, gmailRefreshToken: false } });
			} else if (response.status === 400) {
				this.openToast({ message: "Escolha uma agência", color: "red" });
			}

			this.loading = false;
		},
	},
});
</script>
