import { api } from "../utils/request";

async function getChanges(model, id) {
	const res = await api({
		method: "get",
		url: `/api/changes/${model}/${id}`,
	});

	return res;
}

export { getChanges };
