import { api } from "../utils/request";

async function login(user) {
	const res = await api({
		method: "post",
		url: "/api/auth/login",
		data: user,
	});

	return res;
}

async function getConfig() {
	const res = await api({
		method: "get",
		url: "/api/auth/config",
	});

	return res;
}

async function editConfig(config) {
	const res = await api({
		method: "put",
		url: "/api/auth/config",
		data: config,
	});

	return res;
}

async function forgotPassword(user) {
	const res = await api({
		method: "post",
		url: "/api/auth/forgot-password",
		data: user,
	});

	return res;
}

async function changePassword(user, reset) {
	const res = await api({
		method: "patch",
		url: `/api/auth/${reset ? "reset" : "change"}-password`,
		data: user,
	});

	return res;
}

function logout() {
	const localData = localStorage.getItem("darkMode");
	const darkMode = localData ? JSON.parse(localData) : true;

	localStorage.clear();

	localStorage.setItem("darkMode", darkMode);

	window.location.replace("/");
}

export { login, getConfig, editConfig, forgotPassword, changePassword, logout };
