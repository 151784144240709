<template>
	<v-container fluid class="mx-auto">
		<div v-if="user.role === 'consultant'">
			<StaffStats />
		</div>
		<div v-else>
			<center style="margin-bottom: 5px">
				<v-btn-toggle v-model="tab" mandatory>
					<v-btn v-for="group in groups" :key="group.pos" :value="group.pos">{{ group.name }}</v-btn>
				</v-btn-toggle>
				<v-btn
					v-if="features.customDashboard"
					fab
					small
					color="primary"
					@click="showWidgetsForm = !showWidgetsForm"
					style="margin: 10px"
				>
					<v-icon>mdi-plus</v-icon>
				</v-btn>
			</center>
			<center v-if="hasStaffWidgets" style="margin: 10px">
				<v-select
					outlined
					multiple
					v-model="selectedRoles"
					:items="config.roles"
					item-text="name"
					item-value="key"
					label="Cargos"
					hide-details
					height="50px"
					style="max-width: 252px"
				>
					<template slot="selection" slot-scope="{ item, index }">
						<v-chip small v-if="!index">{{ item.name }}</v-chip>
						<span class="grey--text caption" v-else-if="index === 1"> + {{ selectedRoles.length - 1 }}</span>
					</template>
				</v-select>
			</center>
			<div
				v-if="hasLeadsWidgets"
				style="display: flex; flex-direction: row; justify-content: center; gap: 10px; margin: 10px"
			>
				<v-select
					outlined
					multiple
					small-chips
					v-model="personal"
					:items="[
						{ name: 'Pessoais', value: true },
						{ name: 'Empresa', value: false },
					]"
					item-text="name"
					item-value="value"
					label="Tipo de Lead"
					hide-details
					style="max-width: 162px"
				>
					<template slot="selection" slot-scope="{ item, index }">
						<v-chip small v-if="!index">{{ item.name }}</v-chip>
						<span class="grey--text caption" v-else-if="index === 1"> + {{ personal.length - 1 }}</span>
					</template>
				</v-select>
				<v-select
					outlined
					multiple
					small-chips
					v-model="recruiters"
					:items="recruitersList"
					item-text="name"
					item-value="_id"
					label="Recrutadores"
					hide-details
					style="max-width: 202px"
				>
					<template slot="selection" slot-scope="{ item, index }">
						<v-chip small v-if="!index">{{ item.name }}</v-chip>
						<span class="grey--text caption" v-else-if="index === 1"> + {{ recruiters.length - 1 }}</span>
					</template>
				</v-select>
				<v-select
					outlined
					multiple
					v-model="selectedSources"
					:items="config.sources"
					item-text="name"
					item-value="key"
					label="Fontes"
					hide-details
					height="50px"
					style="max-width: 252px"
				>
					<template slot="selection" slot-scope="{ item, index }">
						<v-chip small v-if="!index">{{ item.name }}</v-chip>
						<span class="grey--text caption" v-else-if="index === 1"> + {{ selectedSources.length - 1 }}</span>
					</template>
				</v-select>
			</div>
			<v-row style="margin: 0px">
				<v-col v-for="widget in filteredWidgets" :key="widget._id" style="padding: 5px" sm="12" :md="widget.width">
					<OfficeStats
						v-if="widget.type === 'officeStats'"
						:view="widget.info?.view"
						:stat="widget.info?.stat || 'billing'"
						:header="widget.info?.header"
						:fields="widget.info?.fields"
						:includeChart="widget.info?.includeChart"
					/>
					<StatsOverview v-else-if="widget.type === 'statsOverview'" />
					<ComparisonBarChart v-else-if="widget.type === 'comparisonBarChart'" group="office" />
					<PredictedBarChart v-else-if="widget.type === 'predictedBarChart'" />
					<IndividualYearlyTable
						v-else-if="widget.type === 'individualYearlyTable'"
						:fields="widget.info?.fields"
					/>
					<References v-else-if="widget.type === 'references'" />
					<NumberCards
						v-else-if="widget.type === 'roleCards'"
						:loading="loading"
						title="Distribuição por Cargo"
						:stats="stats.roles"
						:conversionList="config.roles"
					/>
					<v-card v-else-if="widget.type === 'genderPieChart'" class="widgetCard">
						<PieChart title="Distribuição por Género" :stats="stats.genders" />
					</v-card>
					<v-card v-else-if="widget.type === 'officePieChart'" class="widgetCard">
						<PieChart title="Distribuição por Agência" :stats="stats.offices" />
					</v-card>
					<v-card v-else-if="widget.type === 'companyAgeBarChart'" class="widgetCard">
						<BarChart
							width="100%"
							height="350px"
							title="Distribuição por Anos de Empresa"
							:stats="stats.companyAges"
							:distributed="true"
						/>
					</v-card>
					<v-card v-else-if="widget.type === 'ageBarChart'" class="widgetCard">
						<BarChart
							width="100%"
							height="350px"
							title="Distribuição por Idade"
							:stats="stats.ages"
							:distributed="true"
						/>
					</v-card>
					<NumberCards
						v-else-if="widget.type === 'countryCards'"
						:loading="loading"
						title="Distribuição por País"
						:stats="stats.countries"
						:conversionList="countries"
					/>
					<div v-else-if="widget.type === 'leadsGoalCards'" style="margin-top: 10px">
						<GoalCards />
					</div>
					<Ranking
						v-else-if="widget.type === 'leadsRanking'"
						:personal="personal"
						:recruiters="recruiters"
						:selectedSources="selectedSources"
					/>
					<LineGraph
						v-else-if="widget.type === 'leadsLineChart'"
						:personal="personal"
						:recruiters="recruiters"
						:selectedSources="selectedSources"
					/>
					<FunnelGraph
						v-else-if="widget.type === 'leadsFunnelChart'"
						:personal="personal"
						:recruiters="recruiters"
						:selectedSources="selectedSources"
					/>
					<Sessions v-else-if="widget.type === 'sessions'" />
					<Notifications v-else-if="widget.type === 'notifications'" />
				</v-col>
			</v-row>
			<v-dialog v-model="showWidgetsForm">
				<WidgetsForm :groups="groups" :widgets="widgets" :onClose="handleGetWidgets" />
			</v-dialog>
		</div>
	</v-container>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { countries } from "countries-list";

import OfficeStats from "../components/dashboard/OfficeStats";
import StatsOverview from "../components/dashboard/StatsOverview";
import ComparisonBarChart from "../components/dashboard/ComparisonBarChart";
import PredictedBarChart from "../components/dashboard/PredictedBarChart";
import IndividualYearlyTable from "../components/dashboard/IndividualYearlyTable";
import References from "../components/dashboard/References";
import PieChart from "../components/dashboard/PieChart";
import BarChart from "../components/dashboard/BarChart";
import NumberCards from "../components/dashboard/NumberCards";
import Ranking from "../components/dashboard/Ranking";
import GoalCards from "../components/dashboard/GoalCards";
import LineGraph from "../components/dashboard/LineGraph";
import FunnelGraph from "../components/dashboard/FunnelGraph";
import Sessions from "../components/dashboard/Sessions";
import Notifications from "../components/dashboard/Notifications";
import WidgetsForm from "../components/dashboard/WidgetsForm";
import StaffStats from "../views/StaffStats";

import { getWidgets } from "../api/widgets";
import { getUserStats } from "../api/stats";
import { getUsers } from "../api/users";

export default Vue.extend({
	name: "Dashboard",
	components: {
		OfficeStats,
		StatsOverview,
		ComparisonBarChart,
		PredictedBarChart,
		IndividualYearlyTable,
		References,
		PieChart,
		BarChart,
		NumberCards,
		Ranking,
		GoalCards,
		LineGraph,
		FunnelGraph,
		Sessions,
		Notifications,
		WidgetsForm,
		StaffStats,
	},
	created() {
		if (this.user.role !== "consultant") {
			this.handleGetWidgets();

			this.countries = Object.entries(countries).map(([key, value]) => ({
				key,
				name: value.name,
				image: `https://flagicons.lipis.dev/flags/4x3/${key.toLowerCase()}.svg`,
			}));

			if (this.user.role === "recruiter") this.recruiters.push(this.user._id);
		}
	},
	watch: {
		selectedOffices: function () {
			if (this.hasAnyStaffWidgets) this.handleGetUserStats();
		},
		selectedRoles: function () {
			if (this.hasAnyStaffWidgets) this.handleGetUserStats();
		},
		tab: function () {
			this.filterWidgets();
		},
	},
	computed: mapState(["features", "selectedOffices", "allOffices", "user", "config"]),
	data() {
		return {
			loading: false,
			loadingUserStats: false,

			tab: 0,

			groups: [],
			widgets: [],
			filteredWidgets: [],

			stats: {},
			selectedRoles: [],

			personal: [],
			recruiters: [],
			selectedSources: [],

			recruitersList: [],

			hasAnyStaffWidgets: false,
			hasAnyLeadWidgets: false,
			hasStaffWidgets: false,
			hasLeadsWidgets: false,

			showWidgetsForm: false,
		};
	},
	methods: {
		async handleGetWidgets() {
			this.loading = true;

			const response = await getWidgets();

			if (response.status === 200) {
				this.widgets = response.data;

				this.groups = [];
				this.hasStaffWidgets = false;
				this.hasLeadsWidgets = false;
				for (const widget of this.widgets) {
					if (!this.groups.find(g => g.name === widget.group.name)) this.groups.push(widget.group);

					if (
						[
							"roleCards",
							"genderPieChart",
							"officePieChart",
							"companyAgeBarChart",
							"ageBarChart",
							"countryCards",
						].includes(widget.type)
					) {
						this.hasAnyStaffWidgets = true;
					}

					if (["leadsRanking", "leadsLineChart", "leadsFunnelChart"].includes(widget.type)) {
						this.hasAnyLeadsWidgets = true;
					}
				}

				this.groups.sort((a, b) => (a.pos > b.pos ? 1 : -1));

				this.filterWidgets();

				if (this.hasAnyStaffWidgets) this.handleGetUserStats();
				if (this.hasAnyLeadsWidgets) this.handleGetRecruiters();

				if (!this.widgets.length) {
					if (this.features.listInvoices) {
						this.$router.push("/invoices");
					} else if (this.features.listReservations) {
						this.$router.push("/reservations");
					} else if (this.features.listLeads) {
						this.$router.push("/leads");
					} else if (this.features.listStaff) {
						this.$router.push("/users");
					} else if (this.features.listConfigs) {
						this.$router.push("/config");
					}
				}
			}

			this.loading = false;
		},
		filterWidgets() {
			this.filteredWidgets = this.widgets.filter(w => w.group.pos === this.tab);

			this.hasStaffWidgets = false;
			this.hasLeadsWidgets = false;
			for (const widget of this.filteredWidgets) {
				if (
					[
						"roleCards",
						"genderPieChart",
						"officePieChart",
						"companyAgeBarChart",
						"ageBarChart",
						"countryCards",
					].includes(widget.type)
				) {
					this.hasStaffWidgets = true;
				}

				if (["leadsRanking", "leadsLineChart", "leadsFunnelChart"].includes(widget.type)) {
					this.hasLeadsWidgets = true;
				}
			}
		},
		async handleGetUserStats() {
			this.loadingUserStats = true;

			const response = await getUserStats(this.selectedRoles);

			if (response.status === 200) {
				this.stats = response.data;

				for (const group of this.stats.genders) {
					group.name = this.config.genders.find(g => g.key === group._id).name;
				}

				for (const group of this.stats.offices) {
					group.name = this.allOffices.find(o => o.key === group._id).name;
				}
			}

			this.loadingUserStats = false;
		},
		async handleGetRecruiters() {
			const response = await getUsers(0, 10000, "name", false, {
				active: true,
				role: ["recruiter", "expansionDirector"],
				all: true,
			});

			if (response.status === 200) {
				this.recruitersList = response.data.users;
			}
		},
	},
});
</script>
