import Vue from "vue";
import Vuetify from "vuetify/lib";

const localData = localStorage.getItem("darkMode");
const darkMode = localData ? JSON.parse(localData) : true;

Vue.use(Vuetify);

export default new Vuetify({
	iconfont: "md",
	theme: {
		dark: darkMode,
		themes: {
			dark: {
				primary: "#ff9800",
			},
			light: {
				primary: "#ff9800",
			},
		},
	},
});
