<template>
	<v-card min-width="250" max-width="400" class="mx-auto pa-5">
		<h3>Mudar Password</h3>
		<br />
		<v-form ref="form" @submit.prevent="handleChangePassword">
			<v-text-field
				outlined
				v-model="oldPassword"
				label="Password Antiga"
				:rules="[oldPasswordRequired]"
				:append-icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
				:type="showOldPassword ? 'text' : 'password'"
				@click:append="showOldPassword = !showOldPassword"
			/>
			<v-text-field
				outlined
				v-model="newPassword"
				label="Password Nova"
				:rules="[newPasswordRequired, samePassword, differentThanOld]"
				:append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
				:type="showNewPassword ? 'text' : 'password'"
				@click:append="showNewPassword = !showNewPassword"
			/>
			<v-text-field
				outlined
				v-model="newPasswordRepeat"
				label="Repetir Password Nova"
				:rules="[samePassword, differentThanOld]"
				:append-icon="showNewPasswordRepeat ? 'mdi-eye' : 'mdi-eye-off'"
				:type="showNewPasswordRepeat ? 'text' : 'password'"
				@click:append="showNewPasswordRepeat = !showNewPasswordRepeat"
			/>
			<v-btn type="submit" color="primary">Guardar</v-btn>
		</v-form>
		<br />
		<v-alert type="warning" max-width="400px" class="mx-auto"> Mude a sua password por segurança </v-alert>
	</v-card>
</template>

<script>
import Vue from "vue";
import { mapMutations } from "vuex";

import { changePassword } from "../api/auth";

export default Vue.extend({
	name: "ChangePassword",
	watch: {
		oldPassword: "validatePasswords",
		newPassword: "validatePasswords",
		newPasswordRepeat: "validatePasswords",
	},
	data() {
		return {
			oldPassword: "",
			newPassword: "",
			newPasswordRepeat: "",

			showOldPassword: false,
			showNewPassword: false,
			showNewPasswordRepeat: false,
			oldPasswordRequired: () =>
				this.oldPassword !== "" || (this.newPassword !== "" && this.oldPassword !== "") || "Obrigatório",
			newPasswordRequired: () =>
				this.newPassword !== "" || (this.oldPassword !== "" && this.newPassword !== "") || "Obrigatório",
			samePassword: () => this.newPassword === this.newPasswordRepeat || "Passwords têm de ser iguais",
			differentThanOld: () =>
				this.newPassword !== this.oldPassword || "Nova password têm de ser diferente da antiga",
		};
	},
	methods: {
		...mapMutations(["login", "openToast"]),
		validatePasswords() {
			this.$refs.form.validate();
		},
		isFormValid() {
			if (!this.$refs.form.validate()) {
				this.openToast({ message: "Faltam campos obrigatórios", color: "red" });

				return false;
			}

			return true;
		},
		async handleChangePassword() {
			if (!this.isFormValid()) return;

			const response = await changePassword({ oldPassword: this.oldPassword, newPassword: this.newPassword });

			if (response.status === 200) {
				this.login({ needsPasswordChange: false });

				this.openToast({ message: "Password mudada com sucesso", color: "green" });
			} else {
				this.openToast({ message: "Credenciais Inválidas", color: "red" });
			}
		},
	},
});
</script>
