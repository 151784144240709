<template>
	<div>
		<div :style="`max-height: ${maxHeight || 500}px; overflow: auto`">
			<v-progress-circular v-if="loading" indeterminate color="primary" style="margin: 10px" />
			<div v-else>
				<div v-if="tasks.length">
					<v-card
						:class="!task.done && dayjs().isAfter(task.date) ? 'overdueTask' : ''"
						v-for="task in filteredTasks"
						:key="task._id"
						:style="`margin: 10px; background-color: ${
							handleGetTasksOverwrite ? '' : $vuetify.theme.dark ? '#333' : '#f2f2f2'
						}`"
					>
						<v-card-title style="padding: 10px; display: flex; justify-content: space-between">
							<span style="display: flex">
								<v-checkbox
									outlined
									hide-details
									v-model="task.done"
									@change="value => handleMarkAsDone(task._id, value)"
									style="margin: 0px"
								/>
								<span
									style="
										max-width: 195px;
										text-align: left;
										word-break: normal;
										font-size: 0.75em;
										line-height: 1.5em;
									"
									>{{ task.title }}</span
								>
							</span>
							<span
								:style="`color: ${
									task.priority === 2
										? 'rgba(255, 0, 0)'
										: task.priority === 1
										? 'rgba(255, 216, 1)'
										: 'rgba(52, 126, 199)'
								}; padding-right: 5px`"
							>
								{{ "!".repeat(task.priority + 1) }}
							</span>
						</v-card-title>
						<div style="text-align: left; font-size: 0.85em">
							<v-btn v-if="task.group && task.ref" icon :to="`/${task.group}/${task.ref._id}`">
								<v-icon>mdi-link</v-icon>
							</v-btn>
							<span v-if="task.ref" style="top: 1px; position: relative">{{ task.ref.name || task.ref.ref }}</span>
						</div>
						<v-card-text style="padding: 0px 10px" v-if="task.observations" align="left">
							{{ task.observations }}
						</v-card-text>
						<v-card-actions style="margin: 0px 5px; padding: 0px">
							<div style="width: 100%; text-align: right">
								<span
									:style="`float: left; position: relative; top: 10px; left: 5px; font-size: 0.8rem; opacity: 0.75; ${
										dayjs().isAfter(task.date) ? 'color: red' : ''
									}`"
								>
									{{ formatDate(task.date, "DD MMMM YYYY HH:mm") }}
								</span>
								<v-btn icon @click="() => openTaskDetail(task)">
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
								<v-btn icon @click="() => handleDeleteTask(task)">
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</div>
						</v-card-actions>
					</v-card>
				</div>
			</div>
		</div>
		<v-btn fab small color="primary" style="margin: 10px" @click="openTaskDetail">
			<v-icon>mdi-plus</v-icon>
		</v-btn>
		<v-dialog v-model="showTaskDetail" max-width="500px">
			<v-sheet style="padding: 20px">
				<h3>{{ selectedTask ? "Editar" : "Novo" }} Tarefa</h3>
				<br />
				<v-text-field outlined v-model="title" label="Título" />
				<v-row>
					<v-col>
						<v-menu transition="scale-transition" offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field outlined v-model="date" label="Data" v-bind="attrs" v-on="on" readonly />
							</template>
							<v-date-picker v-model="date" color="primary" />
						</v-menu>
					</v-col>
					<v-col>
						<v-menu :close-on-content-click="false" transition="scale-transition" offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field outlined v-model="time" label="Hora" v-bind="attrs" v-on="on" readonly />
							</template>
							<v-time-picker v-model="time" color="primary" format="24hr" />
						</v-menu>
					</v-col>
					<v-col>
						<v-btn-toggle dense mandatory v-model="priority" style="top: 10px">
							<v-btn>!</v-btn>
							<v-btn>!!</v-btn>
							<v-btn>!!!</v-btn>
						</v-btn-toggle>
					</v-col>
				</v-row>
				<v-textarea outlined v-model="observations" label="Observações" height="145px" />
				<v-row>
					<v-col>
						<v-select outlined v-model="group" :items="groups" item-text="name" item-value="value" label="Grupo" />
					</v-col>
					<v-col v-if="group">
						<v-combobox
							outlined
							v-model="ref"
							:items="refs"
							:item-text="refText"
							item-value="_id"
							label="Ref"
							:loading="loadingRefs"
							hide-selected
							@keyup="setSearch"
							:search-input.sync="search"
							:hint="search ? '' : 'Começe a escrever para procurar...'"
						/>
					</v-col>
				</v-row>
				<v-btn @click="selectedTask ? handleEditTask() : handleAddTask()">
					{{ selectedTask ? "Atualizar" : "Adicionar" }}
				</v-btn>
			</v-sheet>
		</v-dialog>
	</div>
</template>

<script>
import Vue from "vue";
import dayjs from "dayjs";
dayjs.extend(require("dayjs/plugin/isSameOrAfter"));

import { getTasks, addTask, editTask, patchTask, deleteTask } from "../api/tasks";
import { getInvoices } from "../api/invoices";
import { getReservations } from "../api/reservations";
import { getLeads } from "../api/leads";
import { getUsers } from "../api/users";

import { formatDate } from "../utils/utils";

export default Vue.extend({
	name: "Tasks",
	props: ["handleGetTasksOverwrite", "tasksOverwrite", "maxHeight", "selectedDate", "idOverwrite"],
	async created() {
		if (this.handleGetTasksOverwrite) {
			await this.handleGetTasksOverwrite();
			this.filterTasks();
		} else {
			this.handleGetTasks();
		}
	},
	watch: {
		selectedDate: function () {
			this.filterTasks();
		},
		idOverwrite: function () {
			this.handleGetTasks();
		},
		group: function () {
			this.search = "";
			this.refs = [];
		},
	},
	data() {
		return {
			loading: false,

			tasks: [],
			filteredTasks: [],

			showTaskDetail: false,
			selectedTask: null,
			title: "",
			date: null,
			time: null,
			priority: 0,
			observations: "",
			group: null,
			ref: null,

			groups: [
				{ name: "Faturas", value: "invoices" },
				{ name: "Pipeline", value: "reservations" },
				{ name: "Leads", value: "leads" },
				{ name: "Staff", value: "users" },
			],
			search: "",
			loadingRefs: false,
			refs: [],
			refText: "",
		};
	},
	methods: {
		dayjs,
		formatDate,
		setSearch(e) {
			if (this.timer) {
				clearTimeout(this.timer);
				this.timer = null;
			}
			this.timer = setTimeout(() => {
				this.search = e.target.value;
				this.handleGetRefs();
			}, 800);
		},
		async handleGetTasks() {
			this.loading = true;

			if (this.handleGetTasksOverwrite) {
				await this.handleGetTasksOverwrite();
			} else {
				const splitPath = this.$route.path.split("/");

				let ref = this.idOverwrite;
				if (splitPath[1]) this.group = splitPath[1];
				if (this.group && splitPath[2]) {
					ref = splitPath[2];
				}

				if (ref) this.ref = ref;

				const response = await getTasks(ref, false, true);

				if (response.status === 200) {
					this.tasks = response.data;
				}
			}

			this.filterTasks();

			this.loading = false;
		},
		filterTasks() {
			if (this.tasksOverwrite) this.tasks = this.tasksOverwrite;

			if (this.selectedDate) {
				this.filteredTasks = this.tasks.filter(t => dayjs(this.selectedDate).isSame(t.date, "day"));
			} else {
				this.filteredTasks = this.tasks;
			}
		},
		async openTaskDetail(item) {
			this.showTaskDetail = true;

			if (item._id) {
				this.selectedTask = item;
				this.title = item.title;
				this.date = formatDate(item.date, "YYYY-MM-DD");
				this.time = formatDate(item.date, "HH:mm");
				this.priority = item.priority;
				this.observations = item.observations;
				this.group = item.group;
				this.ref = item.ref._id;

				if (item.group && item.ref) {
					this.search = item.ref._id;
					await this.handleGetRefs();
					this.ref = this.refs.length ? this.refs[0] : item.ref;
				}
			} else {
				this.selectedTask = null;
				this.title = "";
				this.date =
					this.selectedDate && dayjs(this.selectedDate).isSameOrAfter(dayjs())
						? formatDate(this.selectedDate, "YYYY-MM-DD")
						: formatDate(dayjs(), "YYYY-MM-DD");
				this.time = formatDate(dayjs(), "HH:mm");
				this.priority = 0;
				this.observations = "";
				this.group = null;
				this.ref = null;

				const splitPath = this.$route.path.split("/");

				if (splitPath[1]) this.group = splitPath[1];

				if (this.group) {
					this.search = this.idOverwrite || splitPath[2];
				}

				if (this.search) {
					await this.handleGetRefs();
					this.ref = this.refs[0];
				}
			}
		},
		async handleGetRefs() {
			this.loadingRefs = true;

			let response = null;
			let key = this.group;
			if (this.group === "invoices") {
				response = await getInvoices(0, 10, "date", true, { search: this.search, active: true });
				this.refText = "ref";
			} else if (this.group === "reservations") {
				response = await getReservations(0, 10, "reservationDate", true, { search: this.search, active: true });
				this.refText = "ref";
			} else if (this.group === "leads") {
				response = await getLeads(0, 10, "_created", true, { search: this.search, active: true });
				this.refText = "name";
			} else if (this.group === "users") {
				response = await getUsers(0, 10, "name", false, { search: this.search, active: true });
				this.refText = "name";
			}

			if (response.status === 200) {
				this.refs = response.data[key];
			}

			this.loadingRefs = false;
		},
		async handleAddTask() {
			const { title, date, time, priority, observations, group, ref } = this;

			const response = await addTask({
				title,
				date: dayjs(`${date} ${time}`),
				priority,
				observations,
				group,
				ref,
			});

			this.showTaskDetail = false;

			if (response.status === 201) {
				this.handleGetTasks();
			}
		},
		async handleEditTask() {
			const { selectedTask, title, date, time, priority, observations, group, ref } = this;

			const response = await editTask({
				_id: selectedTask._id,
				title,
				date: dayjs(`${date} ${time}`),
				priority,
				observations,
				group,
				ref,
			});

			this.showTaskDetail = false;

			if (response.status === 200) {
				this.handleGetTasks();
			}
		},
		async handleMarkAsDone(_id, checked) {
			const response = await patchTask({ _id, done: checked });

			if (response.status === 200) {
				this.handleGetTasks();
			}
		},
		async handleDeleteTask(item) {
			const response = await deleteTask(item._id);

			if (response.status === 200) {
				this.handleGetTasks();
			}
		},
	},
});
</script>

<style>
.overdueTask {
	box-shadow: 0 0 0 0 rgba(255, 0, 0, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
	}
}
</style>
