var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('DataTable',{attrs:{"type":"reservations","featuresMap":{
		add: _vm.features.addReservation,
		edit: _vm.features.editReservation,
		delete: _vm.features.deleteReservation,
		addFiles: true,
		addInvoice: _vm.features.addInvoice,
		listTotals: _vm.features.listReservationTotals,
	},"title":"Processos","filtersConfig":_vm.filtersConfig,"getItems":_vm.getReservations,"getFilters":_vm.getReservationsFilters,"patchItem":_vm.patchReservation,"deleteItem":_vm.deleteReservation,"actions":['comments', 'tasks', 'files', 'addInvoice', 'changes', 'view', 'edit', 'delete'],"profileOverwrite":_vm.profileOverwrite,"sortByOverwrite":"reservationDate"},scopedSlots:_vm._u([{key:"totals",fn:function({ pipeline, totals }){return [_c('ReservationTotals',{attrs:{"id":"totals","pipeline":pipeline,"totals":totals}})]}},{key:"detail",fn:function({ selectedItem, updateDetail, closeDetail }){return [_c('ReservationForm',{attrs:{"reservation":selectedItem,"onUpdate":updateDetail,"onClose":closeDetail}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }