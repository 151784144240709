import axios from "axios";

import { logout } from "../api/auth";

async function api({ method, url, data, headers = {} }) {
	const token = localStorage.getItem("token");

	headers.app = "tipy";

	if (token) {
		const firstDate = localStorage.getItem("firstDate");
		const lastDate = localStorage.getItem("lastDate");
		const company = localStorage.getItem("company");
		const offices = localStorage.getItem("offices");

		headers.Authorization = `Bearer ${token}`;
		headers.FirstDate = firstDate;
		headers.LastDate = lastDate;
		headers.Offices = offices;
		headers.Company = company;
	}

	try {
		const res = await axios.request({
			method,
			url: `${process.env.VUE_APP_API_URL}${url}`,
			data,
			headers,
			timeout: 300000,
		});

		return {
			status: res.request.status,
			message: res.data.message,
			data: res.data.data,
		};
	} catch (error) {
		if (error.response.data.message === "UNAUTHORIZED") logout();

		return {
			status: error.request.status,
			message: error.response.data.message,
			data: error.response.data.data,
		};
	}
}

export { api };
