<template>
	<v-card class="widgetCard" width="100%">
		<div style="display: flex; gap: 10px">
			<v-select
				outlined
				dense
				v-model="chartType"
				:items="[
					{ name: 'Barras', value: 'bar' },
					{ name: 'Radar', value: 'radar' },
				]"
				item-text="name"
				item-value="value"
				label="Tipo de Gráfico"
				hide-details
				style="min-width: 110px; max-width: 110px"
			/>
			<v-select
				v-if="!statsOverwrite"
				outlined
				dense
				v-model="stat"
				:items="statTypes"
				item-text="name"
				item-value="value"
				label="Estatística"
				hide-details
				style="min-width: 160px; max-width: 160px"
			/>
			<v-menu
				v-if="!statsOverwrite"
				v-model="showDatePicker"
				:close-on-content-click="false"
				transition="scale-transition"
				offset-y
			>
				<template v-slot:activator="{ on, attrs }">
					<v-text-field
						outlined
						dense
						:value="dateRangeText(localDateRange)"
						label="Intervalo de Tempo"
						v-bind="attrs"
						v-on="on"
						hide-details
						style="min-width: 175px; max-width: 175px"
						readonly
					/>
				</template>
				<v-date-picker type="month" range v-model="localDateRange" color="primary" />
			</v-menu>
			<div
				v-if="!statsOverwrite && dateRanges.length"
				style="width: 100%; display: flex; flex-wrap: no-wrap; overflow-x: scroll"
			>
				<v-chip
					v-for="(range, index) in dateRanges"
					:key="index"
					close
					style="margin: 0px; margin-right: 5px; min-width: fit-content"
					@click:close="removeDateRange(index)"
				>
					{{ formatDate(range[0], "MMM-YYYY") }} - {{ formatDate(range[1], "MMM-YYYY") }}
				</v-chip>
			</div>
		</div>
		<RadarChart
			v-if="chartType === 'radar'"
			width="100%"
			height="560px"
			:stats="[stats]"
			stat="billing"
			:dateRanges="dateRanges.map(r => r.map(d => formatDate(d, 'M-YYYY')))"
			:showLegend="true"
			style="margin: 0px 50px"
		/>
		<BarChart
			v-else
			width="100%"
			height="560px"
			:stats="stats"
			:stat="stat"
			:dateRanges="dateRanges.map(r => r.map(d => formatDate(d, 'M-YYYY')))"
			:showLegend="true"
			:dataLabelFormatter="stat === 'findings' ? number => formatNumber(number, false, false, 0) : formatNumber"
			:yFormatter="stat === 'findings' ? number => formatNumber(number, false, false, 0) : formatNumber"
			:horizontalLabels="stat !== 'findings'"
			:centerLabels="true"
		/>
	</v-card>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import dayjs from "dayjs";

import BarChart from "./BarChart";
import RadarChart from "./RadarChart";

import { getGroupedStats } from "../../api/stats";

import { formatDate, formatNumber } from "../../utils/utils";

export default Vue.extend({
	name: "ComparisonBarChart",
	props: ["group", "_id", "statOverwrite", "statsOverwrite", "numberOfYears"],
	components: { BarChart, RadarChart },
	created() {
		if (this.statsOverwrite) {
			this.stat = this.statOverwrite;
			this.stats = this.statsOverwrite;

			this.calculateDateRanges();
		} else {
			this.handleGetStats();
		}
	},
	watch: {
		selectedOffices: function () {
			if (!this.statsOverwrite) this.handleGetStats();
		},
		dateRange: function () {
			this.calculateDateRanges();
		},
		localDateRange: function () {
			if (this.localDateRange.length === 2) {
				this.localDateRange.sort((a, b) => new Date(a) - new Date(b));

				const numMonthsAllowed = this.dateRanges.length
					? dayjs(this.dateRanges[0][1]).diff(dayjs(this.dateRanges[0][0]), "months")
					: -1;
				const numMonths = dayjs(this.localDateRange[1]).diff(dayjs(this.localDateRange[0]), "months");
				if (
					dayjs(this.localDateRange[0]).get("year") === dayjs(this.localDateRange[1]).get("year") &&
					(numMonthsAllowed === -1 || numMonths === numMonthsAllowed)
				) {
					this.dateRanges.push(this.localDateRange);
				}

				this.localDateRange = [];
			}
		},
		statOverwrite: function () {
			this.stat = this.statOverwrite;
			this.stats = this.statsOverwrite;

			this.calculateDateRanges();
		},
		numberOfYears: function () {
			this.calculateDateRanges();
		},
	},
	computed: mapState(["selectedOffices", "dateRange"]),
	data() {
		return {
			loading: false,

			showDatePicker: false,

			localDateRange: [],
			dateRanges: [],

			stats: [],
			totals: [],

			statTypes: [
				{ name: "Faturação", value: "billing" },
				{ name: "Pago", value: "billingPaid" },
				{ name: "Previsto", value: "predicted" },
				{ name: "Pipeline", value: "reservations" },
				{ name: "Angariações", value: "findings" },
			],

			chartType: "bar",
			stat: "billing",
		};
	},
	methods: {
		formatDate,
		formatNumber,
		dateRangeText(localDateRange) {
			return localDateRange ? localDateRange.map(d => formatDate(d, "MMM-YYYY")).join(" ~ ") : "";
		},
		removeDateRange(index) {
			this.dateRanges.splice(index, 1);
		},
		getIconParameters(percentDiff) {
			return percentDiff === 0
				? { icon: "mdi-equal", color: "primary" }
				: percentDiff > 0
				? { icon: "mdi-arrow-up", color: "success" }
				: { icon: "mdi-arrow-down", color: "error" };
		},
		calculateDateRanges() {
			const thisYear = dayjs(this.dateRange[1]).get("year");
			const years = [];
			for (let i = dayjs(this.dateRange[0]).get("year"); i <= thisYear; i++) {
				years.push(i);
			}

			while (years.length < (this.numberOfYears || 2) && this.stats[years[0] - 1]) {
				years.unshift(years[0] - 1);
			}

			this.dateRanges = years.map(y => [`${y}-1`, `${y}-12`]);
		},
		async handleGetStats() {
			this.loading = true;

			const response = await getGroupedStats(this.group, this.stat, this._id);

			if (response.status === 200) {
				this.stats = this._id ? response.data : response.data.total;

				const years = [dayjs().get("year")];

				while (years.length < (this.numberOfYears || 2) && this.stats[years[0] - 1]) {
					years.unshift(years[0] - 1);
				}

				this.dateRanges = years.map(y => [`${y}-1`, `${y}-12`]);
			}

			this.loading = false;
		},
	},
});
</script>
