import { api } from "../utils/request";

async function getNotifications(page, perPage, sortBy, sortDesc, filters) {
	const res = await api({
		method: "post",
		url: "/api/notifications/search",
		data: { page, perPage, sortBy, sortDesc, filters },
	});

	return res;
}

async function addNotification(notification) {
	const res = await api({
		method: "post",
		url: "/api/notifications",
		data: notification,
	});

	return res;
}

async function editNotification(notification) {
	const res = await api({
		method: "put",
		url: `/api/notifications/${notification._id}`,
		data: notification,
	});

	return res;
}

async function deleteNotification(id) {
	const res = await api({
		method: "delete",
		url: `/api/notifications/${id}`,
	});

	return res;
}

export { getNotifications, addNotification, editNotification, deleteNotification };
