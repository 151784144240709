import { api } from "../utils/request";

async function getUsers(page, perPage, sortBy, sortDesc, filters = {}) {
	const res = await api({
		method: "post",
		url: "/api/users/search",
		data: { page, perPage, sortBy, sortDesc, filters },
	});

	return res;
}

async function getUser(id) {
	const res = await api({
		method: "get",
		url: `/api/users/${id}`,
	});

	return res;
}

async function addUser(user) {
	const res = await api({
		method: "post",
		url: "/api/users",
		data: user,
	});

	return res;
}

async function editUser(user) {
	const res = await api({
		method: "put",
		url: `/api/users/${user._id}`,
		data: user,
	});

	return res;
}

async function patchUser(user) {
	const res = await api({
		method: "patch",
		url: `/api/users/${user._id}`,
		data: user,
	});

	return res;
}

async function patchUsers(users) {
	const res = await api({
		method: "patch",
		url: "/api/users",
		data: users,
	});

	return res;
}

async function deleteUser(id) {
	const res = await api({
		method: "delete",
		url: `/api/users/${id}`,
	});

	return res;
}

async function addGoals(id, goals) {
	const res = await api({
		method: "post",
		url: `/api/users/${id}/goals`,
		data: goals,
	});

	return res;
}

async function exportUsers() {
	const res = await api({
		method: "post",
		url: "/api/users/export",
	});

	return res;
}

async function importUsers(files) {
	const res = await api({
		method: "post",
		url: "/api/users/import",
		data: files,
		headers: { "Content-Type": "multipart/form-data" },
	});

	return res;
}

export {
	getUsers,
	getUser,
	addUser,
	editUser,
	patchUser,
	patchUsers,
	deleteUser,
	addGoals,
	exportUsers,
	importUsers,
};
