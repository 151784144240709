<template>
	<v-sheet style="padding: 20px; display: flex; flex-direction: column; gap: 20px">
		<center v-if="loading"><v-progress-circular indeterminate /></center>
		<div v-if="changes.length">
			<div
				v-for="change in changes"
				:key="change._id"
				style="border: 1px solid Gray; border-radius: 5px; padding: 10px; margin: 10px 0px"
			>
				<div style="display: flex; justify-content: space-between; margin-bottom: 10px">
					<div>{{ change.user.name }}</div>
					<div>{{ formatDate(change._created, "DD MMM YYYY HH:mm") }}</div>
				</div>
				<table style="width: 100%; border-spacing: 10px">
					<th align="left">Propriedade</th>
					<th colspan="2">Alterações</th>
					<tr v-for="(value, key) in change.fields" :key="key" style="border: 1px solid Gray; border-radius: 5px">
						<td align="left">{{ key }}</td>
						<td>
							<div v-if="Array.isArray(value)"></div>
							<div
								v-else
								style="
									background-color: #ff000050;
									border-radius: 5px;
									padding: 0px 10px;
									display: flex;
									justify-content: space-between;
								"
							>
								<span>-</span>
								<span>{{ value?.__old }} </span>
							</div>
						</td>
						<td>
							<div v-if="Array.isArray(value)">
								<div v-if="value[0] && typeof value[0][1] === 'object'">
									<div v-for="(arrayChange, index) in value" :key="index" style="border-radius: 5px; margin: 10px">
										<table style="width: 100%; border-spacing: 10px; border: 1px solid Gray">
											<th align="left">Propriedade</th>
											<th colspan="2">Alterações</th>
											<tr v-for="(field, key) in arrayChange[1]" :key="key">
												<td v-if="key !== '_id'" align="left">{{ key }}</td>
												<td v-if="key !== '_id' && !Array.isArray(field) && ['~', '-'].includes(arrayChange[0])">
													<div
														style="
															background-color: #ff000050;
															border-radius: 5px;
															padding: 0px 10px;
															display: flex;
															justify-content: space-between;
														"
													>
														<span>-</span>
														<span
															>{{
																field && field.__old !== undefined
																	? field.__old
																	: Array.isArray(field)
																	? field.map(f => `${f[0]}${f[1]}`).join(", ")
																	: field
															}}
														</span>
													</div>
												</td>
												<td v-if="key !== '_id' && ['~', '+'].includes(arrayChange[0])">
													<div
														style="
															background-color: #00ff0050;
															border-radius: 5px;
															padding: 0px 10px;
															display: flex;
															justify-content: space-between;
														"
													>
														<span>+</span>
														<span
															>{{
																field && field.__new !== undefined
																	? field.__new
																	: Array.isArray(field)
																	? field.map(f => `${f[0]}${f[1]}`).join(", ")
																	: field
															}}
														</span>
													</div>
												</td>
											</tr>
										</table>
									</div>
								</div>
								<div v-else>
									<div v-for="(arrayChange, index) in value" :key="index">
										<div
											:style="`
													background-color: #${['~', '-'].includes(arrayChange[0]) ? 'ff000050' : '00ff0050'};
													border-radius: 5px;
													padding: 0px 10px;
													margin: 10px 0px;
													display: flex;
													justify-content: space-between;
												`"
										>
											<span>{{ arrayChange[0] }}</span>
											<span>{{ arrayChange[1] }} </span>
										</div>
									</div>
								</div>
							</div>
							<div
								v-else
								style="
									background-color: #00ff0050;
									border-radius: 5px;
									padding: 0px 10px;
									display: flex;
									justify-content: space-between;
								"
							>
								<span>+</span>
								<span>{{ value?.__new }} </span>
							</div>
						</td>
					</tr>
				</table>
			</div>
		</div>
		<div v-else>Não existem alterações</div>
	</v-sheet>
</template>

<script>
import Vue from "vue";

import { getChanges } from "../api/changes";

import { formatDate } from "../utils/utils";

export default Vue.extend({
	name: "ChangeLog",
	props: ["model", "id"],
	created() {
		if (this.id) this.handleGetChanges();
	},
	watch: {
		id: function () {
			if (this.id) this.handleGetChanges();
		},
	},
	data() {
		return {
			loading: false,

			changes: [],
		};
	},
	methods: {
		formatDate,
		async handleGetChanges() {
			const { model, id } = this;

			this.loading = true;

			const response = await getChanges(model, id);

			if (response.status === 200) {
				this.changes = response.data;
			}

			this.loading = false;
		},
	},
});
</script>
