<template>
	<apexchart type="pie" width="100%" height="400px" :options="chartOptions" :series="series" />
</template>

<script>
import Vue from "vue";

export default Vue.extend({
	name: "PieChart",
	props: ["title", "stats"],
	created() {
		this.chartOptions = { ...this.chartOptions, title: { ...this.chartOptions.title, text: this.title } };

		if (this.stats) this.formatStats();
	},
	watch: {
		"$vuetify.theme.dark": function () {
			this.formatStats();
		},
		stats: function () {
			this.formatStats();
		},
	},
	data() {
		return {
			series: [],
			chartOptions: {
				colors: ["#2E93fA", "#FF9800", "#E91E63", "#00BCD4", "#9C27B0", "#3F51B5", "#546E7A", "#FFC107"],
				labels: [],
				title: {
					text: "",
					align: "center",
					style: {
						color: "#ffffff",
					},
				},
				legend: {
					position: "bottom",
					labels: {
						colors: "#ffffff",
					},
				},
			},
		};
	},
	methods: {
		setColors(darkMode) {
			const textColor = darkMode ? "#ffffff" : "#000000";

			this.chartOptions = {
				...this.chartOptions,
				title: { ...this.chartOptions.title, text: this.title, style: { color: textColor } },
				legend: { ...this.chartOptions.legend, labels: { colors: textColor } },
			};
		},
		formatStats() {
			this.series = [];

			this.setColors(this.$vuetify.theme.dark);

			let labels = [];
			for (const group of this.stats) {
				this.series.push(group.total);
				labels.push(group.name);
			}

			this.chartOptions = { ...this.chartOptions, labels };
		},
	},
});
</script>
