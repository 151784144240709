<style scoped>
.centerContainer {
	display: flex;
	justify-content: center;
	gap: 10px;
	margin-top: 5px;
	margin-bottom: 15px;
}
</style>

<template>
	<v-sheet>
		<div class="centerContainer">
			<div style="margin: 10px 10px; max-width: 300px">
				<div v-if="formType === 'contact' || formType === 'afterContact'">
					<h3>Adicionar Contacto</h3>
					<v-form ref="contactForm">
						<v-checkbox outlined v-model="contactContacted" label="Contactado" />
						<v-select
							outlined
							v-model="contactType"
							:items="config.contactTypes"
							item-text="name"
							item-value="key"
							label="Tipo de Contacto"
						/>
						<div v-if="contactContacted && formType === 'contact'">
							<h4>Candidato aceite?</h4>
							<br />
							<v-btn-toggle v-model="contactAccepted">
								<v-btn :value="true">
									<span>Sim</span>
									<v-icon right>mdi-check</v-icon>
								</v-btn>
								<v-btn :value="false">
									<span>Não</span>
									<v-icon right>mdi-close</v-icon>
								</v-btn>
							</v-btn-toggle>
							<br /><br />
						</div>
						<div v-if="contactAccepted === false">
							<h4>Quem rejeitou?</h4>
							<br />
							<v-btn-toggle v-model="contactDeclinedBy">
								<v-btn value="lead">
									<span>Candidato</span>
								</v-btn>
								<v-btn value="recruiter">
									<span>Recrutador</span>
								</v-btn>
							</v-btn-toggle>
							<br /><br />
							<v-select
								v-if="contactDeclinedBy === 'lead'"
								outlined
								v-model="contactJustification"
								:items="config.justifications"
								item-text="name"
								item-value="key"
								label="Justificação"
								:rules="[required]"
							/>
							<v-select
								v-if="contactDeclinedBy === 'recruiter'"
								outlined
								v-model="contactJustification"
								:items="config.justifications"
								item-text="name"
								item-value="key"
								label="Justificação"
								:rules="[required]"
							/>
						</div>
						<v-textarea
							outlined
							v-model="contactObservations"
							label="Observações"
							:rules="[requiredContactObservations]"
						/>
						<div class="centerContainer" style="margin: 0px">
							<v-menu
								v-model="showDatePicker"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										outlined
										v-model="date"
										label="Data de Registo"
										v-bind="attrs"
										v-on="on"
										style="max-width: 150px"
										readonly
									/>
								</template>
								<v-date-picker v-model="date" color="primary" @input="showDatePicker = false" />
							</v-menu>
							<v-menu
								v-model="showTimePicker"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										outlined
										v-model="time"
										label="Hora de Registo"
										v-bind="attrs"
										v-on="on"
										style="max-width: 150px"
									/>
								</template>
								<v-time-picker v-model="time" color="primary" format="24hr" @input="showTimePicker = false" />
							</v-menu>
						</div>
						<div class="centerContainer" style="margin: 0px">
							<v-autocomplete
								outlined
								v-model="recruiter"
								:items="recruiters"
								item-text="name"
								item-value="_id"
								label="Recrutador"
							/>
						</div>
						<v-btn color="primary" @click="addContact">Adicionar</v-btn>
					</v-form>
				</div>
				<div v-if="formType === 'interview' || formType === 'presentation' || formType === 'approval'">
					<h3>Adicionar Reunião</h3>
					<br />
					<v-form ref="interviewForm">
						<v-menu
							v-model="showInterviewDatePicker"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									outlined
									v-model="interviewDate"
									label="Data"
									v-bind="attrs"
									v-on="on"
									:rules="[required]"
									style="max-width: 300px"
									readonly
								/>
							</template>
							<v-date-picker v-model="interviewDate" color="primary" @input="showInterviewDatePicker = false" />
						</v-menu>
						<v-menu
							v-model="showInterviewTimePicker"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									outlined
									v-model="interviewTime"
									label="Hora"
									v-bind="attrs"
									v-on="on"
									:rules="[required]"
									style="max-width: 300px"
								/>
							</template>
							<v-time-picker
								v-model="interviewTime"
								color="primary"
								format="24hr"
								@input="showInterviewTimePicker = false"
							/>
						</v-menu>
						<div class="centerContainer" style="margin: 0px">
							<v-menu
								v-model="showDatePicker"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										outlined
										v-model="date"
										label="Data de Registo"
										v-bind="attrs"
										v-on="on"
										style="max-width: 150px"
										readonly
									/>
								</template>
								<v-date-picker v-model="date" color="primary" @input="showDatePicker = false" />
							</v-menu>
							<v-menu
								v-model="showTimePicker"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										outlined
										v-model="time"
										label="Hora de Registo"
										v-bind="attrs"
										v-on="on"
										style="max-width: 150px"
									/>
								</template>
								<v-time-picker v-model="time" color="primary" format="24hr" @input="showTimePicker = false" />
							</v-menu>
						</div>
						<div class="centerContainer" style="margin: 0px">
							<v-autocomplete
								outlined
								v-model="recruiter"
								:items="recruiters"
								item-text="name"
								item-value="_id"
								label="Recrutador"
							/>
						</div>
						<v-btn color="primary" @click="addInterview">Adicionar</v-btn>
					</v-form>
				</div>
				<div v-if="formType === 'interviewEdit' || formType === 'presentationEdit' || formType === 'approvalEdit'">
					<h3>Finalizar Reunião</h3>
					<br />
					<h4>Aconteceu?</h4>
					<br />
					<v-btn-toggle v-model="interviewDone">
						<v-btn :value="true">
							<span>Sim</span>
							<v-icon right>mdi-check</v-icon>
						</v-btn>
						<v-btn :value="false">
							<span>Não</span>
							<v-icon right>mdi-close</v-icon>
						</v-btn>
					</v-btn-toggle>
					<br /><br />
					<div v-if="interviewDone">
						<h4>Candidato aceite?</h4>
						<br />
						<v-btn-toggle v-model="interviewAccepted">
							<v-btn :value="true">
								<span>Sim</span>
								<v-icon right>mdi-check</v-icon>
							</v-btn>
							<v-btn :value="false">
								<span>Não</span>
								<v-icon right>mdi-close</v-icon>
							</v-btn>
						</v-btn-toggle>
						<br /><br />
					</div>
					<div v-if="formType === 'interviewEdit' && company === 'realtyart' && interviewAccepted">
						<v-combobox
							outlined
							v-model="commission"
							:items="[40, 47.8, 50, 57.3, 60]"
							label="Comissão"
							append-icon="mdi-percent"
							type="number"
							:rules="[required]"
							hint="Obrigatório"
							persistent-hint
						/>
						<v-select
							outlined
							v-model="selectedOffers"
							:items="config.offers"
							item-text="name"
							item-value="key"
							chips
							label="Ofertas"
							multiple
						/>
					</div>
					<div v-if="interviewDone === false || interviewAccepted === false">
						<h4>{{ interviewDone === false ? "Quem faltou?" : "Quem rejeitou?" }}</h4>
						<br />
						<v-btn-toggle v-model="interviewDeclinedBy">
							<v-btn value="lead">
								<span>Candidato</span>
							</v-btn>
							<v-btn value="recruiter">
								<span>{{ formType === "approvalEdit" ? "Diretor Comercial" : "Recrutador" }}</span>
							</v-btn>
						</v-btn-toggle>
						<br /><br />
						<v-select
							v-if="interviewDone === false || (interviewDone === true && interviewAccepted === false)"
							outlined
							v-model="interviewJustification"
							:items="config.justifications"
							item-text="name"
							item-value="key"
							label="Justificação"
							:rules="[required]"
						/>
					</div>
					<v-textarea outlined v-model="interviewObservations" label="Observações" />
					<v-btn color="primary" @click="editInterview">Guardar</v-btn>
				</div>
			</div>
		</div>
	</v-sheet>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import dayjs from "dayjs";

import { getUsers } from "../api/users";
import { patchLead } from "../api/leads";

export default Vue.extend({
	name: "LeadTimelineForm",
	props: ["lead", "formType", "handleAddContact", "handleAddInterview", "handleEditInterview"],
	async created() {
		this.handleGetRecruiters();

		this.recruiter = this.lead.recruiter;
	},
	computed: mapState(["company", "config"]),
	data() {
		return {
			required: value => !!value || "Obrigatório",
			requiredContactObservations: value =>
				(this.contactContacted === false && !!value) || this.contactContacted || "Obrigatório",

			contactContacted: false,
			contactType: "phone",
			contactAccepted: null,
			contactDeclinedBy: null,
			contactJustification: null,
			contactObservations: "",

			showInterviewDatePicker: false,
			showInterviewTimePicker: false,
			interviewDate: null,
			interviewTime: null,
			interviewDone: null,
			interviewDeclinedBy: null,
			interviewObservations: "",
			interviewAccepted: null,
			interviewJustification: null,

			showDatePicker: false,
			date: dayjs().format("YYYY-MM-DD"),
			showTimePicker: false,
			time: dayjs().format("HH:mm"),
			recruiters: [],
			recruiter: null,

			commission: null,
			selectedOffers: [],
		};
	},
	methods: {
		...mapMutations(["openToast"]),
		async handleGetRecruiters() {
			const response = await getUsers(0, 10000, "name", false, {
				active: true,
				role: ["recruiter", "expansionDirector", "commercialDirector", "broker", "ceo"],
				all: true,
			});

			if (response.status === 200) {
				this.recruiters = response.data.users;
			}
		},
		addContact() {
			if (!this.$refs["contactForm"].validate()) return;

			if (this.formType === "contact") {
				if (
					(this.contactContacted === true && typeof this.contactAccepted !== "boolean") ||
					(this.contactAccepted === false && this.contactDeclinedBy === null)
				) {
					this.openToast({ message: "Faltam campos obrigatórios", color: "red" });

					return;
				}
			}

			this.handleAddContact(
				this.contactType,
				this.contactContacted,
				this.contactDeclinedBy,
				this.contactJustification,
				this.contactObservations,
				true,
				dayjs(`${this.date} ${this.time}`),
				this.recruiter,
			);

			this.contactContacted = false;
			this.contactType = "phone";
			this.contactAccepted = null;
			this.contactDeclinedBy = null;
			this.contactObservations = "";
		},
		addInterview() {
			if (!this.$refs["interviewForm"].validate()) return;

			this.handleAddInterview(
				this.interviewDate,
				this.interviewTime,
				dayjs(`${this.date} ${this.time}`),
				this.recruiter,
			);

			this.showInterviewDatePicker = false;
			this.showInterviewTimePicker = false;
			this.interviewDate = null;
			this.interviewTime = null;
		},
		async editInterview() {
			if (
				typeof this.interviewDone !== "boolean" ||
				(this.interviewDone === true && typeof this.interviewAccepted !== "boolean") ||
				(this.interviewDone === false && this.interviewDeclinedBy === null) ||
				((this.interviewDone === false || (this.interviewDone === true && this.interviewAccepted === false)) &&
					this.interviewDeclinedBy === null) ||
				(this.formType === "interviewEdit" &&
					this.interviewAccepted &&
					this.company === "realtyart" &&
					!this.commission)
			) {
				this.openToast({ message: "Faltam campos obrigatórios", color: "red" });

				return;
			}

			this.handleEditInterview(
				this.interviewDone,
				this.interviewDeclinedBy,
				this.interviewJustification,
				this.interviewObservations,
				this.interviewAccepted,
			);

			if (this.company === "realtyart" && this.commission) {
				await patchLead({ _id: this.lead._id, commission: this.commission, offers: this.selectedOffers });
			}

			this.interviewDone = null;
			this.interviewDeclinedBy = null;
			this.interviewObservations = "";
			this.interviewAccepted = null;
		},
	},
});
</script>
