<template>
	<v-sheet style="overflow-x: hidden" outlined rounded>
		<v-form ref="form" @submit.prevent="handleAddGoal">
			<v-row justify="center">
				<v-col style="margin: 30px; max-width: 400px">
					<v-select
						outlined
						v-model="group"
						:items="groups"
						item-text="name"
						item-value="key"
						label="Grupo"
						:rules="[required]"
						:disabled="!!goals"
					/>
					<v-select
						v-if="group === 'office'"
						outlined
						v-model="office"
						item-text="name"
						item-value="key"
						label="Agência"
						:items="offices"
						:rules="[required]"
						:disabled="!!goals"
					/>
					<v-autocomplete
						v-else-if="group === 'consultant'"
						outlined
						v-model="selectedUser"
						item-text="name"
						item-value="_id"
						label="Consultor"
						:items="consultants"
						:rules="[required]"
						:disabled="!!goals"
					/>
					<v-autocomplete
						v-else-if="group"
						outlined
						v-model="selectedUser"
						item-text="name"
						item-value="_id"
						label="Diretor Comercial"
						:items="commercialDirectors"
						:rules="[required]"
						:disabled="!!goals"
					/>
					<v-row>
						<v-col>
							<v-text-field
								outlined
								:append-icon="type === 'findings' ? '' : 'mdi-currency-eur'"
								v-model="number"
								label="Valor"
								type="number"
								:rules="[required]"
							/>
						</v-col>
						<v-col>
							<v-select
								outlined
								v-model="type"
								:items="config.goalTypes"
								item-text="name"
								item-value="key"
								label="Tipo"
								:rules="[required]"
								:disabled="!!goals"
							/>
						</v-col>
					</v-row>
					<v-select
						outlined
						v-model="frequency"
						:items="config.goalFrequencies"
						item-text="name"
						item-value="key"
						label="Frequência"
						:rules="[required]"
						disabled
						hide-details
					/>
					<br />
					Objetivos Mensais

					<div style="display: flex; gap: 10px; flex-wrap: wrap; margin-top: 10px">
						<v-text-field
							v-for="i in 12"
							:key="defaultMonthlyGoal + i"
							outlined
							:append-icon="type === 'findings' ? '' : 'mdi-currency-eur'"
							v-model="monthlyGoals[i - 1]"
							:label="
								dayjs()
									.set('month', i - 1)
									.locale('pt')
									.format('MMMM')
							"
							type="number"
							:rules="[required]"
							hide-details
							style="width: 183px"
							@change="setMonthlyGoals"
						/>
					</div>
				</v-col>
			</v-row>
			<v-btn v-if="yearlyGoal" @click="handleEditGoal">Atualizar</v-btn>
			<v-btn v-else @click="handleAddGoal">Adicionar</v-btn>
			<br /><br />
		</v-form>
	</v-sheet>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import dayjs from "dayjs";

import { getUsers } from "../api/users";
import { addGoal, editGoal } from "../api/goals";

export default Vue.extend({
	name: "GoalDetail",
	props: ["overwriteGroup", "overwriteTarget", "overwriteType", "goals", "onClose"],
	created() {
		this.setGoals(this.goals);

		if (this.overwriteType) this.type = this.overwriteType;
		if (this.overwriteTarget) this.selectedUser = this.overwriteTarget;

		this.groups = this.config.goalGroups;
		if (
			!this.features.addOfficeGoal ||
			!this.features.addCommercialDirectorGoal ||
			!this.features.addConsultantGoal
		) {
			const allowedGroups = [];

			if (this.features.addOfficeGoal) allowedGroups.push("office");
			if (this.features.addCommercialDirectorGoal) allowedGroups.push("commercialDirector");
			if (this.features.addConsultantGoal) allowedGroups.push("consultant");

			this.groups = this.config.goalGroups.filter(g => allowedGroups.includes(g.key));
		}

		this.handleGetUsers();
	},
	watch: {
		goals: function (newVal) {
			this.setGoals(newVal);
		},
		overwriteGroup: function () {
			if (this.overwriteGroup) this.group = this.overwriteGroup;
		},
		overwriteTarget: function () {
			if (this.overwriteTarget) {
				if (this.group === "office") {
					this.office = this.overwriteTarget;
				} else {
					this.selectedUser = this.overwriteTarget;
				}
			}
		},
		overwriteType: function () {
			if (this.overwriteType) this.type = this.overwriteType;
		},
		number: function (newVal) {
			this.number = Number(newVal);

			this.setMonthlyGoals();
		},
	},
	computed: mapState(["user", "offices", "features", "config"]),
	data() {
		return {
			loading: false,

			required: value => !!value || "Obrigatório",

			consultants: [],
			commercialDirectors: [],

			groups: [],

			yearlyGoal: null,
			group: null,
			selectedUser: null,
			office: null,
			number: null,
			type: "billing",
			frequency: "yearly",
			monthlyGoals: [],
			defaultMonthlyGoal: null,
		};
	},
	methods: {
		...mapMutations(["openToast"]),
		dayjs,
		setGoals(newVal) {
			this.monthlyGoals = [];
			this.defaultMonthlyGoal = null;

			if (newVal?.length) {
				this.yearlyGoal = newVal.find(g => g.frequency === "yearly");
				this.group = this.yearlyGoal.group;
				this.selectedUser = this.yearlyGoal.user;
				this.office = this.yearlyGoal.office;
				this.number = this.yearlyGoal.number;

				this.setMonthlyGoals();
			} else {
				this.yearlyGoal = null;
				this.group = null;
				this.selectedUser = null;
				this.office = null;
				this.number = null;

				if (this.overwriteGroup) this.group = this.overwriteGroup;
				if (this.overwriteTarget) {
					if (this.group === "office") {
						this.office = this.overwriteTarget;
					} else {
						this.selectedUser = this.overwriteTarget;
					}
				}
			}
		},
		setMonthlyGoals() {
			let yearlyGoalLeft = this.number;
			let monthsLeft = 12;

			for (let i = 0; i < 12; i++) {
				const monthlyGoal = this.goals
					? this.goals.find(g => g.frequency === "monthly" && dayjs(g.startDate).get("month") === i)
					: null;

				this.monthlyGoals[i] = monthlyGoal
					? monthlyGoal.number
					: this.monthlyGoals[i] && this.monthlyGoals[i] !== this.defaultMonthlyGoal
					? Number(this.monthlyGoals[i])
					: this.defaultMonthlyGoal;

				if (this.monthlyGoals[i] && this.monthlyGoals[i] !== this.defaultMonthlyGoal) {
					yearlyGoalLeft -= this.monthlyGoals[i];
					monthsLeft--;
				}
			}

			const defaultMonthlyGoal = Number((yearlyGoalLeft / monthsLeft).toFixed(2));

			for (let i = 0; i < 12; i++) {
				this.monthlyGoals[i] =
					this.monthlyGoals[i] && this.monthlyGoals[i] !== this.defaultMonthlyGoal
						? this.monthlyGoals[i]
						: defaultMonthlyGoal > 0
						? defaultMonthlyGoal
						: 0;
			}

			this.defaultMonthlyGoal = defaultMonthlyGoal;
		},
		isFormValid() {
			if (!this.$refs.form.validate()) return false;

			return true;
		},
		async handleGetUsers() {
			const filters = { role: ["consultant", "teamLeader", "commercialDirector", "broker", "coo"] };

			if (["commercialDirector", "teamLeader"].includes(this.user.role)) {
				filters.manager = this.user._id.toString();
			}

			const response = await getUsers(0, 10000, "name", false, { ...filters, all: true });

			if (response.status === 200) {
				this.commercialDirectors = response.data.users.filter(u =>
					["commercialDirector", "broker", "coo"].includes(u.role),
				);
				this.consultants = response.data.users.filter(u =>
					["consultant", "teamLeader", "commercialDirector"].includes(u.role),
				);
			}
		},
		async handleAddGoal() {
			this.loading = true;
			const { group, selectedUser, office, number, type, frequency, monthlyGoals, defaultMonthlyGoal } = this;

			if (!this.isFormValid()) return;

			if (Math.round(monthlyGoals.reduce((acc, g) => acc + Number(g), 0)) !== number) {
				return this.openToast({
					message: "A soma dos objetivos mensais deve ser igual ao objetivo anual",
					color: "red",
				});
			}

			const response = await addGoal({
				group,
				user: selectedUser,
				office,
				number,
				type,
				frequency,
				monthlyGoals: monthlyGoals.map(g => (g === defaultMonthlyGoal ? 0 : g)),
			});

			if (response.status === 201) {
				this.onClose();
			} else if (response.status === 409) {
				this.openToast({ message: "Já existe um objetivo igual", color: "red" });
			}

			this.loading = false;
		},
		async handleEditGoal() {
			this.loading = true;
			const { group, selectedUser, office, number, type, frequency, monthlyGoals, defaultMonthlyGoal } = this;

			if (!this.isFormValid()) return;

			if (Math.round(monthlyGoals.reduce((acc, g) => acc + Number(g), 0)) !== number) {
				return this.openToast({
					message: "A soma dos objetivos mensais deve ser igual ao objetivo anual",
					color: "red",
				});
			}

			const response = await editGoal({
				_id: this.yearlyGoal._id,
				group,
				user: selectedUser,
				office,
				number,
				type,
				frequency,
				monthlyGoals: monthlyGoals.map(g => (g === defaultMonthlyGoal ? 0 : g)),
			});

			if (response.status === 200) {
				this.onClose();
			} else if (response.status === 409) {
				this.openToast({ message: "Já existe um objetivo igual", color: "red" });
			}

			this.loading = false;
		},
	},
});
</script>
