<template>
	<span>
		<slot name="activator" :onClick="handleOpen" />
		<v-dialog v-model="open" width="500">
			<v-card>
				<v-card-title>Confirmar eliminação</v-card-title>
				<v-divider />
				<v-card-text style="text-align: start; padding: 20px 24px">
					Tem a certeza que quer eliminar {{ deletionText }}?
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<v-btn text @click="handleClose">Cancelar</v-btn>
					<v-btn color="primary" @click="handleConfirm">Confirmar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</span>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
	name: "DeletionDialog",
	props: ["deletionText", "onClose", "onConfirm"],
	data() {
		return { open: false };
	},
	methods: {
		handleOpen() {
			this.open = true;
		},
		handleClose() {
			if (this.onClose) this.onClose();
			this.open = false;
		},
		handleConfirm() {
			this.onConfirm();
			this.handleClose();
		},
	},
});
</script>
