<template>
	<apexchart type="radar" :width="width" :height="height" :options="chartOptions" :series="series" />
</template>

<script>
import Vue from "vue";

import { formatDate } from "../../utils/utils";

export default Vue.extend({
	name: "RadarChart",
	props: [
		"width",
		"height",
		"title",
		"stats",
		"stat",
		"year",
		"yearLimit",
		"dateRanges",
		"showLegend",
		"yFormatter",
	],
	created() {
		this.chartOptions = {
			...this.chartOptions,
			title: { ...this.chartOptions.title, text: this.title },
			legend: { ...this.chartOptions.legend, show: !!this.showLegend },
		};

		this.setColors(this.$vuetify.theme.dark);

		if (this.stats) this.formatStats();
	},
	watch: {
		"$vuetify.theme.dark": function (newVal) {
			this.setColors(newVal);
		},
		stats: function () {
			this.formatStats();
		},
	},
	data() {
		return {
			series: [],
			chartOptions: {
				colors: ["#2E93fA", "#FF9800", "#E91E63", "#00BCD4", "#9C27B0", "#3F51B5", "#546E7A", "#FFC107"],
				labels: [],
				title: { text: "", align: "center", style: { color: "#ffffff" } },
				chart: { toolbar: { show: false } },
				legend: { position: "bottom", labels: { colors: "#ffffff" } },
				yaxis: { show: false },
				tooltip: { theme: "dark" },
			},
		};
	},
	methods: {
		setColors(darkMode) {
			const textColor = darkMode ? "#ffffff" : "#000000";

			this.chartOptions = {
				...this.chartOptions,
				title: { ...this.chartOptions.title, text: this.title, style: { color: textColor } },
				legend: { ...this.chartOptions.legend, labels: { colors: textColor } },
				tooltip: { ...this.chartOptions.tooltip, theme: darkMode ? "dark" : "light" },
			};

			if (this.yFormatter) {
				this.chartOptions = {
					...this.chartOptions,
					yaxis: { ...this.chartOptions.yaxis, labels: { formatter: this.yFormatter } },
				};
			}
		},
		formatStats() {
			if (this.yFormatter) {
				this.chartOptions = {
					...this.chartOptions,
					yaxis: { ...this.chartOptions.yaxis, labels: { formatter: this.yFormatter } },
				};
			}

			this.series = [];
			const labels = [];

			for (const individual of this.stats) {
				let dateRanges = this.dateRanges;

				if (this.year && this.yearLimit) {
					const years = [Number(this.year)];

					while (years.length < this.yearLimit && individual[years[0] - 1]) {
						years.unshift(years[0] - 1);
					}

					dateRanges = years.map(y => [`1-${y}`, `12-${y}`]);
				}

				if (dateRanges.length) {
					for (const dateRange of dateRanges) {
						const [firstMonth, lastMonth] = dateRange.map(d => Number(d.split("-")[0]));
						const year = Number(dateRange[1].split("-")[1]);

						const monthlyStat = [];

						for (let i = firstMonth; i <= lastMonth; i++) {
							monthlyStat.push(individual[`${i}-${year}`] ? individual[`${i}-${year}`][this.stat] || 0 : 0);
							if (labels.length <= lastMonth - firstMonth) {
								labels.push(
									this.dateRanges && lastMonth - firstMonth < 11 ? i : formatDate(`${year}-${i}-1`, "MMMM"),
								);
							}
						}

						this.series.push({
							name: this.dateRanges ? `${firstMonth}/${year} - ${lastMonth}/${year}` : year,
							data: monthlyStat,
						});
					}
				}
			}

			this.chartOptions = { ...this.chartOptions, labels };
		},
	},
});
</script>
