import dayjs from "dayjs";
import Vue from "vue";
import Vuex from "vuex";
import VueFileAgent from "vue-file-agent";
import "vue-file-agent/dist/vue-file-agent.css";

Vue.use(Vuex);
Vue.use(VueFileAgent);

function getFromLocalStorage(key, json) {
	const localData = localStorage.getItem(key);

	return localData ? (json ? JSON.parse(localData) : localData) : json ? {} : null;
}

function getDateRangeFromLocalStorage() {
	let firstDate = localStorage.getItem("firstDate");

	if (!firstDate) {
		localStorage.setItem("firstDate", dayjs().startOf("month").format("YYYY-MM-DD"));
	}

	localStorage.setItem("lastDate", dayjs().format("YYYY-MM-DD"));

	firstDate = localStorage.getItem("firstDate");
	const lastDate = localStorage.getItem("lastDate");

	return [firstDate, lastDate];
}

function getOfficeFromLocalStorage() {
	let localData = localStorage.getItem("offices");

	return localData ? localData.split(",") : [];
}

const today = dayjs();

const state = {
	user: getFromLocalStorage("user", true),
	company: getFromLocalStorage("company"),
	token: getFromLocalStorage("token"),
	needsPasswordChange: getFromLocalStorage("needsPasswordChange") == "true",
	darkMode: getFromLocalStorage("darkMode") == "true",
	horizontalMenu: false,
	profiles: [],
	features: getFromLocalStorage("features", true),
	selectedOffices: getOfficeFromLocalStorage(),
	dateRange: getDateRangeFromLocalStorage(),
	dateRangeDefaults: [
		{
			name: "Esta Semana",
			value: [today.startOf("week").format("YYYY-MM-DD"), today.format("YYYY-MM-DD")],
		},
		{
			name: "Este Mês",
			value: [today.startOf("month").format("YYYY-MM-DD"), today.format("YYYY-MM-DD")],
		},
		{
			name: "Este Trimestre",
			value: [today.startOf("quarter").format("YYYY-MM-DD"), today.format("YYYY-MM-DD")],
		},
		{
			name: "Este Ano",
			value: [today.startOf("year").format("YYYY-MM-DD"), today.format("YYYY-MM-DD")],
		},
		{
			name: "Última Semana",
			value: [
				today.subtract(1, "week").startOf("week").format("YYYY-MM-DD"),
				today.subtract(1, "week").endOf("week").format("YYYY-MM-DD"),
			],
		},
		{
			name: "Último Mês",
			value: [
				today.subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
				today.subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
			],
		},
		{
			name: "Último Trimestre",
			value: [
				today.subtract(1, "quarter").startOf("quarter").format("YYYY-MM-DD"),
				today.subtract(1, "quarter").endOf("quarter").format("YYYY-MM-DD"),
			],
		},
		{
			name: "Último Ano",
			value: [
				today.subtract(1, "year").startOf("year").format("YYYY-MM-DD"),
				today.subtract(1, "year").endOf("year").format("YYYY-MM-DD"),
			],
		},
		{
			name: "Desde Sempre",
			value: [
				getFromLocalStorage("oldestDate") ? getFromLocalStorage("oldestDate") : today.format("YYYY-MM-DD"),
				today.format("YYYY-MM-DD"),
			],
		},
	],

	toast: { open: false, message: "", color: "" },

	redirect: null,
	allOffices: [],
	offices: [],
	config: {},
};

const mutations = {
	login(state, { user, token, company, needsPasswordChange }) {
		if (user != null) {
			state.user = user;

			localStorage.setItem("user", JSON.stringify(state.user));
		}

		if (token != null) {
			state.token = token;
			state.selectedOffices = user.offices;

			localStorage.setItem("token", state.token);
			localStorage.setItem("offices", state.selectedOffices);
		}

		if (company != null) {
			state.company = company;

			localStorage.setItem("company", state.company);
		}

		if (needsPasswordChange != null) {
			state.needsPasswordChange = needsPasswordChange;

			localStorage.setItem("needsPasswordChange", state.needsPasswordChange);
		}
	},
	openToast(state, { message, color = "" }) {
		state.toast = { open: true, message, color };
	},
	closeToast(state) {
		state.toast.open = false;
	},
	setDateRange(state, dateRange) {
		dateRange.sort((a, b) => new Date(a) - new Date(b));

		state.dateRange = dateRange;

		localStorage.setItem("firstDate", state.dateRange[0]);
		localStorage.setItem("lastDate", state.dateRange[1]);
	},
	setOldestDate(state, oldestDate) {
		localStorage.setItem("oldestDate", dayjs(oldestDate).format("YYYY-MM-DD"));

		state.dateRangeDefaults[state.dateRangeDefaults.length - 1].value = [
			getFromLocalStorage("oldestDate") ? getFromLocalStorage("oldestDate") : today.format("YYYY-MM-DD"),
			today.format("YYYY-MM-DD"),
		];
	},
	setSelectedOffices(state, offices) {
		if (offices.length) {
			state.selectedOffices = offices;

			localStorage.setItem("offices", state.selectedOffices);
		}
	},
	setUser(state, user) {
		state.user = { ...state.user, ...user };

		localStorage.setItem("user", JSON.stringify(state.user));
	},
	setDarkMode(state, darkMode) {
		state.darkMode = darkMode;

		localStorage.setItem("darkMode", state.darkMode);
	},
	setHorizontalMenu(state, horizontalMenu) {
		state.horizontalMenu = horizontalMenu;
	},
	setProfiles(state, profiles) {
		state.profiles = profiles;
	},
	setOffices(state, offices) {
		state.allOffices = offices;
		state.offices = offices.filter(o => state.user.offices.includes(o.key) && o.active);

		const officeKeys = state.offices.map(office => office.key);

		const officesToRemove = state.selectedOffices.filter(o => !officeKeys.includes(o));

		if (officesToRemove.length) state.selectedOffices = state.selectedOffices.filter(o => officeKeys.includes(o));

		localStorage.setItem("offices", state.selectedOffices);
	},
	setFeatures(state, features) {
		const featureMap = {};
		for (const feature of features) {
			featureMap[feature.key] =
				state.user.role === "sysAdmin" ||
				feature.roles.includes(state.user.role) ||
				feature.users.includes(state.user._id) ||
				(!feature.roles.length && !feature.users.length);
		}

		state.features = featureMap;

		localStorage.setItem("features", JSON.stringify(state.features));
	},
	setRedirect(state, redirect) {
		state.redirect = redirect;
	},
	setConfig(state, config) {
		state.config = config;
	},
};

export default new Vuex.Store({
	state,
	mutations,
});
