<template>
	<apexchart type="line" :width="width" :height="height" :options="chartOptions" :series="series" />
</template>

<script>
import Vue from "vue";

export default Vue.extend({
	name: "LineChart",
	props: ["width", "height", "title", "seriesOverwrite", "labels", "colors", "opacity", "yFormatter"],
	created() {
		this.chartOptions = {
			...this.chartOptions,
			title: { ...this.chartOptions.title, text: this.title },
			fill: { ...this.chartOptions.fill, opacity: this.opacity ? this.opacity : 1 },
		};

		if (this.colors) this.chartOptions = { ...this.chartOptions, colors: this.colors };

		if (this.yFormatter) {
			this.chartOptions = {
				...this.chartOptions,
				yaxis: { labels: { ...this.chartOptions.yaxis.labels, formatter: this.yFormatter } },
				tooltip: { ...this.chartOptions.tooltip, y: { formatter: this.yFormatter } },
			};
		}

		this.setColors(this.$vuetify.theme.dark);

		if (this.seriesOverwrite) this.formatStats();
	},
	watch: {
		"$vuetify.theme.dark": function (newVal) {
			this.setColors(newVal);
		},
		seriesOverwrite: function () {
			this.formatStats();
		},
	},
	data() {
		return {
			series: [],
			chartOptions: {
				colors: ["#2E93fA", "#FF9800", "#E91E63", "#00BCD4", "#9C27B0", "#3F51B5", "#546E7A", "#FFC107"],
				labels: [],
				title: { text: "", align: "center", style: { color: "#ffffff" } },
				chart: { toolbar: { show: false } },
				stroke: { width: 2, curve: "smooth" },
				legend: { position: "bottom", labels: { colors: "#ffffff" } },
				xaxis: { labels: { style: { colors: "#ffffff" } } },
				yaxis: { labels: { style: { colors: "#ffffff" } } },
				tooltip: { theme: "dark" },
			},
		};
	},
	methods: {
		setColors(darkMode) {
			const textColor = darkMode ? "#ffffff" : "#000000";

			this.chartOptions = {
				...this.chartOptions,
				title: { ...this.chartOptions.title, text: this.title, style: { color: textColor } },
				legend: { ...this.chartOptions.legend, labels: { colors: textColor } },
				tooltip: { ...this.chartOptions.tooltip, theme: darkMode ? "dark" : "light" },
			};

			if (this.yFormatter) {
				this.chartOptions = {
					...this.chartOptions,
					yaxis: { labels: { style: { colors: textColor }, formatter: this.yFormatter } },
					tooltip: { theme: darkMode ? "dark" : "light", y: { formatter: this.yFormatter } },
				};
			}
		},
		formatStats() {
			this.series = this.seriesOverwrite;

			this.chartOptions.labels = this.labels;

			this.setColors(this.$vuetify.theme.dark);
		},
	},
});
</script>
