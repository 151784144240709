<template>
	<div>
		<v-data-table
			:loading="loading"
			:headers="headers"
			:items="stats"
			:items-per-page="10"
			:sort-by="sortBy"
			:sort-desc="sortDesc"
			must-sort
			no-data-text="Não há resultados"
			no-results-text="Não há resultados"
			:footer-props="{
				itemsPerPageOptions: [10, 20, 50, -1],
				itemsPerPageText: 'Linhas',
				itemsPerPageAllText: 'Tudo',
				showCurrentPage: true,
				showFirstLastPage: true,
			}"
			fixed-header
			height="530px"
			style="min-height: 600px"
		>
			<template v-slot:top>
				<v-toolbar>
					<v-toolbar-title>Ranking</v-toolbar-title>
					<v-select
						outlined
						dense
						v-model="groupBy"
						:items="[
							{ name: 'Recrutadores', value: 'recruiter' },
							{ name: 'Fontes', value: 'source' },
						]"
						item-text="name"
						item-value="value"
						label="Grupo"
						hide-details
						style="min-width: 225px; max-width: 225px; margin-left: 10px"
					/>
				</v-toolbar>
			</template>
			<template v-slot:item.name="{ item }">
				<div style="display: flex">
					<v-badge inline dot :color="item.color" style="margin-right: 5px" />
					<v-badge inline dot :value="groupBy === 'recruiter' && !item._id.active" color="error">
						{{ groupBy === "recruiter" ? item._id.name : config.sources.find(s => s.key === item._id)?.name }}
					</v-badge>
				</div>
			</template>
			<template v-slot:item.recruiterAssigned="{ item }">
				<v-btn
					:disabled="(user.role === 'recruiter' && item._id._id !== user._id) || !item.recruiterAssigned"
					@click="openDetailedStats(item, 'recruiterAssigned')"
				>
					{{ item.recruiterAssigned }}
				</v-btn>
			</template>
			<template v-slot:item.interviewScheduled="{ item }">
				<v-btn
					:disabled="(user.role === 'recruiter' && item._id._id !== user._id) || !item.interviewScheduled"
					@click="openDetailedStats(item, 'interviewScheduled')"
				>
					{{ item.interviewScheduled }}
				</v-btn>
			</template>
			<template v-slot:item.interviewDone="{ item }">
				<v-btn
					:disabled="(user.role === 'recruiter' && item._id._id !== user._id) || !item.interviewDone"
					@click="openDetailedStats(item, 'interviewDone')"
				>
					{{ item.interviewDone }}
				</v-btn>
			</template>
			<template v-slot:item.recruited="{ item }">
				<v-btn
					:disabled="(user.role === 'recruiter' && item._id._id !== user._id) || !item.recruited"
					@click="openDetailedStats(item, 'recruited')"
				>
					{{ item.recruited }}
				</v-btn>
			</template>
			<template v-slot:item.conversionRate="{ item }">
				{{ `${(item.conversionRate * 100).toFixed(2)}%` }}
				<br />
				<span style="color: DarkGray; font-size: 0.9em">
					{{
						`${item.recruited}/${
							company === "realtyart" ? item.interviewDone || item.recruited : item.recruiterAssigned
						}`
					}}
				</span>
			</template>
			<template v-slot:item.retentionRate="{ item }">
				{{ (item.retentionRate * 100).toFixed(2) }}%<br />
				<span style="color: DarkGray; font-size: 0.9em">
					{{ `${item.retentionActive}/${item.retentionTotal}` }}
				</span>
			</template>
			<template v-slot:item.billing="{ item }">
				{{ formatNumber(item.billing, true) }}
				<br />
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on" style="color: DarkGray; font-size: 0.9em">
							{{ formatNumber(item.medianBilling, true) }}
						</span>
					</template>
					<span>Faturação Mediana</span>
				</v-tooltip>
			</template>
		</v-data-table>
		<v-dialog v-model="showDetailedStats" max-width="1000px" @click:outside="closeDetailedStats">
			<v-sheet outlined rounded>
				<v-data-table
					:headers="detailedStatsHeaders"
					:items="detailedStats.leads"
					:loading="loadingDetails"
					:items-per-page="detailedStatsPerPage"
					:sort-by="detailedStatsSortBy"
					:sort-desc="detailedStatsSortDesc"
					:server-items-length="detailedStats.total"
					must-sort
					no-data-text="Não há resultados"
					no-results-text="Não há resultados"
					:footer-props="{
						itemsPerPageOptions: [10, 20, 50],
						itemsPerPageText: 'Linhas',
						showCurrentPage: true,
						showFirstLastPage: true,
					}"
					fixed-header
					height="530px"
					@update:options="setOptions"
				>
					<template v-slot:item.name="{ item }">
						{{ item.name }}
						<v-btn color="primary" icon small :href="`/leads/${item._id}`" target="_blank">
							<v-icon small>mdi-link</v-icon>
						</v-btn>
					</template>
					<template v-slot:item.office="{ item }">
						{{ allOffices.find(o => o.key === item.office)?.name }}
					</template>
					<template v-slot:item.source="{ item }">
						{{
							groupBy === "recruiter" ? config.sources.find(s => s.key === item.source)?.name : item.recruiter.name
						}}
					</template>
					<template v-slot:item.date="{ item }">
						{{ formatDate(item.date, "DD-MM-YYYY") }}
					</template>
					<template v-slot:item.endDate="{ item }">
						{{ item.endDate ? formatDate(item.endDate, "DD-MM-YYYY") : "" }}
					</template>
					<template v-slot:item.stage="{ item }">
						<v-chip style="margin-top: 3px" small :color="config.stages.find(s => s.key === item.stage).color">
							{{ config.stages.find(s => s.key === item.stage).name }}
						</v-chip>
					</template>
					<template v-slot:item.active="{ item }">
						<v-chip v-if="item.active === null"><v-icon>mdi-alert</v-icon></v-chip>
						<v-chip v-else-if="item.active === true" color="green">
							<v-icon>mdi-checkbox-marked-circle</v-icon>
						</v-chip>
						<v-chip v-else-if="item.active === false" color="red">
							<v-icon>mdi-close</v-icon>
						</v-chip>
					</template>
					<template v-slot:item.billing="{ item }">
						{{ formatNumber(item.user ? item.user.billing : 0, true) }}
					</template>
					<template v-if="selectedStage === 'recruited'" v-slot:footer.page-text>
						<v-btn icon @click="copyTable"><v-icon>mdi-content-copy</v-icon></v-btn>
					</template>
				</v-data-table>
			</v-sheet>
		</v-dialog>
	</div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import copy from "copy-to-clipboard";

import { getLeadTotals, getDetailedLeadStats } from "../../api/stats";

import { formatDate, formatNumber } from "../../utils/utils";

export default Vue.extend({
	name: "Ranking",
	props: ["personal", "recruiters", "selectedSources"],
	created() {
		this.handleGetTotals();
	},
	watch: {
		selectedOffices: function () {
			this.handleGetTotals();
		},
		dateRange: function () {
			this.handleGetTotals();
		},
		personal: function () {
			this.handleGetTotals();
		},
		recruiters: function () {
			this.handleGetTotals();
		},
		selectedSources: function () {
			this.handleGetTotals();
		},
		groupBy: function () {
			this.handleGetTotals();
		},
	},
	computed: mapState(["company", "user", "selectedOffices", "dateRange", "allOffices", "config"]),
	data() {
		return {
			loading: false,
			loadingDetails: false,

			groupBy: "recruiter",
			stats: [],

			headers: [
				{ text: "Nome", value: "name", width: 200 },
				{ text: "Leads", value: "recruiterAssigned", align: "right" },
				{ text: "Agendados", value: "interviewScheduled", align: "right" },
				{ text: "Entrevistados", value: "interviewDone", align: "right" },
				{ text: "Recrutados", value: "recruited", align: "right" },
				{ text: "Taxa de Conversão", value: "conversionRate", align: "right" },
				{ text: "Taxa de Retenção", value: "retentionRate", align: "right" },
			],
			sortBy: "recruited",
			sortDesc: true,

			detailedStatsHeaders: [
				{ text: "Nome", value: "name" },
				{ text: "Agência", value: "office" },
				{ text: "Fonte", value: "source" },
				{ text: "Data de Entrada", value: "date", align: "right" },
				{ text: "Data de Saída", value: "endDate", align: "right", sortable: false },
				{ text: "Ativo", value: "active", sortable: false },
			],
			detailedStatsPerPage: 10,
			detailedStatsSortBy: "date",
			detailedStatsSortDesc: true,

			showDetailedStats: false,
			detailedStats: [],
			selectedId: null,
			selectedStage: null,
		};
	},
	methods: {
		formatDate,
		formatNumber,
		async handleGetTotals() {
			this.loading = true;

			this.stats = [];

			const response = await getLeadTotals(this.groupBy, this.personal, this.recruiters, this.selectedSources);

			if (response.status === 200) {
				this.stats = response.data;

				const colors = [
					"#2E93fA",
					"#F44336",
					"#FF9800",
					"#E91E63",
					"#9C27B0",
					"#66DA26",
					"#546E7A",
					"#2E93fA",
					"#F44336",
					"#FF9800",
					"#E91E63",
					"#9C27B0",
					"#66DA26",
					"#546E7A",
					"#2E93fA",
					"#F44336",
					"#FF9800",
					"#E91E63",
					"#9C27B0",
					"#66DA26",
					"#546E7A",
				];

				let i = 0;
				for (const recruiter of this.stats) {
					recruiter.color = colors[i];

					i++;
				}

				if (this.headers.length === 7 && this.company !== "realtyart") {
					this.headers.push({ text: "Faturação", value: "billing", align: "right", width: 150 });
				}
			}

			this.loading = false;
		},
		setOptions(options) {
			if (
				this.detailedStatsPerPage !== options.itemsPerPage ||
				this.detailedStatsSortBy !== options.sortBy ||
				this.detailedStatsSortDesc !== options.sortDesc
			) {
				this.page = 0;
			}

			this.detailedStatsPage = options.page - 1;
			this.detailedStatsPerPage = options.itemsPerPage;
			this.detailedStatsSortBy = options.sortBy;
			this.detailedStatsSortDesc = options.sortDesc;

			this.handleGetDetailedStats();
		},
		openDetailedStats(item, stage) {
			this.selectedId = this.groupBy === "recruiter" ? item._id._id : item._id;
			this.selectedStage = stage;

			if (["recruiterAssigned", "interviewScheduled", "interviewDone"].includes(stage)) {
				this.detailedStatsHeaders = [
					{ text: "Nome", value: "name" },
					{ text: "Agência", value: "office" },
					{ text: this.groupBy === "recruiter" ? "Fonte" : "Recrutador", value: "source" },
					{ text: "Data de Entrada", value: "date", align: "right" },
					{ text: "Etapa", value: "stage", sortable: false },
				];
			} else {
				this.detailedStatsHeaders = [
					{ text: "Nome", value: "name" },
					{ text: "Agência", value: "office" },
					{ text: this.groupBy === "recruiter" ? "Fonte" : "Recrutador", value: "source" },
					{ text: "Data de Entrada", value: "date", align: "right", width: 120 },
					{ text: "Data de Saída", value: "endDate", align: "right", width: 120 },
					{ text: "Ativo", value: "active", sortable: false },
				];

				if (this.company !== "realtyart") {
					this.detailedStatsHeaders.push({ text: "Faturação", value: "billing", align: "right", width: 120 });
				}
			}

			this.handleGetDetailedStats();

			this.showDetailedStats = true;
		},
		async handleGetDetailedStats() {
			if (!this.loadingDetails) {
				this.loadingDetails = true;

				const response = await getDetailedLeadStats(
					this.groupBy,
					this.selectedId,
					this.selectedStage,
					this.detailedStatsPage,
					this.detailedStatsPerPage,
					this.detailedStatsSortBy,
					this.detailedStatsSortDesc,
				);

				this.detailedStats = response.data;
			}
			this.loadingDetails = false;
		},
		async copyTable() {
			let lines = "";

			const response = await getDetailedLeadStats(
				this.groupBy,
				this.selectedId,
				this.selectedStage,
				0,
				1000,
				this.detailedStatsSortBy,
				this.detailedStatsSortDesc,
			);

			if (response.status === 200) {
				for (const item of response.data.leads) {
					lines += `
					<tr>
						<td align="center">${item.name}</td>
						<td align="center">${this.allOffices.find(o => o.key === item.office)?.name}</td>
						<td align="center">${this.config.sources.find(s => s.key === item.source)?.name}</td>
						<td align="center">${formatDate(item.date, "DD-MM-YYYY")}</td>
						<td align="center">${item.endDate ? formatDate(item.endDate, "DD-MM-YYYY") : ""}</td>
						<td align="center">${item.active ? "Sim" : item.active === false ? "Não" : "Não Sei"}</td>
						<td align="center">${formatNumber(item.user ? item.user.billing : 0, false, true)}</td>
					</tr>
					`;
				}

				copy(
					`<style>
					table, th, td { border: 1px solid black; border-collapse: collapse; font-size: 0.9em }
				</style>
				<table>
					<tr>
						<th>Nome</th>
						<th>Agência</th>
						<th>Fonte</th>
						<th>Data de Entrada</th>
						<th>Data de Saída</th>
						<th>Ativo</th>
						<th>Faturação</th>
					</tr>
					${lines}
				</table>`,
					{
						asHtml: true,
						format: "text/html",
					},
				);
			}
		},
		closeDetailedStats() {
			this.showDetailedStats = false;
		},
	},
});
</script>
