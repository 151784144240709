import { api } from "../utils/request";

async function getSessions(page, perPage, sortBy, sortDesc, app, search) {
	let query = "";
	query += page ? `?page=${page}` : "";
	query += perPage ? `${query ? "&" : "?"}perPage=${perPage}` : "";
	query += sortBy ? `${query ? "&" : "?"}sortBy=${sortBy}` : "";
	query += sortDesc ? `${query ? "&" : "?"}sortDesc=${sortDesc}` : "";
	query += app ? `${query ? "&" : "?"}app=${app}` : "";
	query += search ? `${query ? "&" : "?"}search=${search}` : "";

	const res = await api({
		method: "get",
		url: `/api/stats/sessions${query}`,
	});

	return res;
}

export { getSessions };
