import { api } from "../utils/request";

async function addFiles(group, id, path, files, type) {
	let query = "";
	query += type ? `${query ? "&" : "?"}type=${type}` : "";

	const res = await api({
		method: "post",
		url: `/api/files/${group}/${id}/${path}${query}`,
		data: files,
		headers: { "Content-Type": "multipart/form-data" },
	});

	return res;
}

export { addFiles };
