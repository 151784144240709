var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('DataTable',{attrs:{"type":"invoices","featuresMap":{
		add: _vm.features.addInvoice,
		edit: _vm.features.editInvoice,
		delete: _vm.features.deleteInvoice,
		addFiles: _vm.features.addInvoiceFiles,
		listTotals: _vm.features.listInvoiceTotals,
	},"title":"Faturas","filtersConfig":_vm.filtersConfig,"getItems":_vm.getInvoices,"patchItem":_vm.patchInvoice,"deleteItem":_vm.deleteInvoice,"actions":['comments', 'tasks', 'files', 'changes', 'view', 'edit', 'delete'],"profileOverwrite":_vm.profileOverwrite},scopedSlots:_vm._u([{key:"totals",fn:function({ monthly, totals }){return [_c('InvoiceTotals',{attrs:{"id":"totals","monthly":monthly,"totals":totals}})]}},{key:"detail",fn:function({ selectedItem, closeDetail }){return [_c('InvoiceForm',{attrs:{"invoice":selectedItem,"onClose":closeDetail}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }