import { api } from "../utils/request";

async function getLeads(page, perPage, sortBy, sortDesc, filters) {
	const res = await api({
		method: "post",
		url: "/api/leads/search",
		data: { page, perPage, sortBy, sortDesc, filters },
	});

	return res;
}

async function getFilters() {
	const res = await api({
		method: "get",
		url: "/api/leads/filters",
	});

	return res;
}

async function getLead(id) {
	const res = await api({
		method: "get",
		url: `/api/leads/${id}`,
	});

	return res;
}

async function addLead(lead) {
	const res = await api({
		method: "post",
		url: "/api/leads",
		data: lead,
	});

	return res;
}

async function editLead(lead) {
	const res = await api({
		method: "put",
		url: `/api/leads/${lead._id}`,
		data: lead,
	});

	return res;
}

async function addUserDraft(id, user) {
	const res = await api({
		method: "put",
		url: `/api/leads/${id}/draft`,
		data: user,
	});

	return res;
}

async function patchLead(lead) {
	const res = await api({
		method: "patch",
		url: `/api/leads/${lead._id}`,
		data: lead,
	});

	return res;
}

async function deleteLead(id) {
	const res = await api({
		method: "delete",
		url: `/api/leads/${id}`,
	});

	return res;
}

async function addEvents(id, events) {
	const res = await api({
		method: "post",
		url: `/api/leads/${id}/events`,
		data: events,
	});

	return res;
}

async function deleteLastEvent(id) {
	const res = await api({
		method: "delete",
		url: `/api/leads/${id}/events`,
	});

	return res;
}

async function toggleAirTable(id, add) {
	const res = await api({
		method: "post",
		url: `/api/leads/${id}/airtable`,
		data: add,
	});

	return res;
}

export {
	getLeads,
	getFilters,
	getLead,
	addLead,
	editLead,
	addUserDraft,
	patchLead,
	deleteLead,
	addEvents,
	deleteLastEvent,
	toggleAirTable,
};
