<template>
	<v-row>
		<v-col>
			<v-autocomplete
				outlined
				label="Perfil"
				:value="profile"
				:items="profiles.filter(p => p.type === type)"
				item-value="_id"
				item-text="name"
				return-object
				chips
				small-chips
				@change="handleChangeProfile"
			/>
		</v-col>
		<v-col v-if="company === 'realtyart' || features.addProfile" cols="2" style="position: relative; top: 10px">
			<v-tooltip top>
				<template v-slot:activator="{ on, attrs }">
					<v-btn v-bind="attrs" v-on="on" icon @click="() => openProfileDetail()">
						<v-icon>mdi-pencil</v-icon>
					</v-btn>
				</template>
				<span>Editar perfil</span>
			</v-tooltip>
		</v-col>
		<v-col v-if="features.addProfile" cols="2" style="position: relative; top: 10px">
			<DeletionDialog deletionText="este perfil" :onConfirm="handleDeleteProfile">
				<template v-slot:activator="{ onClick }">
					<v-tooltip top>
						<template v-slot:activator="{ on, attrs }">
							<v-btn v-bind="attrs" v-on="on" icon @click="onClick">
								<v-icon>mdi-delete</v-icon>
							</v-btn>
						</template>
						<span>Apagar perfil</span>
					</v-tooltip>
				</template>
			</DeletionDialog>
		</v-col>
		<v-col v-if="features.addProfile" cols="2" style="position: relative; top: 10px">
			<v-tooltip top>
				<template v-slot:activator="{ on, attrs }">
					<v-btn v-bind="attrs" v-on="on" icon @click="() => openProfileDetail(true)">
						<v-icon>mdi-plus</v-icon>
					</v-btn>
				</template>
				<span>Novo perfil</span>
			</v-tooltip>
		</v-col>
		<v-dialog v-model="showProfileDetail" max-width="500px">
			<v-sheet style="padding: 20px">
				<h3>{{ addProfile ? "Novo" : "Editar" }} Perfil</h3>
				<br />
				<v-text-field outlined dense v-model="profileName" label="Nome" />
				<v-row>
					<v-col>
						<v-autocomplete
							dense
							outlined
							label="Colunas"
							:items="headers.filter(h => !columns.includes(h.value) && !expandedColumns.includes(h.value))"
							hide-details
							@change="value => addColumn(value, 'columns')"
							style="margin-bottom: 5px"
						/>
						<SlickList
							axis="y"
							v-model="columnsLocal"
							:distance="10"
							@input="value => setParentProp('columns', value)"
							style="border: 1px solid #555; min-height: 100px"
						>
							<SlickItem v-for="(column, i) in columns" :key="column" :index="i" style="z-index: 1000">
								<v-card
									style="
										background-color: #555;
										margin: 5px;
										padding: 10px;
										cursor: pointer;
										display: flex;
										justify-content: space-between;
									"
								>
									<v-btn x-small @click="() => deleteColumn(column, 'columns')">
										<v-icon small>mdi-delete</v-icon>
									</v-btn>
									{{ headers.find(h => h.value === column)?.text }}
									<v-btn x-small @click="() => moveColumnTo(column, 'columns', 'expandedColumns')">
										<v-icon small>mdi-arrow-right</v-icon>
									</v-btn>
								</v-card>
							</SlickItem>
						</SlickList>
					</v-col>
					<v-col>
						<v-autocomplete
							dense
							outlined
							label="Expandido"
							:items="headers.filter(h => !columns.includes(h.value) && !expandedColumns.includes(h.value))"
							hide-details
							@change="value => addColumn(value, 'expandedColumns')"
							style="margin-bottom: 5px"
						/>
						<SlickList
							axis="y"
							v-model="expandedColumnsLocal"
							:distance="10"
							@input="value => setParentProp('expandedColumns', value)"
							style="border: 1px solid #555; min-height: 100px"
						>
							<SlickItem v-for="(column, i) in expandedColumns" :key="column" :index="i" style="z-index: 1000">
								<v-card
									style="
										background-color: #555;
										margin: 5px;
										padding: 10px;
										cursor: pointer;
										display: flex;
										justify-content: space-between;
									"
								>
									<v-btn x-small @click="() => moveColumnTo(column, 'expandedColumns', 'columns')">
										<v-icon small>mdi-arrow-left</v-icon>
									</v-btn>
									{{ headers.find(h => h.value === column)?.text }}
									<v-btn x-small @click="() => deleteColumn(column, 'expandedColumns')">
										<v-icon small>mdi-delete</v-icon>
									</v-btn>
								</v-card>
							</SlickItem>
						</SlickList>
					</v-col>
				</v-row>
				<v-checkbox v-model="saveFilters" label="Guardar filtros" persistent-hint />
				<v-btn @click="addProfile ? handleAddProfile() : handleEditProfile()">
					{{ addProfile ? "Adicionar" : "Editar" }}
				</v-btn>
			</v-sheet>
		</v-dialog>
	</v-row>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import { SlickList, SlickItem } from "vue-slicksort";

import DeletionDialog from "../components/DeletionDialog.vue";

import { patchUser } from "../api/users";

export default Vue.extend({
	name: "Profiles",
	components: { SlickList, SlickItem, DeletionDialog },
	props: ["type", "profile", "columns", "expandedColumns", "filters", "headers", "formatColumns", "updateParent"],
	created() {
		this.columnsLocal = this.columns;
		this.expandedColumnsLocal = this.expandedColumns;
	},
	watch: {
		columns: function (value) {
			this.columnsLocal = value;
		},
		expandedColumns: function (value) {
			this.expandedColumnsLocal = value;
		},
	},
	computed: mapState(["user", "company", "features", "profiles"]),
	data() {
		return {
			columnsLocal: [],
			expandedColumnsLocal: [],

			saveFilters: false,

			showProfileDetail: false,
			profileName: null,
			addProfile: false,
		};
	},
	methods: {
		...mapMutations(["setProfiles"]),
		setParentProp(prop, value) {
			this.updateParent(prop, value);

			this.formatColumns();
		},
		moveColumnTo(column, from, to) {
			const update = this[from].filter(c => c !== column);
			this[to].push(column);

			this.setParentProp(from, update);
		},
		addColumn(column, from) {
			this[from].push(column);

			this.formatColumns();
		},
		deleteColumn(column, from) {
			const update = this[from].filter(c => c !== column);

			this.setParentProp(from, update);
		},
		async handleChangeProfile(profile) {
			for (const p of this.profiles) {
				if (p.type === this.type) p.active = !!(p._id === profile._id);
			}

			const response = await patchUser({ _id: this.user._id, profiles: this.profiles });

			this.setProfiles(response.data.profiles);
		},
		async handleEditProfile() {
			const profile = this.profiles.find(p => p._id === this.profile._id);

			profile.name = this.profileName;
			profile.columns = this.columns;
			profile.expanded = this.expandedColumns;
			if (this.saveFilters) profile.filters = this.filters;

			delete profile.filters.search;

			await patchUser({ _id: this.user._id, profiles: this.profiles });

			this.showProfileDetail = false;

			this.formatColumns();
		},
		async handleDeleteProfile() {
			const profiles = this.profiles.filter(p => p._id !== this.profile._id);

			const typeProfiles = profiles.filter(p => p.type === this.type);

			if (typeProfiles.length === 0) return;

			typeProfiles[0].active = true;

			const response = await patchUser({ _id: this.user._id, profiles });

			this.setProfiles(response.data.profiles);
		},
		openProfileDetail(add) {
			this.showProfileDetail = true;

			this.profileName = add ? "" : this.profile.name;

			this.addProfile = add;
		},
		async handleAddProfile() {
			const profile = this.profiles.find(p => p._id === this.profile._id);

			profile.active = false;

			delete this.filters.search;

			this.profiles.push({
				active: true,
				name: this.profileName,
				type: this.type,
				columns: this.columns,
				expanded: this.expandedColumns,
				filters: this.filters,
			});

			await patchUser({ _id: this.user._id, profiles: this.profiles });

			this.showProfileDetail = false;

			this.formatColumns();
		},
	},
});
</script>
