<template>
	<DataTable
		type="invoices"
		:featuresMap="{
			add: features.addInvoice,
			edit: features.editInvoice,
			delete: features.deleteInvoice,
			addFiles: features.addInvoiceFiles,
			listTotals: features.listInvoiceTotals,
		}"
		title="Faturas"
		:filtersConfig="filtersConfig"
		:getItems="getInvoices"
		:patchItem="patchInvoice"
		:deleteItem="deleteInvoice"
		:actions="['comments', 'tasks', 'files', 'changes', 'view', 'edit', 'delete']"
		:profileOverwrite="profileOverwrite"
	>
		<template v-slot:totals="{ monthly, totals }">
			<InvoiceTotals id="totals" :monthly="monthly" :totals="totals" />
		</template>
		<template v-slot:detail="{ selectedItem, closeDetail }">
			<InvoiceForm :invoice="selectedItem" :onClose="closeDetail" />
		</template>
	</DataTable>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";

import DataTable from "../components/DataTable.vue";
import InvoiceTotals from "../components/InvoiceTotals.vue";
import InvoiceForm from "../components/InvoiceForm.vue";

import { getInvoices, patchInvoice, deleteInvoice } from "../api/invoices";

export default Vue.extend({
	name: "Invoices",
	props: ["profileOverwrite"],
	components: { DataTable, InvoiceTotals, InvoiceForm },
	computed: mapState(["features"]),
	data() {
		return {
			filtersConfig: [
				{ type: "autocomplete", label: "Consultores", value: "consultants", condition: !this.profileOverwrite },
				{
					type: "autocomplete",
					label: "Equipas",
					value: "teams",
					items: "config.teams",
					itemValue: "key",
					condition: !this.profileOverwrite,
				},
				{
					type: "autocomplete",
					label: "Diretores Comerciais",
					value: "commercialDirectors",
					condition: !this.profileOverwrite,
				},
				{ type: "autocomplete", label: "Gestoras de Crédito", value: "creditManager", items: "creditManagers" },
				{ type: "autocomplete", label: "Recrutadores", value: "recruiters" },
				{
					type: "buttonToggle",
					value: "clientProofDate",
					options: [
						{ value: false, label: "Cliente não comprovou" },
						{ value: "null", label: "Tudo" },
						{ value: true, label: "Cliente já comprovou" },
					],
				},
				{
					type: "buttonToggle",
					value: "clientPaymentDate",
					options: [
						{ value: false, label: "Cliente não pagou" },
						{ value: "null", label: "Tudo" },
						{ value: true, label: "Cliente já pagou" },
					],
				},
				{
					type: "buttonToggle",
					value: "consultantPaymentDate",
					options: [
						{ value: false, label: "Consultor não recebeu" },
						{ value: "null", label: "Tudo" },
						{ value: true, label: "Consultor já recebeu" },
					],
				},
				{
					type: "buttonToggle",
					value: "sentBills",
					options: [
						{ value: false, label: "Contas não enviadas" },
						{ value: "null", label: "Tudo" },
						{ value: true, label: "Contas enviadas" },
					],
				},
				{
					type: "buttonToggle",
					value: "isBank",
					options: [
						{ value: false, label: "Imobiliário" },
						{ value: "null", label: "Tudo" },
						{ value: true, label: "Diversos" },
					],
				},
				{
					type: "buttonToggle",
					value: "isExternal",
					options: [
						{ value: "internal", label: "Interno" },
						{ value: "null", label: "Tudo" },
						{ value: "external", label: "Externo" },
						{ value: "externalFinder", label: "Ang Externo" },
						{ value: "externalSeller", label: "Vend Externo" },
					],
				},
				{
					type: "buttonToggle",
					value: "isShared",
					options: [
						{ value: false, label: "Não Partilhado" },
						{ value: "null", label: "Tudo" },
						{ value: true, label: "Partilhado" },
					],
				},
			],
		};
	},
	methods: { getInvoices, patchInvoice, deleteInvoice },
});
</script>
