<template>
	<apexchart type="bar" :width="width" :height="height" :options="chartOptions" :series="series" />
</template>

<script>
import Vue from "vue";

import { formatDate } from "../../utils/utils";

export default Vue.extend({
	name: "BarChart",
	props: [
		"width",
		"height",
		"title",
		"stats",
		"stat",
		"seriesOverwrite",
		"labels",
		"year",
		"yearLimit",
		"dateRanges",
		"showLegend",
		"legendFormatter",
		"dataLabelFormatter",
		"xFormatter",
		"yFormatter",
		"centerLabels",
		"horizontalLabels",
		"hideGrid",
		"distributed",
		"stacked",
		"horizontal",
	],
	created() {
		this.setChartOptions();

		if (this.stats || this.seriesOverwrite) this.formatStats();
	},
	watch: {
		"$vuetify.theme.dark": function (newVal) {
			this.setColors(newVal);
		},
		stats: function () {
			this.formatStats();
		},
		stat: function () {
			this.setChartOptions();
		},
		seriesOverwrite: function () {
			this.formatStats();
		},
		dateRanges: function () {
			this.formatStats();
		},
	},
	data() {
		return {
			series: [],
			chartOptions: {
				chart: { toolbar: { show: false } },
				dataLabels: {
					enabled: true,
					style: { fontWeight: "normal" },
					background: { enabled: true, foreColor: "black" },
				},
				colors: ["#2E93fA", "#FF9800", "#E91E63", "#00BCD4", "#9C27B0", "#3F51B5", "#546E7A", "#FFC107"],
				title: { text: "", align: "left", style: { color: "#ffffff" } },
				legend: { labels: { colors: "#ffffff" } },
				plotOptions: {
					bar: {
						horizontal: this.horizontal,
						columnWidth: "90%",
						distributed: this.distributed,
						dataLabels: { position: "top", hideOverflowingLabels: true },
					},
				},
				xaxis: { labels: { style: { colors: "#ffffff" } } },
				yaxis: { labels: { style: { colors: "#ffffff" } } },
				tooltip: { theme: "dark" },
				annotations: { points: [] },
			},
		};
	},
	methods: {
		setChartOptions() {
			this.chartOptions = {
				...this.chartOptions,
				title: { ...this.chartOptions.title, text: this.title },
				legend: { ...this.chartOptions.legend, show: !!this.showLegend },
				plotOptions: {
					...this.chartOptions.plotOptions,
					bar: {
						...this.chartOptions.plotOptions.bar,
						dataLabels: {
							...this.chartOptions.plotOptions.bar.dataLabels,
							position: this.centerLabels ? "center" : "top",
							orientation: this.horizontalLabels ? "vertical" : "horizontal",
						},
					},
				},
			};

			if (this.legendFormatter) {
				this.chartOptions.legend = { ...this.chartOptions.legend, formatter: this.legendFormatter };
			}

			if (this.dataLabelFormatter) {
				this.chartOptions.dataLabels = { ...this.chartOptions.dataLabels, formatter: this.dataLabelFormatter };
			}

			if (this.xFormatter) {
				this.chartOptions.tooltip = { ...this.chartOptions.tooltip, x: { formatter: this.xFormatter } };
			}

			if (this.yFormatter) {
				this.chartOptions = {
					...this.chartOptions,
					yaxis: { labels: { ...this.chartOptions.yaxis.labels, formatter: this.yFormatter } },
					tooltip: { ...this.chartOptions.tooltip, y: { formatter: this.yFormatter } },
				};
			}

			if (this.stacked) {
				this.chartOptions.chart = { ...this.chartOptions.chart, stacked: true, stackType: "100%" };
			}

			if (this.hideGrid) {
				this.chartOptions = {
					...this.chartOptions,
					grid: { show: false },
					xaxis: { show: false, axisBorder: { show: false }, axisTicks: { show: false }, labels: { show: false } },
					yaxis: { show: false },
				};
			}

			this.setColors(this.$vuetify.theme.dark);
		},
		setColors(darkMode) {
			const textColor = darkMode ? "#ffffff" : "#000000";

			this.chartOptions = {
				...this.chartOptions,
				title: { ...this.chartOptions.title, style: { color: textColor } },
				legend: { ...this.chartOptions.legend, labels: { colors: textColor } },
				xaxis: {
					...this.chartOptions.xaxis,
					labels: { ...this.chartOptions.xaxis.labels, style: { colors: textColor } },
				},
				yaxis: {
					...this.chartOptions.yaxis,
					show: !this.hideGrid,
					labels: { ...this.chartOptions.yaxis.labels, style: { colors: textColor } },
				},
				tooltip: { ...this.chartOptions.tooltip, theme: darkMode ? "dark" : "light" },
			};

			if (!this.hideGrid && this.yFormatter) {
				this.chartOptions = {
					...this.chartOptions,
					yaxis: {
						...this.chartOptions.yaxis,
						labels: { ...this.chartOptions.yaxis.labels, formatter: this.yFormatter },
					},
				};
			}
		},
		formatStats() {
			this.series = [];
			const labels = this.labels || [];

			let dateRanges = this.dateRanges || [];

			if (this.year && this.yearLimit) {
				const years = [Number(this.year)];

				while (years.length < this.yearLimit && this.stats[years[0] - 1]) {
					years.unshift(years[0] - 1);
				}

				dateRanges = years.map(y => [`1-${y}`, `12-${y}`]);
			}

			if (dateRanges.length) {
				for (const dateRange of dateRanges) {
					const [firstMonth, lastMonth] = dateRange.map(d => Number(d.split("-")[0]));
					const year = Number(dateRange[1].split("-")[1]);

					const monthlyStats = [];

					for (let i = firstMonth; i <= lastMonth; i++) {
						monthlyStats.push(this.stats[`${i}-${year}`] ? this.stats[`${i}-${year}`][this.stat] : 0);
						if (labels.length <= lastMonth - firstMonth) {
							labels.push(
								this.dateRanges && lastMonth - firstMonth < 11 ? i : formatDate(`${year}-${i}-1`, "MMM"),
							);
						}
					}

					this.series.push({
						name: this.dateRanges ? `${firstMonth}/${year} - ${lastMonth}/${year}` : year,
						data: monthlyStats,
					});
				}
			} else if (this.seriesOverwrite) {
				this.series = this.seriesOverwrite;
			} else if (this.stats.length) {
				const data = [];
				for (const group of this.stats) {
					data.push(group.total);
					labels.push(group._id);
				}

				this.series.push({ name: this.title, data });
			}

			this.chartOptions = { ...this.chartOptions, labels };
		},
	},
});
</script>
