<style scoped>
.chip {
	width: 165px;
	justify-content: center;
}
</style>

<template>
	<v-card class="widgetCard" width="100%" style="display: flex; flex-direction: column; justify-content: flex-end">
		<apexchart type="bar" width="100%" height="500px" :options="chartOptions" :series="totals" />
		<v-dialog v-model="showDetailedStats" max-width="1500px" @click:outside="closeDetailedStats">
			<v-sheet outlined rounded>
				<v-data-table
					:headers="headers"
					:items="detailedStats"
					:items-per-page="perPage"
					:sort-by="sortBy"
					:sort-desc="sortDesc"
					must-sort
					no-data-text="Não há resultados"
					no-results-text="Não há resultados"
					:footer-props="{
						itemsPerPageOptions: [10, 20, 50],
						itemsPerPageText: 'Linhas',
						showCurrentPage: true,
						showFirstLastPage: true,
					}"
					fixed-header
					height="530px"
				>
					<template v-slot:item.office="{ item }">
						{{ allOffices.find(o => o.key === item.office).name }}
					</template>
					<template v-slot:item.ref="{ item }">
						<v-chip color="dark-grey" class="chip">{{ item.ref }}</v-chip>
					</template>
					<template v-slot:item.reservationDate="{ item }">
						{{ formatDate(item.reservationDate, "DD-MM-YYYY") }}
					</template>
					<template v-slot:item.predictedExecutionDate="{ item }">
						{{ formatDate(item.predictedExecutionDate, "DD-MM-YYYY") }}
					</template>
					<template v-slot:item.finder="{ item }">
						<v-chip outlined :color="item.isExternalFinder ? 'blue' : ''" class="chip">
							{{ item.finderUser ? item.finderUser.name : item.finder }}
						</v-chip>
					</template>
					<template v-slot:item.seller="{ item }">
						<v-chip outlined :color="item.isExternalSeller ? 'blue' : ''" class="chip">
							{{ item.sellerUser ? item.sellerUser.name : item.seller }}
						</v-chip>
					</template>
					<template :slot="'item.billingDates.type'" slot-scope="{ item }">
						{{ config.billingTypes.find(t => t.key === item.billingDates.type).name }}
					</template>
					<template :slot="'item.billingDates.percentage'" slot-scope="{ item }">
						{{ formatNumber(item.billingDates.percentage, false, false, 0) }}%
					</template>
					<template v-slot:item.tipyValue="{ item }">
						{{ formatNumber(item.tipyValue, true) }}
					</template>
				</v-data-table>
			</v-sheet>
		</v-dialog>
	</v-card>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";

import { getPredictedStats } from "../../api/stats";

import { formatNumber, formatDate } from "../../utils/utils";

export default Vue.extend({
	name: "PredictedBarChart",
	created() {
		this.handleGetStats();

		this.setColors(this.$vuetify.theme.dark);
	},
	watch: {
		selectedOffices: function () {
			this.handleGetStats();
		},
		"$vuetify.theme.dark": function (darkMode) {
			this.setColors(darkMode);
		},
	},
	computed: mapState(["selectedOffices", "allOffices", "config"]),
	data() {
		return {
			loading: false,

			stats: null,
			totals: [],
			chartOptions: {
				labels: [],
				chart: {
					stacked: true,
					toolbar: {
						show: false,
					},
					events: {
						dataPointSelection: (event, chartContext, config) => {
							if (config.seriesIndex === 2) {
								this.openDetailedStats(this.chartOptions.labels[config.dataPointIndex]);
							}
						},
					},
				},
				dataLabels: {
					enabled: true,
					style: { fontWeight: "normal" },
					background: { enabled: true, foreColor: "black" },
					formatter: (number, e) => {
						const hasBilling = this.totals[0].data[e.dataPointIndex] || this.totals[1].data[e.dataPointIndex];

						return hasBilling ? formatNumber(number) : "";
					},
				},
				colors: ["#FF9800", "#00BCD4", "#546E7A"],
				title: {
					text: "Previsões Mensais",
					align: "left",
					style: {
						color: "#ffffff",
					},
				},
				legend: {
					labels: {
						colors: "#ffffff",
					},
				},
				plotOptions: {
					bar: {
						dataLabels: {
							position: "top",
						},
					},
				},
				xaxis: {
					labels: {
						style: {
							colors: "#ffffff",
						},
					},
				},
				yaxis: {
					labels: {
						style: {
							colors: "#ffffff",
						},
						formatter: formatNumber,
					},
				},
				tooltip: {
					theme: "dark",
				},
				annotations: {
					points: [],
				},
			},

			detailedStats: [],
			showDetailedStats: false,

			headers: [
				{ text: "Agência", value: "office" },
				{ text: "Ref", value: "ref", align: "center" },
				{ text: "Reservado", value: "reservationDate" },
				{ text: "Previsão", value: "predictedExecutionDate" },
				{ text: "Angariador", value: "finder", align: "center" },
				{ text: "Vendedor", value: "seller", align: "center" },
				{ text: "Tipo", value: "billingDates.type" },
				{ text: "Percentagem", value: "billingDates.percentage", align: "right" },
				{ text: "Comissão da Agência", value: "tipyValue", align: "right" },
			],
			perPage: 10,
			sortBy: "reservationDate",
			sortDesc: true,
		};
	},
	methods: {
		formatNumber,
		formatDate,
		setColors(darkMode) {
			const textColor = darkMode ? "#ffffff" : "#000000";

			this.chartOptions = {
				...this.chartOptions,
				title: { ...this.chartOptions.title, style: { color: textColor } },
				legend: { labels: { colors: textColor } },
				xaxis: { labels: { style: { colors: textColor } } },
				yaxis: { labels: { style: { colors: textColor }, formatter: formatNumber } },
				tooltip: { theme: darkMode ? "dark" : "light" },
			};
		},
		async handleGetStats() {
			this.loading = true;

			const response = await getPredictedStats();

			if (response.status === 200) {
				this.stats = response.data;
				this.totals = [
					{ name: "Imobiliário", data: [] },
					{ name: "Diversos", data: [] },
					{ name: "Previsão", data: [] },
				];
				this.chartOptions.labels = [];

				const annotations = [];
				for (const monthYear in response.data) {
					const data = response.data[monthYear];

					if (data.total || data.totalBanks || data.predicted) {
						this.chartOptions.labels.push(monthYear);

						this.totals[0].data.push(data.total);
						this.totals[1].data.push(data.totalBanks);
						this.totals[2].data.push(data.predicted);

						let total = data.total + data.totalBanks + data.predicted;

						annotations.push({
							x: monthYear,
							y: total,
							marker: {
								size: 3,
							},
							label: {
								text: formatNumber(total, true),
								offsetY: -5,
								style: { fontSize: "0.85em", fontWeight: "bold" },
							},
						});
					}
				}

				this.chartOptions = {
					...this.chartOptions,
					annotations: {
						points: annotations,
					},
				};
			}

			this.loading = false;
		},
		openDetailedStats(monthYear) {
			this.detailedStats = this.stats[monthYear].reservations;

			this.showDetailedStats = true;
		},
		closeDetailedStats() {
			this.detailedStats = [];

			this.showDetailedStats = false;
		},
	},
});
</script>
