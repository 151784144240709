import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import apexcharts from "./plugins/apexcharts";

import { addError } from "./api/errors";

if (process.env.VUE_APP_ENV === "prod") {
	Vue.config.errorHandler = (err, vm, info) => {
		addError({ type: "frontend", data: { stack: err.stack.toString(), info } });
	};
}

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	vuetify,
	apexcharts,
	render: h => h(App),
}).$mount("#app");
