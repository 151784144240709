import { api } from "../utils/request";

async function getErrors() {
	const res = await api({
		method: "get",
		url: "/api/errors",
	});

	return res;
}

async function addError(error) {
	const res = await api({
		method: "post",
		url: "/api/errors",
		data: error,
	});

	return res;
}

async function deleteError(id) {
	const res = await api({
		method: "delete",
		url: `/api/errors/${id}`,
	});

	return res;
}

export { getErrors, addError, deleteError };
