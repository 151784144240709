<template>
	<div v-if="loading" style="display: flex; justify-content: center; flex-wrap: wrap; gap: 10px">
		<div v-for="statType in config.statTypes" :key="statType.key">
			<v-skeleton-loader
				style="text-align: left"
				type="list-item-avatar,list-item,divider,list-item,list-item,list-item,list-item,list-item"
				width="220px"
			/>
		</div>
	</div>
	<div v-else style="display: flex; justify-content: center; flex-wrap: wrap; gap: 10px">
		<div v-for="card in cards" :key="card._id">
			<v-card min-width="220px">
				<v-card-text style="padding: 0px 10px">
					<v-row>
						<v-col sm="9" class="display-3" align="left">
							{{ card.total }}
						</v-col>
						<v-col sm="3">
							<v-icon color="grey" style="top: 10px; left: -10px; font-size: 3em">{{ icons[card._id] }}</v-icon>
						</v-col>
					</v-row>
					<v-row style="margin: -5px -12px">
						<v-col align="left">
							{{ card.label }}
						</v-col>
					</v-row>
				</v-card-text>
				<v-divider />
				<v-list dense>
					<v-list-item align="left" v-for="goal in card.goals" :key="goal._id">
						<v-list-item-content>
							<v-list-item-title>
								{{ frequencies[goal.frequency] }}
							</v-list-item-title>
							<v-progress-linear v-if="goal.number" :value="goal.percentage" :color="chipColor(goal)" />
						</v-list-item-content>
						<v-list-item-action>
							<v-chip small style="width: 80px; justify-content: center" :color="chipColor(goal)">
								{{ goal.total }}{{ goal.number ? `/${goal.number}` : "" }}
							</v-chip>
						</v-list-item-action>
					</v-list-item>
				</v-list>
			</v-card>
		</div>
	</div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
dayjs.extend(weekOfYear);

import { getLeadTotals } from "../../api/stats";

export default Vue.extend({
	name: "GoalCard",
	created() {
		this.handleGetTotals();
	},
	watch: {
		selectedOffices: function () {
			this.handleGetTotals();
		},
		dateRange: function () {
			this.handleGetTotals();
		},
	},
	computed: mapState(["user", "selectedOffices", "dateRange", "config"]),
	data() {
		return {
			loading: false,

			cards: [],

			frequencies: {
				daily: "Hoje",
				weekly: "Esta Semana",
				monthly: "Este Mês",
				quarterly: "Este Trimestre",
				yearly: "Este Ano",
			},
			icons: {
				recruiterAssigned: "mdi-account-switch",
				recruiterAccepted: "mdi-check",
				contactsContacted: "mdi-phone",
				interviewScheduled: "mdi-calendar",
				interviewDone: "mdi-calendar-check",
				presentationScheduled: "mdi-calendar",
				presentationDone: "mdi-calendar-check",
				recruited: "mdi-star",
			},
		};
	},
	methods: {
		async handleGetTotals() {
			this.loading = true;

			const response = await getLeadTotals("periodically");

			this.cards = [];

			if (response.status === 200) {
				const totals = {};

				for (const goalFrequency of [...this.config.goalFrequencies, { key: "total", name: "Total" }]) {
					for (const statType of this.config.statTypes) {
						const goalNumber = response.data[goalFrequency.key]
							? response.data[goalFrequency.key][statType.key]
							: 0;

						if (totals[statType.key]) {
							totals[statType.key][goalFrequency.key] = goalNumber;
						} else {
							totals[statType.key] = { [goalFrequency.key]: goalNumber };
						}
					}
				}

				for (const statType in totals) {
					const goals = [];

					for (const goalFrequency of this.config.goalFrequencies) {
						const goal = this.user.goals.find(g => g.type === statType && g.frequency === goalFrequency.key);

						goals.push({
							_id: `${statType}-${goalFrequency.key}`,
							frequency: goalFrequency.key,
							number: goal ? goal.number : 0,
							total: totals[statType][goalFrequency.key],
							percentage: goal ? (totals[statType][goalFrequency.key] / goal.number) * 100 : 0,
						});
					}

					this.cards.push({
						_id: statType,
						label: this.config.statTypes.find(t => t.key === statType).name,
						total: totals[statType].total,
						goals,
					});
				}
			}

			this.loading = false;
		},
		chipColor(goal) {
			return !goal.total && !goal.number ? "dark-grey" : goal.total >= goal.number ? "success" : "error";
		},
	},
});
</script>
