import { api } from "../utils/request";

async function getViabilities(page, perPage, sortBy, sortDesc, filters) {
	const res = await api({
		method: "post",
		url: "/api/viabilities/search",
		data: { page, perPage, sortBy, sortDesc, filters },
	});

	return res;
}

async function addViability(viability) {
	const res = await api({
		method: "post",
		url: "/api/viabilities",
		data: viability,
	});

	return res;
}

async function editViability(viability) {
	const res = await api({
		method: "put",
		url: `/api/viabilities/${viability._id}`,
		data: viability,
	});

	return res;
}

async function patchViability(viability) {
	const res = await api({
		method: "patch",
		url: `/api/viabilities/${viability._id}`,
		data: viability,
	});

	return res;
}

async function deleteViability(id) {
	const res = await api({
		method: "delete",
		url: `/api/viabilities/${id}`,
	});

	return res;
}

async function editComment(comment) {
	const res = await api({
		method: "put",
		url: `/api/comments/${comment._id}`,
		data: comment,
	});

	return res;
}

async function deleteComment(id) {
	const res = await api({
		method: "delete",
		url: `/api/comments/${id}`,
	});

	return res;
}

export {
	getViabilities,
	addViability,
	editViability,
	patchViability,
	deleteViability,
	editComment,
	deleteComment,
};
