<template>
	<v-sheet style="overflow-x: hidden" outlined rounded>
		<v-form ref="form" @submit.prevent="handleAddFinding">
			<v-row justify="center" no-gutters>
				<v-col style="margin: 40px; min-width: 300px; max-width: 400px">
					<v-expansion-panels popout multiple :value="[0]">
						<v-expansion-panel style="border: 1px solid DarkGrey">
							<v-expansion-panel-header>Processo</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-row>
									<v-col>
										<v-text-field outlined v-model="url" label="Link C21" />
									</v-col>
									<v-col style="padding-top: 22px">
										<v-progress-circular v-if="loadingInfo" indeterminate color="primary" />
										<v-btn v-else color="primary" @click="handleGetFindingInfo">Preencher</v-btn>
									</v-col>
								</v-row>
								<v-text-field
									outlined
									v-model="ref"
									label="Referência"
									:rules="[required]"
									hint="Obrigatório"
									persistent-hint
								/>
								<v-menu :close-on-content-click="false" transition="scale-transition" offset-y>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											outlined
											v-model="findingDate"
											label="Data de Angariação"
											v-bind="attrs"
											v-on="on"
											:rules="[required]"
											readonly
										/>
									</template>
									<v-date-picker v-model="findingDate" color="primary" />
								</v-menu>
								<v-row v-if="company === 'realtyart'">
									<v-col>
										<v-text-field outlined v-model="number" label="Nº Contrato" />
									</v-col>
									<v-col>
										<v-autocomplete
											outlined
											v-model="contractType"
											:items="config.findingContractTypes"
											item-text="name"
											item-value="key"
											label="Tipo de Contrato"
										/>
									</v-col>
								</v-row>
								<v-text-field
									v-if="company === 'realtyart'"
									outlined
									v-model="contractDuration"
									label="Duração do Contrato"
									type="number"
									hint="meses"
									persistent-hint
								/>
								<v-row>
									<v-col v-if="company === 'realtyart'">
										<v-autocomplete
											outlined
											v-model="regime"
											:items="config.regimes"
											item-text="name"
											item-value="key"
											label="Regime"
										/>
									</v-col>
									<v-col>
										<v-autocomplete
											outlined
											v-model="state"
											:items="config.states"
											item-text="name"
											item-value="key"
											label="Estado"
										/>
									</v-col>
								</v-row>
								<v-autocomplete
									outlined
									v-model="finder"
									item-text="name"
									item-value="_id"
									label="Angariador"
									:items="consultants"
									:rules="[required]"
									hint="Obrigatório"
									persistent-hint
									return-object
								/>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-col>
				<v-divider vertical />
				<v-col style="margin: 40px; min-width: 300px; max-width: 400px">
					<v-expansion-panels popout multiple :value="[0, 1]">
						<v-expansion-panel style="border: 1px solid DarkGrey">
							<v-expansion-panel-header>Imóvel</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-text-field outlined v-model="owner" label="Proprietário" />
								<v-text-field
									outlined
									append-icon="mdi-currency-eur"
									v-model="houseValue"
									label="Valor de Venda"
									type="number"
									:rules="[required]"
									hint="Obrigatório"
									persistent-hint
									@input="() => (this.value = this.houseValue * (this.percentageSplit / 100))"
								/>
								<v-text-field
									outlined
									append-icon="mdi-percent"
									v-model="percentageSplit"
									label="Percentagem Interna"
									type="number"
									:rules="[required]"
									hint="Obrigatório"
									persistent-hint
									@input="() => (this.value = this.houseValue * (this.percentageSplit / 100))"
								/>
								<v-text-field
									outlined
									append-icon="mdi-currency-eur"
									v-model="value"
									label="Comissão da Agência"
									type="number"
									:rules="[required]"
									hint="Obrigatório"
									persistent-hint
									@input="() => (this.percentageSplit = ((this.value / this.houseValue) * 100).toFixed(2))"
								/>
								<v-text-field outlined v-model="address" label="Morada" />
								<v-row>
									<v-col>
										<v-text-field outlined v-model="postalCode" label="Código Postal" :rules="[validPostalCode]" />
									</v-col>
									<v-col>
										<v-text-field outlined v-model="city" label="Cidade" />
									</v-col>
								</v-row>
								<v-autocomplete
									outlined
									v-model="houseType"
									item-text="name"
									item-value="key"
									label="Tipo de Imóvel"
									:items="config.houseTypes"
									:rules="[required]"
									hint="Obrigatório"
									persistent-hint
								/>
								<v-row>
									<v-col>
										<v-text-field outlined append-icon="m²" v-model="area" label="Área Útil" type="number" />
									</v-col>
									<v-col>
										<v-text-field
											outlined
											prepend-inner-icon="T"
											v-model="rooms"
											label="Tipologia"
											type="number"
										/>
									</v-col>
								</v-row>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-col>
			</v-row>
			<v-btn v-if="!finding" @click="handleAddFinding">Adicionar</v-btn>
			<v-btn v-if="finding" @click="handleEditFinding">Atualizar</v-btn>
			<br /><br />
		</v-form>
	</v-sheet>
</template>

<script>
import Vue from "vue";
import dayjs from "dayjs";

import { mapState, mapMutations } from "vuex";

import { getUsers } from "../api/users";
import { getFindingInfo, addFinding, editFinding } from "../api/findings";

import { formatDate } from "../utils/utils";

export default Vue.extend({
	name: "FindingDetail",
	props: ["finding", "onClose"],
	created() {
		this.handleGetConsultants();

		if (this.finding) this.setFinding(this.finding);
	},
	watch: {
		finding: function (newVal) {
			this.setFinding(newVal);
		},
	},
	computed: mapState(["company", "config"]),
	data() {
		const postalCodeRegex = /^[0-9]{4}-[0-9]{3}$/;

		return {
			loading: false,
			loadingInfo: false,

			required: value => !!value || "Obrigatório",
			validPostalCode: value => !value || (value && postalCodeRegex.test(value)) || "Código postal inválido",

			consultants: [],
			states: [],

			url: "",

			ref: "",
			findingDate: formatDate(dayjs(), "YYYY-MM-DD"),
			number: "",
			contractType: "",
			contractDuration: "",
			regime: "",
			state: "",
			address: "",
			postalCode: "",
			city: "",
			houseType: "",
			area: "",
			rooms: "",
			owner: "",
			finder: "",
			houseValue: "",
			percentageSplit: "5",
			value: "",
		};
	},
	methods: {
		...mapMutations(["openToast"]),
		setFinding(newVal) {
			if (newVal) {
				this.url = newVal.url;
				this.ref = newVal.ref;
				this.findingDate = formatDate(newVal.findingDate, "YYYY-MM-DD");
				this.number = newVal.number;
				this.contractType = newVal.contractType;
				this.contractDuration = newVal.contractDuration;
				this.regime = newVal.regime;
				this.state = newVal.state;
				this.address = newVal.address;
				this.postalCode = newVal.postalCode;
				this.city = newVal.city;
				this.houseType = newVal.houseType;
				this.area = newVal.area;
				this.rooms = newVal.rooms;
				this.owner = newVal.owner;
				this.finder = newVal.finder;
				this.houseValue = newVal.houseValue;
				this.percentageSplit = newVal.percentageSplit ? newVal.percentageSplit * 100 : null;
				this.value = newVal.value;
			} else {
				this.ref = "";
				this.findingDate = formatDate(dayjs(), "YYYY-MM-DD");
				this.number = "";
				this.contractType = "";
				this.contractDuration = "";
				this.regime = "";
				this.state = "";
				this.address = "";
				this.postalCode = "";
				this.city = "";
				this.houseType = "";
				this.area = "";
				this.rooms = "";
				this.owner = "";
				this.finder = "";
				this.houseValue = "";
				this.percentageSplit = "5";
				this.value = "";
			}
		},
		isFormValid() {
			if (!this.$refs.form.validate()) return false;

			return true;
		},
		async handleGetConsultants() {
			const response = await getUsers(0, 10000, "name", false, {
				role: ["consultant", "teamLeader", "commercialDirector"],
				all: true,
				infoLevel: 2,
			});

			if (response.status === 200) {
				this.consultants = response.data.users;
			}
		},
		async handleGetFindingInfo() {
			this.loadingInfo = true;

			const { url } = this;

			if (url) {
				const response = await getFindingInfo(url);

				if (response.status === 200) {
					this.ref = response.data.ref;
					this.finder = this.consultants.find(c => c._id === response.data.finder);
					this.houseValue = response.data.houseValue;
					this.value = this.houseValue * (this.percentageSplit / 100);
					this.address = response.data.address;
					this.city = response.data.city;
					this.houseType = response.data.houseType;
					this.area = response.data.area;
					this.rooms = response.data.rooms;
				} else if (response.message === "OFFICE_MISSING") {
					this.openToast({ message: "Escolha uma agência", color: "red" });
				} else {
					this.openToast({ message: "URL inválido", color: "red" });
				}
			} else {
				this.openToast({ message: "Faltam campos obrigatórios", color: "red" });
			}

			this.loadingInfo = false;
		},
		async handleAddFinding() {
			this.loading = true;
			const {
				url,
				ref,
				findingDate,
				number,
				contractType,
				contractDuration,
				regime,
				state,
				address,
				postalCode,
				city,
				houseType,
				area,
				rooms,
				owner,
				finder,
				houseValue,
				percentageSplit,
				value,
			} = this;

			if (!this.isFormValid()) {
				this.openToast({ message: "Faltam campos obrigatórios", color: "red" });

				return;
			}

			const response = await addFinding({
				url,
				ref,
				findingDate,
				number,
				contractType,
				contractDuration,
				regime,
				state,
				address,
				postalCode,
				city,
				houseType,
				area,
				rooms,
				owner,
				finder: finder._id || finder,
				finderTeam: finder.team || null,
				finderCommercialDirector: finder.manager || null,
				houseValue,
				percentageSplit,
				value,
			});

			if (response.status === 201) {
				this.onClose();

				this.setFinding();
			} else if (response.status === 400) {
				this.openToast({ message: "Escolha uma agência", color: "red" });
			} else if (response.status === 409) {
				this.openToast({ message: "Essa referência já existe", color: "red" });
			}

			this.loading = false;
		},
		async handleEditFinding() {
			this.loading = true;
			const {
				url,
				ref,
				findingDate,
				number,
				contractType,
				contractDuration,
				regime,
				state,
				address,
				postalCode,
				city,
				houseType,
				area,
				rooms,
				owner,
				finder,
				houseValue,
				percentageSplit,
				value,
			} = this;

			if (!this.isFormValid()) {
				this.openToast({ message: "Faltam campos obrigatórios", color: "red" });

				return;
			}

			const response = await editFinding({
				_id: this.finding._id,
				url,
				ref,
				findingDate,
				number,
				contractType,
				contractDuration,
				regime,
				state,
				address,
				postalCode,
				city,
				houseType,
				area,
				rooms,
				owner,
				finder: finder._id || finder,
				finderTeam: finder.team || null,
				finderCommercialDirector: finder.manager || null,
				houseValue,
				percentageSplit,
				value,
			});

			if (response.status === 200) {
				this.onClose();
			} else if (response.status === 409) {
				this.openToast({ message: "Essa referência já existe", color: "red" });
			}

			this.loading = false;
		},
	},
});
</script>
