<template>
	<v-card class="widgetCard" width="100%">
		<h1 style="text-align: left">Notificações</h1>
		<v-card
			v-for="notification in notifications"
			:key="notification._id"
			style="background-color: #333; margin-bottom: 10px; padding: 10px; text-align: left"
		>
			<h4>{{ notification.title }}</h4>
			<div v-if="notification.description">{{ notification.description }}</div>
			<div style="margin-bottom: 10px">{{ formatDate(notification.dateToSend, "DD-MM-YYYY HH:mm") }}</div>
			<v-card-actions style="padding: 0px">
				<v-btn color="primary">
					<a
						:href="`/${notification.model}s/${notification.ref}`"
						target="_blank"
						style="text-decoration: none; color: inherit"
					>
						Link
					</a>
				</v-btn>
				<v-btn color="error" @click="() => handleDeleteNotification(notification)">Apagar</v-btn>
			</v-card-actions>
		</v-card>
	</v-card>
</template>

<script>
import Vue from "vue";

import { getNotifications, deleteNotification } from "../../api/notifications";

import { formatDate } from "../../utils/utils";

export default Vue.extend({
	name: "Notifications",
	created() {
		this.handleGetNotifications();
	},
	data() {
		return {
			notifications: [],
		};
	},
	methods: {
		formatDate,
		async handleGetNotifications() {
			const response = await getNotifications(0, 100, null, null, { active: true, channels: "web" });

			if (response.status === 200) {
				this.notifications = response.data.notifications;
			}
		},
		async handleDeleteNotification(item) {
			const response = await deleteNotification(item._id);

			if (response.status === 200) this.handleGetNotifications();
		},
	},
});
</script>
