<style>
.card {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
	padding: 10px;
}

.totalBlock {
	border: 1px solid #777;
	border-radius: 3px;
	padding: 10px;
	width: 215px;
}

.totalValues {
	text-align: left;
	margin-top: 10px;
}

.totalWithPercent {
	display: flex;
	gap: 10px;
	justify-content: space-between;
	margin-top: 10px;
}
</style>

<template>
	<v-skeleton-loader
		v-if="!pipeline || !totals"
		type="image"
		class="mx-auto"
		style="width: 100%; height: 125px; max-width: 1175px"
	/>
	<v-card v-else class="card mx-auto">
		<div class="totalBlock" style="border: 1px solid orange">
			Pipeline
			<div style="font-size: 0.75em; color: DarkGrey">(acumulado)</div>
			<div class="totalValues">
				{{ formatNumber(pipeline, true) || "0 €" }}
			</div>
		</div>
		<div class="totalBlock">
			Pipeline
			<div style="font-size: 0.75em; color: DarkGrey">(período selecionado)</div>
			<div class="totalValues">
				{{ formatNumber(totals.tipyValue, true) || "0 €" }}
			</div>
		</div>
		<div class="totalBlock">
			Por Faturar
			<div style="font-size: 0.75em; color: DarkGrey">(período selecionado)</div>
			<div class="totalValues">
				{{ formatNumber(totals.pipeline, true) || "0 €" }}
			</div>
		</div>
		<div class="totalBlock">
			Faturado
			<div style="font-size: 0.75em; color: DarkGrey">(período selecionado)</div>
			<div class="totalValues">
				{{ formatNumber(totals.executions, true) || "0 €" }}
			</div>
		</div>
		<div class="totalBlock">
			Desistências
			<div style="font-size: 0.75em; color: DarkGrey">(período selecionado)</div>
			<div class="totalValues">
				{{ formatNumber(totals.giveUps, true) || "0 €" }}
			</div>
		</div>
		<div class="totalBlock">
			Financiamento
			<div style="font-size: 0.75em; color: DarkGrey">(período selecionado)</div>
			<div class="totalValues">
				{{ formatNumber(totals.creditValue, true) || "0 €" }}
			</div>
		</div>
	</v-card>
</template>

<script>
import Vue from "vue";

import { formatNumber } from "../utils/utils";

export default Vue.extend({
	name: "ReservationTotals",
	props: ["pipeline", "totals"],
	methods: {
		formatNumber,
	},
});
</script>
