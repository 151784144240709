<template>
	<DataTable
		type="viabilities"
		:featuresMap="{
			add: features.addViability,
			edit: features.editViability,
			delete: features.deleteViability,
			addFiles: true,
		}"
		title="Viabilidades"
		:filtersConfig="filtersConfig"
		:getItems="getViabilities"
		:patchItem="patchViability"
		:deleteItem="deleteViability"
		:actions="['comments', 'files', 'changes', 'edit', 'delete']"
		:profileOverwrite="profileOverwrite"
		sortByOverwrite="date"
	>
		<template v-slot:detail="{ selectedItem, closeDetail }">
			<ViabilityDetail :viability="selectedItem" :onClose="closeDetail" />
		</template>
	</DataTable>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";

import DataTable from "../components/DataTable.vue";
import ViabilityDetail from "../components/ViabilityDetail";

import { getViabilities, patchViability, deleteViability } from "../api/viabilities";

export default Vue.extend({
	name: "Viabilities",
	props: ["profileOverwrite"],
	components: { DataTable, ViabilityDetail },
	computed: mapState(["features"]),
	data() {
		return {
			filtersConfig: [
				{ type: "autocomplete", label: "Consultores", value: "consultants", condition: !this.profileOverwrite },
				{
					type: "autocomplete",
					label: "Equipas",
					value: "teams",
					items: "config.teams",
					itemValue: "key",
					condition: !this.profileOverwrite,
				},
				{
					type: "autocomplete",
					label: "Diretores Comerciais",
					value: "commercialDirectors",
					condition: !this.profileOverwrite,
				},
				{ type: "autocomplete", label: "Gestoras de Crédito", value: "creditManager", items: "creditManagers" },
				{ type: "divider" },
				{
					type: "autocomplete",
					label: "Estado",
					value: "state",
					items: "config.viabilityStates",
					itemValue: "key",
				},
				{
					type: "buttonToggle",
					value: "isGiveUp",
					options: [
						{ value: false, label: "Sem Desistências" },
						{ value: "null", label: "Tudo" },
						{ value: true, label: "Desistências" },
					],
				},
			],
		};
	},
	methods: { getViabilities, patchViability, deleteViability },
});
</script>
