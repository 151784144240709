<template>
	<v-card style="padding: 20px">
		<div v-if="files.length">
			<v-list outlined style="border-radius: 4px">
				<v-subheader style="justify-content: space-between">
					Ficheiros
					<div>
						<v-btn v-if="false && user.gmailRefreshToken" icon @click="handleGenerateGmailDraft">
							<v-icon>mdi-email</v-icon>
						</v-btn>
						<v-btn
							v-else-if="false"
							icon
							:href="`https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${redirect}/gmail&prompt=consent&access_type=offline&response_type=code&client_id=557887385987-ls6a5j9ep453c0qi02r8ddiumlhkb4m0.apps.googleusercontent.com&scope=https://www.googleapis.com/auth/gmail.compose https://www.googleapis.com/auth/gmail.readonly`"
						>
							<v-icon>mdi-gmail</v-icon>
						</v-btn>
						<v-btn icon @click="archive = !archive" style="margin-right: 20px">
							<v-icon>{{ archive ? "mdi-file" : "mdi-archive" }}</v-icon>
						</v-btn>
					</div>
				</v-subheader>
				<v-list-item-group multiple color="primary">
					<v-expansion-panels multiple style="padding: 0px 15px 0px 15px">
						<v-expansion-panel
							v-for="folder in getFolders(this.files.filter(f => f.active !== archive && f.folder))"
							:key="folder"
							:style="`background-color: ${$vuetify.theme.dark ? '#333' : '#f2f2f2'}; padding: 0px`"
						>
							<v-expansion-panel-header>
								{{ folder }}
							</v-expansion-panel-header>
							<v-expansion-panel-content style="margin: 0px -20px; padding: 0px">
								<v-list-item
									inactive
									style="text-align: left"
									v-for="file in files.filter(f => f.active !== archive && f.folder === folder)"
									:key="file._id"
								>
									<v-list-item-action v-if="false">
										<v-checkbox v-model="file.selected" />
									</v-list-item-action>
									<v-list-item-content>
										<v-list-item-title>
											<v-tooltip :disabled="!file._created" top>
												<template v-slot:activator="{ on, attrs }">
													<a v-bind="attrs" v-on="on" :href="getFileUrl(file.link)" target="_blank">
														{{ getFileName(file.name) }}
													</a>
												</template>
												<span>
													Criado: {{ formatDate(file._created, "DD-MM-YY HH:mm") }}
													<br />
													Modificado: {{ formatDate(file._modified, "DD-MM-YY HH:mm") }}
													<br />
													Por: {{ file.user?.name }}
												</span>
											</v-tooltip>
										</v-list-item-title>
									</v-list-item-content>
									<div v-if="archive">
										<v-list-item-action @click.stop="() => deleteFile(file)" style="margin: 5px; cursor: pointer">
											<v-icon>mdi-restore</v-icon>
										</v-list-item-action>
									</div>
									<div v-else>
										<v-list-item-action @click.stop="() => editFile(file)" style="margin: 5px; cursor: pointer">
											<v-icon>mdi-pencil</v-icon>
										</v-list-item-action>
										<DeletionDialog :deletionText="getFileName(file.name)" :onConfirm="() => deleteFile(file)">
											<template v-slot:activator="{ onClick }">
												<v-list-item-action @click.stop="onClick" style="margin: 5px; cursor: pointer">
													<v-icon>mdi-delete</v-icon>
												</v-list-item-action>
											</template>
										</DeletionDialog>
									</div>
								</v-list-item>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
					<v-list-item
						inactive
						style="text-align: left; margin: 0px 20px"
						v-for="file in files.filter(f => f.active !== archive && !f.folder)"
						:key="file._id"
					>
						<v-list-item-action v-if="false">
							<v-checkbox v-model="file.selected" />
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title>
								<v-tooltip :disabled="!file._created" top>
									<template v-slot:activator="{ on, attrs }">
										<a v-bind="attrs" v-on="on" :href="getFileUrl(file.link)" target="_blank">
											{{ getFileName(file.name) }}
										</a>
									</template>
									<span>
										Criado: {{ formatDate(file._created, "DD-MM-YY HH:mm") }}
										<br />
										Modificado: {{ formatDate(file._modified, "DD-MM-YY HH:mm") }}
										<br />
										Por: {{ file.user?.name }}
									</span>
								</v-tooltip>
							</v-list-item-title>
						</v-list-item-content>
						<div v-if="archive">
							<v-list-item-action @click.stop="() => deleteFile(file)" style="margin: 5px; cursor: pointer">
								<v-icon>mdi-restore</v-icon>
							</v-list-item-action>
						</div>
						<div v-else>
							<v-list-item-action @click.stop="() => editFile(file)" style="margin: 5px; cursor: pointer">
								<v-icon>mdi-pencil</v-icon>
							</v-list-item-action>
							<DeletionDialog :deletionText="getFileName(file.name)" :onConfirm="() => deleteFile(file)">
								<template v-slot:activator="{ onClick }">
									<v-list-item-action @click.stop="onClick" style="margin: 5px; cursor: pointer">
										<v-icon>mdi-delete</v-icon>
									</v-list-item-action>
								</template>
							</DeletionDialog>
						</div>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</div>
		<div v-else>Não há ficheiros</div>
		<br />
		<span v-for="file in filesToUploadInfo" :key="file.id">
			<v-row>
				<v-col style="margin-bottom: 10px">
					<v-text-field outlined dense v-model="file.name" label="Nome" hide-details />
				</v-col>
				<v-col>
					<v-checkbox v-model="file.isInvoice" label="Fatura" style="margin-top: 2px" />
				</v-col>
				<v-col>
					<v-combobox
						outlined
						dense
						v-model="file.folder"
						label="Pasta"
						:items="getFolders(files.filter(f => f.active && f.folder))"
						hide-details
					/>
				</v-col>
			</v-row>
		</span>
		<VueFileAgent
			v-if="filesToUpload.length === filesToUploadInfo.length"
			multiple
			theme="list"
			deletable
			@select="addFileToUpload"
			@beforedelete="deleteFileToUpload($event)"
			helpText="Escolha ficheiros ou faça drag & drop"
			v-model="filesToUpload"
		/>
		<br />
		<v-btn v-if="id && !hideSaveButton" color="primary" @click="handleFileUpload">Guardar</v-btn>
	</v-card>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";

import { addFiles } from "../api/files";
import { generateFilesDraft } from "../api/gmail";

import { formatDate, getFileUrl, getFileName } from "../utils/utils";

import DeletionDialog from "../components/DeletionDialog.vue";

export default Vue.extend({
	name: "Files",
	components: { DeletionDialog },
	props: ["files", "group", "id", "hideSaveButton", "patchFunction", "onSubmit"],
	watch: {
		group: function () {
			this.file = null;
			this.filesToUpload = [];
			this.filesToUploadInfo = [];
		},
		id: function () {
			this.file = null;
			this.filesToUpload = [];
			this.filesToUploadInfo = [];
		},
	},
	computed: {
		...mapState(["user", "company", "token", "redirect"]),
		apiUrl() {
			return process.env.VUE_APP_API_URL;
		},
	},
	data() {
		return {
			file: null,
			filesToUpload: [],
			filesToUploadInfo: [],
			archive: false,
		};
	},
	methods: {
		...mapMutations(["openToast", "login"]),
		formatDate,
		getFileUrl,
		getFileName,
		getFolders(files) {
			const folders = new Set();

			for (const file of files) {
				if (file.folder) folders.add(file.folder);
			}

			return Array.from(folders);
		},
		async editFile(file) {
			if (this.filesToUpload.length) {
				this.filesToUpload = [];
				this.filesToUploadInfo = [];
			}

			const fileFound = this.files.find(f => f._id === file._id);
			fileFound._modified = Date.now();

			this.filesToUploadInfo.push(fileFound);
		},
		async deleteFile(file) {
			const fileFound = this.files.find(f => f._id === file._id);

			fileFound.active = this.archive;

			const files = this.files;

			const response = await this.patchFunction({ _id: this.id, files });

			if (response.status === 200) {
				this.openToast({ message: "Ficheiro apagado com sucesso", color: "green" });

				await this.onSubmit();
			}
		},
		addFileToUpload(files) {
			if (this.filesToUpload.length === 1 && this.filesToUploadInfo.length) this.filesToUploadInfo = [];

			for (const file of files) {
				this.filesToUploadInfo.push({ id: file.file.name, name: file.file.name, folder: "" });
			}
		},
		deleteFileToUpload(file) {
			this.filesToUpload = this.filesToUpload.filter(f => f !== file);
			this.filesToUploadInfo = this.filesToUploadInfo.filter(f => f.id !== file.file.name);
		},
		async handleFileUpload(id) {
			if (this.filesToUpload.length) {
				const formData = new FormData();
				const fileTypes = [];
				const fileNames = [];
				const fileIsInvoice = [];
				for (const file of this.filesToUpload) {
					formData.append("files", file.file);
					fileTypes.push(file.ext);
				}

				for (const file of this.filesToUploadInfo) {
					fileIsInvoice.push(file.isInvoice);
					fileNames.push(`${file.folder}/${file.name}`);
				}

				formData.append("fileTypes", fileTypes);
				formData.append("fileNames", fileNames);
				formData.append("fileIsInvoice", fileIsInvoice);

				const response = await addFiles(this.group, this.id || id, this.group, formData);

				if (response.status === 200) {
					if (!this.hideSaveButton) {
						this.openToast({ message: "Ficheiros adicionados com sucesso", color: "green" });
					}

					this.file = null;
					this.filesToUpload = [];
					this.filesToUploadInfo = [];

					if (!this.hideSaveButton) await this.onSubmit();
				}
			} else if (this.filesToUploadInfo.length) {
				const response = await this.patchFunction({ _id: this.id || id, files: this.files });

				if (response.status === 200) {
					if (!this.hideSaveButton) {
						this.openToast({ message: "Ficheiros modificados com sucesso", color: "green" });
					}

					this.filesToUploadInfo = [];

					if (!this.hideSaveButton) await this.onSubmit();
				}
			}
		},
		async handleGenerateGmailDraft() {
			const files = this.files.filter(f => f.selected);

			if (!files.length) return this.openToast({ message: "Selecione pelo menos 1 ficheiro", color: "red" });

			const response = await generateFilesDraft(files);

			if (response.status === 200) {
				this.openToast({ message: "Rascunho gerado com sucesso", color: "green" });
			} else if (response.status === 401) {
				this.login({ user: { ...this.user, gmailRefreshToken: false } });
			}
		},
	},
});
</script>
