<style scoped>
.container {
	height: 100%;
	display: flex;
	gap: 20px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
</style>

<template>
	<div class="container">
		<h1>{{ forgotPassword ? "Esqueci-me da password" : "Login" }}</h1>
		<div v-if="forgotPassword && !token" align="left">
			Introduza o seu e-mail.<br />
			Será enviado um e-mail com um link para alterar a password.<br />
			Clique no link criar uma nova password.
		</div>
		<v-form v-if="token" ref="form" @submit.prevent="handleChangePassword">
			<v-text-field
				outlined
				v-model="newPassword"
				label="Password Nova"
				:rules="[newPasswordRequired, samePassword]"
				:append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
				:type="showNewPassword ? 'text' : 'password'"
				@click:append="showNewPassword = !showNewPassword"
			/>
			<v-text-field
				outlined
				v-model="newPasswordRepeat"
				label="Repetir Password Nova"
				:rules="[samePassword]"
				:append-icon="showNewPasswordRepeat ? 'mdi-eye' : 'mdi-eye-off'"
				:type="showNewPasswordRepeat ? 'text' : 'password'"
				@click:append="showNewPasswordRepeat = !showNewPasswordRepeat"
			/>
			<v-btn type="submit" color="primary">Alterar</v-btn>
		</v-form>
		<v-form v-else ref="form" @submit.prevent="handleSubmit">
			<v-text-field outlined v-model="email" label="E-mail" :rules="[required]" />
			<v-text-field
				v-if="!forgotPassword"
				outlined
				v-model="password"
				label="Password"
				:rules="[required]"
				:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
				:type="showPassword ? 'text' : 'password'"
				@click:append="showPassword = !showPassword"
			/>
			<v-btn type="submit" color="primary">{{ forgotPassword ? "Enviar e-mail" : "Login" }}</v-btn>
			<br /><br />
			<v-btn v-if="!forgotPassword" text to="/forgot-password">Esqueci-me da password</v-btn>
		</v-form>
	</div>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";

import { login, forgotPassword, changePassword } from "../api/auth";

export default Vue.extend({
	name: "Login",
	async created() {
		this.forgotPassword = this.$route.fullPath.includes("forgot-password");
		this.token = this.$route.query.token;
	},
	watch: {
		$route: function (to) {
			this.forgotPassword = to.fullPath.includes("forgot-password");
		},
	},
	computed: mapState(["user"]),
	data() {
		return {
			required: value => !!value || "Obrigatório",

			email: "",
			password: "",
			showPassword: false,

			forgotPassword: false,
			token: null,

			newPassword: "",
			newPasswordRepeat: "",

			showNewPassword: false,
			showNewPasswordRepeat: false,
			newPasswordRequired: () => this.newPassword !== "" || "Obrigatório",
			samePassword: () => this.newPassword === this.newPasswordRepeat || "Passwords têm de ser iguais",
		};
	},
	methods: {
		...mapMutations(["openToast", "login"]),
		isFormValid() {
			if (!this.$refs.form.validate()) {
				this.openToast({ message: "Faltam campos obrigatórios", color: "red" });

				return false;
			}

			return true;
		},
		handleSubmit() {
			return this.forgotPassword ? this.handleForgotPassword() : this.handleLogin();
		},
		async handleLogin() {
			if (!this.isFormValid()) return;

			const response = await login({ email: this.email, password: this.password });

			if (response.status === 200) {
				this.login({
					user: response.data.user,
					token: response.data.token,
					company: response.data.company,
					needsPasswordChange: response.data.needsPasswordChange,
				});
			} else {
				this.openToast({ message: "Credenciais Inválidas", color: "red" });
			}
		},
		async handleForgotPassword() {
			if (!this.isFormValid()) return;

			const response = await forgotPassword({ email: this.email });

			if (response.status === 200) {
				this.openToast({ message: "E-mail enviado", color: "green" });
			} else {
				this.openToast({ message: "Credenciais Inválidas", color: "red" });
			}
		},
		async handleChangePassword() {
			if (!this.isFormValid()) return;

			const response = await changePassword(
				{
					token: this.token,
					newPassword: this.newPassword,
				},
				true,
			);

			if (response.status === 200) {
				this.openToast({ message: "Password mudada com sucesso", color: "green" });

				this.token = null;

				this.$router.push("/");
			} else {
				this.openToast({ message: "Credenciais Inválidas", color: "red" });
			}
		},
	},
});
</script>
