<style scoped>
.centerContainer {
	display: flex;
	justify-content: center;
	gap: 10px;
	margin-top: 5px;
	margin-bottom: 15px;
}
</style>

<template>
	<v-sheet outlined rounded>
		<v-stepper v-model="step">
			<v-stepper-header>
				<v-stepper-step :complete="step > 1" step="1">Recrutador</v-stepper-step>
				<v-divider />
				<v-stepper-step :complete="step > 2" step="2">1º Contacto</v-stepper-step>
				<v-divider />
				<v-stepper-step :complete="step > 3" step="3">
					{{ lead.experient ? "Reunião" : "Entrevista" }}
				</v-stepper-step>
				<v-divider v-if="company !== 'realtyart'" />
				<v-stepper-step v-if="company !== 'realtyart'" :complete="step > 4" step="4">
					Apresentação do Projeto
				</v-stepper-step>
				<v-divider />
				<v-stepper-step :complete="step > 5" step="5">Aprovação</v-stepper-step>
				<v-divider />
				<v-stepper-step :complete="step > 6" step="6">Recrutado</v-stepper-step>
			</v-stepper-header>
			<v-stepper-items>
				<v-stepper-content step="1">
					<v-form ref="form1">
						<v-btn-toggle v-model="recruiterAccepted">
							<v-btn :value="true">
								<span>Aceite</span>
								<v-icon right> mdi-check </v-icon>
							</v-btn>
							<v-btn :value="false">
								<span>Recusada</span>
								<v-icon right> mdi-close </v-icon>
							</v-btn>
						</v-btn-toggle>
						<br /><br />
						<v-textarea
							v-if="recruiterAccepted === false"
							outlined
							v-model="recruiterAcceptedObservations"
							label="Observações"
							:rules="[requiredRecruiterObservations]"
						/>
						<div class="centerContainer">
							<v-menu
								v-model="showAcceptedDatePicker"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										outlined
										v-model="date"
										label="Data de Registo"
										v-bind="attrs"
										v-on="on"
										style="max-width: 150px"
										readonly
										hide-details
									/>
								</template>
								<v-date-picker v-model="date" color="primary" @input="showAcceptedDatePicker = false" />
							</v-menu>
							<v-menu
								v-model="showAcceptedTimePicker"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										outlined
										v-model="time"
										label="Hora de Registo"
										v-bind="attrs"
										v-on="on"
										style="max-width: 150px"
										readonly
										hide-details
									/>
								</template>
								<v-time-picker
									v-model="time"
									color="primary"
									format="24hr"
									@input="showAcceptedTimePicker = false"
								/>
							</v-menu>
							<v-autocomplete
								outlined
								v-model="recruiter"
								:items="recruiters"
								item-text="name"
								item-value="_id"
								label="Recrutador"
								style="max-width: 200px"
								hide-details
							/>
						</div>
					</v-form>
				</v-stepper-content>
				<v-stepper-content step="2">
					<v-form ref="form2">
						<div class="centerContainer" style="flex-wrap: wrap">
							<v-card v-for="(contact, index) in contacts" :key="contact._id" min-width="150px" max-width="150px">
								<v-avatar :color="contact.accepted && !contact.declinedBy ? 'green' : 'red'">
									<v-icon>{{ getContactIcon(contact) }}</v-icon>
								</v-avatar>
								<v-badge color="primary" :content="index + 1" style="top: 20px; left: 8px; position: absolute" />
								<DeletionDialog
									v-if="newContacts.length && newContacts[newContacts.length - 1].date === contact.date"
									deletionText="este contacto"
									:onConfirm="() => handleDeleteContact(contact)"
								>
									<template v-slot:activator="{ onClick }">
										<span @click="onClick">
											<v-badge
												color="red"
												icon="mdi-close"
												style="top: 20px; right: 20px; position: absolute; cursor: pointer"
											/>
										</span>
									</template>
								</DeletionDialog>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title>
											{{ contact.accepted ? "Contactado" : "Não Contactado" }}
										</v-list-item-title>
										<v-list-item-subtitle>
											{{ formatDate(contact.date, "DD-MM-YYYY HH:mm") }}
										</v-list-item-subtitle>
										<v-list-item-subtitle :title="contact.observations" style="white-space: normal">
											{{ contact.observations }}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
							</v-card>
							<v-card
								v-if="!contacts.find(i => i.accepted === true && !i.declinedBy)"
								min-width="150px"
								max-width="150px"
							>
								<v-container fill-height style="justify-content: center">
									<v-btn fab color="primary" @click="setSideForm('contact')">
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</v-container>
							</v-card>
						</div>
					</v-form>
				</v-stepper-content>
				<v-stepper-content step="3">
					<v-form ref="form3">
						<div class="centerContainer" style="flex-wrap: wrap">
							<v-card v-for="interview in interviews" :key="interview._id" min-width="150px" max-width="150px">
								<v-avatar
									:color="
										interview.subType === 'scheduled'
											? 'info'
											: interview.subType === 'missed'
											? 'error'
											: 'success'
									"
								>
									<v-icon>
										{{
											interview.subType === "scheduled"
												? "mdi-calendar-check"
												: interview.subType === "missed"
												? "mdi-calendar-remove"
												: "mdi-account-multiple"
										}}
									</v-icon>
								</v-avatar>
								<DeletionDialog
									v-if="newInterviews.length && newInterviews[newInterviews.length - 1].date === interview.date"
									deletionText="esta entrevista"
									:onConfirm="() => handleDeleteInterview(interview)"
								>
									<template v-slot:activator="{ onClick }">
										<span @click="onClick">
											<v-badge
												color="red"
												icon="mdi-close"
												style="top: 20px; right: 20px; position: absolute; cursor: pointer"
											/>
										</span>
									</template>
								</DeletionDialog>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title style="white-space: normal">
											{{
												interview.subType === "scheduled"
													? `Marcada para ${formatDate(interview.scheduledDate, "DD-MM-YYYY HH:mm")}`
													: interview.subType === "missed"
													? "Não Aconteceu"
													: "Feita"
											}}
										</v-list-item-title>
										<v-list-item-subtitle>
											{{ formatDate(interview.date, "DD-MM-YYYY HH:mm") }}
										</v-list-item-subtitle>
										<v-list-item-subtitle v-if="interview.declinedBy">
											{{ interview.declinedBy === "lead" ? "Candidato" : "Recrutador" }}
										</v-list-item-subtitle>
										<v-list-item-subtitle :title="interview.observations" style="white-space: normal">
											{{ interview.observations }}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
							</v-card>
							<v-card min-width="150px" max-width="150px">
								<v-container fill-height style="justify-content: center">
									<v-btn fab color="primary" @click="setSideForm('interview')">
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</v-container>
							</v-card>
						</div>
					</v-form>
				</v-stepper-content>
				<v-stepper-content step="4">
					<v-form ref="form4">
						<div class="centerContainer" style="flex-wrap: wrap">
							<v-card
								v-for="presentation in presentations"
								:key="presentation._id"
								min-width="150px"
								max-width="150px"
							>
								<v-avatar
									:color="
										presentation.subType === 'scheduled'
											? 'info'
											: presentation.subType === 'missed'
											? 'error'
											: 'success'
									"
								>
									<v-icon>
										{{
											presentation.subType === "scheduled"
												? "mdi-calendar-check"
												: presentation.subType === "missed"
												? "mdi-calendar-remove"
												: "mdi-account-multiple"
										}}
									</v-icon>
								</v-avatar>
								<DeletionDialog
									v-if="newInterviews.length && newInterviews[newInterviews.length - 1].date === presentation.date"
									deletionText="esta entrevista"
									:onConfirm="() => handleDeleteInterview(presentation)"
								>
									<template v-slot:activator="{ onClick }">
										<span @click="onClick">
											<v-badge
												color="red"
												icon="mdi-close"
												style="top: 20px; right: 20px; position: absolute; cursor: pointer"
											/>
										</span>
									</template>
								</DeletionDialog>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title style="white-space: normal">
											{{
												presentation.subType === "scheduled"
													? `Marcada para ${formatDate(presentation.scheduledDate, "DD-MM-YYYY HH:mm")}`
													: presentation.subType === "missed"
													? "Não Aconteceu"
													: "Feita"
											}}
										</v-list-item-title>
										<v-list-item-subtitle>
											{{ formatDate(presentation.date, "DD-MM-YYYY HH:mm") }}
										</v-list-item-subtitle>
										<v-list-item-subtitle v-if="presentation.declinedBy">
											{{ presentation.declinedBy === "lead" ? "Candidato" : "Recrutador" }}
										</v-list-item-subtitle>
										<v-list-item-subtitle :title="presentation.observations" style="white-space: normal">
											{{ presentation.observations }}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
							</v-card>
							<v-card v-if="!presentations.find(i => i.subType === 'done')" min-width="150px" max-width="150px">
								<v-container fill-height style="justify-content: center">
									<v-btn fab color="primary" @click="setSideForm('presentation')">
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</v-container>
							</v-card>
						</div>
					</v-form>
				</v-stepper-content>
				<v-stepper-content step="5">
					<v-form ref="form5">
						<div class="centerContainer">
							<v-autocomplete
								outlined
								v-model="commercialDirector"
								:items="commercialDirectors"
								item-text="name"
								item-value="_id"
								:label="company === 'realtyart' ? 'Responsável de Integração' : 'Diretor Comercial'"
								:loading="loading"
								hide-selected
								:disabled="!!lead.commercialDirector"
								:rules="[required]"
								hint="Obrigatório"
								persistent-hint
								style="max-width: 300px"
							/>
						</div>
						<div class="centerContainer" style="flex-wrap: wrap">
							<v-card v-for="approval in approvals" :key="approval._id" min-width="150px" max-width="150px">
								<v-avatar
									:color="
										approval.subType === 'scheduled' ? 'info' : approval.subType === 'missed' ? 'error' : 'success'
									"
								>
									<v-icon>
										{{
											approval.subType === "scheduled"
												? "mdi-calendar-check"
												: approval.subType === "missed"
												? "mdi-calendar-remove"
												: "mdi-account-multiple"
										}}
									</v-icon>
								</v-avatar>
								<DeletionDialog
									v-if="newInterviews.length && newInterviews[newInterviews.length - 1].date === approval.date"
									deletionText="esta entrevista"
									:onConfirm="() => handleDeleteInterview(approval)"
								>
									<template v-slot:activator="{ onClick }">
										<span @click="onClick">
											<v-badge
												color="red"
												icon="mdi-close"
												style="top: 20px; right: 20px; position: absolute; cursor: pointer"
											/>
										</span>
									</template>
								</DeletionDialog>
								<v-list-item>
									<v-list-item-content>
										<v-list-item-title style="white-space: normal">
											{{
												approval.subType === "scheduled"
													? `Marcada para ${formatDate(approval.scheduledDate, "DD-MM-YYYY HH:mm")}`
													: approval.subType === "missed"
													? "Não Aconteceu"
													: "Feita"
											}}
										</v-list-item-title>
										<v-list-item-subtitle>
											{{ formatDate(approval.date, "DD-MM-YYYY HH:mm") }}
										</v-list-item-subtitle>
										<v-list-item-subtitle v-if="approval.declinedBy">
											{{ approval.declinedBy === "lead" ? "Candidato" : "Recrutador" }}
										</v-list-item-subtitle>
										<v-list-item-subtitle :title="approval.observations" style="white-space: normal">
											{{ approval.observations }}
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
							</v-card>
							<v-card v-if="!approvals.find(i => i.subType === 'done')" min-width="150px" max-width="150px">
								<v-container fill-height style="justify-content: center">
									<v-btn fab color="primary" @click="setSideForm('approval')">
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</v-container>
							</v-card>
						</div>
					</v-form>
				</v-stepper-content>
				<v-stepper-content step="6">
					<v-form ref="form6">
						<div class="centerContainer">
							<div>
								<h4>Ficha de Consultor</h4>
								<br />
								<v-autocomplete
									outlined
									clearable
									v-model="consultant"
									item-text="name"
									item-value="_id"
									label="Staff Existente"
									:items="consultants"
								/>
								<v-btn v-if="consultant" color="primary" :disabled="loading" @click="handlePatchUser">
									Guardar
								</v-btn>
								<StaffForm
									v-if="step === 6 && !consultant"
									:staff="
										lead.userDraft
											? { ...lead, ...lead.userDraft, role: 'consultant' }
											: { ...lead, role: 'consultant', manager: lead.commercialDirector, fullTime: true }
									"
									:isLead="true"
									:onClose="handleAddEvent"
								/>
							</div>
						</div>
					</v-form>
					<div class="centerContainer">
						<v-menu
							v-model="showRecruitedDatePicker"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									outlined
									v-model="date"
									label="Data de Registo"
									v-bind="attrs"
									v-on="on"
									style="max-width: 150px"
									readonly
								/>
							</template>
							<v-date-picker v-model="date" color="primary" @input="showRecruitedDatePicker = false" />
						</v-menu>
						<v-menu
							v-model="showRecruitedTimePicker"
							:close-on-content-click="false"
							transition="scale-transition"
							offset-y
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									outlined
									v-model="time"
									label="Hora de Registo"
									v-bind="attrs"
									v-on="on"
									style="max-width: 150px"
								/>
							</template>
							<v-time-picker
								v-model="time"
								color="primary"
								format="24hr"
								@input="showRecruitedTimePicker = false"
							/>
						</v-menu>
						<v-autocomplete
							outlined
							v-model="recruiter"
							:items="recruiters"
							item-text="name"
							item-value="_id"
							label="Recrutador"
							style="max-width: 200px"
						/>
					</div>
				</v-stepper-content>
			</v-stepper-items>
		</v-stepper>
		<span v-if="step !== 6">
			<v-btn
				v-if="step === (company === 'realtyart' ? 5 : 4) && !newInterviews.length"
				color="primary"
				@click="company === 'realtyart' && step === 5 ? (step = 3) : step--"
				style="margin: 10px 10px"
			>
				<v-icon>mdi-chevron-left</v-icon>
			</v-btn>
			<v-btn :disabled="loading" color="primary" @click="handleAddEvent">Guardar</v-btn>
			<v-btn
				v-if="!newContacts.length && !newInterviews.length"
				color="primary"
				@click="company === 'realtyart' && step === 3 ? (step = 5) : step++"
				style="margin: 10px 10px"
			>
				<v-icon>mdi-chevron-right</v-icon>
			</v-btn>
		</span>
		<v-dialog v-model="openSideForm" width="500px">
			<LeadTimelineSideForm
				:lead="lead"
				:formType="sideFormType"
				:handleAddContact="handleAddContact"
				:handleAddInterview="handleAddInterview"
				:handleEditInterview="handleEditInterview"
			/>
		</v-dialog>
	</v-sheet>
</template>

<script>
import Vue from "vue";
import { mapState, mapMutations } from "vuex";
import dayjs from "dayjs";

import LeadTimelineSideForm from "./LeadTimelineSideForm";
import StaffForm from "./StaffForm";
import DeletionDialog from "../components/DeletionDialog.vue";

import { editLead, addEvents } from "../api/leads";
import { getUsers, patchUser } from "../api/users";

import { formatDate } from "../utils/utils";

export default Vue.extend({
	name: "LeadTimelineForm",
	props: ["lead", "overrideStep", "onClose"],
	components: {
		StaffForm,
		LeadTimelineSideForm,
		DeletionDialog,
	},
	created() {
		if (this.lead) {
			this.populateLead(this.lead);

			this.handleGetUsers();
		}
	},
	watch: {
		lead: function (newVal) {
			if (newVal) {
				this.populateLead(newVal);
			}
		},
		overrideStep: function () {
			this.calculateStep();
		},
	},
	computed: mapState(["company"]),
	data() {
		return {
			loading: false,

			required: value => !!value || "Obrigatório",
			requiredRecruiterObservations: value => (this.recruiterAccepted === false && !!value) || "Obrigatório",
			requiredContactObservations: value =>
				(this.contactContacted === false && !!value) || this.contactContacted || "Obrigatório",
			requiredApprovalDirector: value => (this.approvalCommercialDirector === false && !!value) || "Obrigatório",

			openSideForm: false,
			sideFormType: "contact",

			step: 1,
			maxStep: 1,

			newContacts: [],
			newInterviews: [],

			showAcceptedDatePicker: false,
			showAcceptedTimePicker: false,
			showRecruitedDatePicker: false,
			showRecruitedTimePicker: false,
			date: dayjs().format("YYYY-MM-DD"),
			time: dayjs().format("HH:mm"),
			recruiters: [],
			recruiter: null,
			recruiterAccepted: null,
			recruiterAcceptedObservations: "",
			contacts: [],
			interviews: [],
			commercialDirectors: [],
			commercialDirector: null,

			consultant: null,
			consultants: [],
		};
	},
	methods: {
		...mapMutations(["openToast"]),
		formatDate,
		populateLead(newVal) {
			const recruiterAcceptanceEvent = newVal.events.find(
				e => e.type === "recruiter" && e.subType === "acceptance",
			);
			this.recruiterAccepted = recruiterAcceptanceEvent && recruiterAcceptanceEvent.accepted;
			this.recruiterAcceptedObservations = recruiterAcceptanceEvent && recruiterAcceptanceEvent.observations;

			this.contacts = newVal.events.filter(e => e.type === "contact");
			this.interviews = newVal.events.filter(e => e.type === "interview");
			this.presentations = newVal.events.filter(e => e.type === "presentation");
			this.approvals = newVal.events.filter(e => e.type === "approval");

			const directorMeetingEvent = newVal.events.find(e => e.type === "approval" && e.subType === "scheduled");
			this.commercialDirectorMeetingDate = directorMeetingEvent
				? formatDate(directorMeetingEvent.scheduledDate, "YYYY-MM-DD")
				: null;
			this.commercialDirectorMeetingTime = directorMeetingEvent
				? formatDate(directorMeetingEvent.scheduledDate, "HH:mm")
				: null;
			this.commercialDirector = newVal.commercialDirector;
			const approvalDirectorEvent = newVal.events.find(
				e => e.type === "approval" && e.subType === "commercialDirector",
			);
			this.approvalCommercialDirector = approvalDirectorEvent && approvalDirectorEvent.accepted;
			this.approvalCommercialDirectorObservations = approvalDirectorEvent && approvalDirectorEvent.observations;

			this.recruiter = this.lead.recruiter;

			this.calculateStep();
		},
		calculateStep() {
			if (
				[
					"toAssign",
					"recruiterRejected",
					"interviewDoneByRecruiter",
					"interviewDoneByLead",
					"presentationDoneByRecruiter",
					"presentationDoneByLead",
					"approvalDoneByRecruiter",
					"approvalDoneByLead",
				].includes(this.lead.stage)
			)
				this.step = 0;
			if (["recruiterAssigned"].includes(this.lead.stage)) this.step = this.maxStep = 1;
			if (["recruiterAccepted"].includes(this.lead.stage)) this.step = this.maxStep = 2;
			if (
				[
					"contactsContacted",
					"interviewScheduled",
					"interviewMissedByRecruiter",
					"interviewMissedByLead",
				].includes(this.lead.stage)
			)
				this.step = this.maxStep = 3;
			if (
				[
					"interviewDone",
					"presentationScheduled",
					"presentationMissedByRecruiter",
					"presentationMissedByLead",
				].includes(this.lead.stage)
			)
				this.step = this.maxStep = this.company === "realtyart" ? 5 : 4;
			if (
				["presentationDone", "approvalScheduled", "approvalMissedByDirector", "approvalMissedByLead"].includes(
					this.lead.stage,
				)
			)
				this.step = this.maxStep = 5;
			if (["approvalDone"].includes(this.lead.stage)) this.step = this.maxStep = 6;

			if (this.step === 5 || this.step === 6) this.handleGetUsers();

			if (this.overrideStep) this.step = this.overrideStep;
		},
		async handleGetUsers() {
			const response = await getUsers(0, 10000, "name", false, { active: true, all: true });

			if (response.status === 200) {
				this.recruiters = response.data.users.filter(u =>
					["recruiter", "expansionDirector", "commercialDirector", "broker", "ceo"].includes(u.role),
				);
				this.commercialDirectors = response.data.users.filter(u =>
					["commercialDirector", "broker", "ceo", "integration", "hr"].includes(u.role),
				);
				this.consultants = response.data.users.filter(u => ["consultant", "teamLeader"].includes(u.role));
			}
		},
		getContactIcon(contact) {
			switch (contact.subType) {
				case "phone":
					return contact.accepted ? "mdi-phone-in-talk" : "mdi-phone-missed";
				case "message":
					return contact.accepted ? "mdi-message-bulleted" : "mdi-message-bulleted-off";
				case "email":
					return contact.accepted ? "mdi-email" : "mdi-email-alert";
				case "socialNetwork":
					return "mdi-facebook-messenger";
			}
		},
		setSideForm(type) {
			this.sideFormType = type;

			if (type === "interview" || type === "presentation" || type === "approval") {
				const lastInterview =
					type === "approval"
						? this.approvals[this.approvals.length - 1]
						: type === "presentation"
						? this.presentations[this.presentations.length - 1]
						: this.interviews[this.interviews.length - 1];

				if (lastInterview && lastInterview.subType === "scheduled") {
					this.selectedInterview = lastInterview;
					this.sideFormType =
						type === "approval" ? "approvalEdit" : type === "presentation" ? "presentationEdit" : "interviewEdit";
				}
			}

			this.openSideForm = true;
		},
		handleAddContact(
			contactType,
			contacted,
			contactDeclinedBy,
			justification,
			observations,
			afterContact,
			date,
			recruiter,
		) {
			const newContact = {
				subType: contactType,
				accepted: contacted,
				declinedBy: contactDeclinedBy,
				justification,
				date,
				recruiter,
				observations,
				afterContact,
			};

			this.newContacts.push(newContact);
			this.contacts.push(newContact);

			this.openSideForm = false;
		},
		handleDeleteContact(item) {
			this.contacts = this.contacts.filter(c => c.date !== item.date);
			this.newContacts = this.newContacts.filter(i => i.date !== item.date);
		},
		handleAddInterview(interviewDate, interviewTime, date, recruiter) {
			const newInterview = {
				subType: "scheduled",
				date,
				recruiter,
				scheduledDate: dayjs(`${interviewDate} ${interviewTime}`),
			};

			this.newInterviews.push(newInterview);
			this.interviews.push(newInterview);
			this.presentations.push(newInterview);
			this.approvals.push(newInterview);

			this.openSideForm = false;
		},
		handleEditInterview(interviewDone, interviewDeclinedBy, interviewJustification, observations, accepted) {
			const newInterview = {
				subType: interviewDone ? "done" : "missed",
				date: this.selectedInterview.scheduledDate,
				recruiter: this.recruiter,
				declinedBy: interviewDeclinedBy,
				justification: interviewJustification,
				observations,
				accepted,
			};

			this.newInterviews.push(newInterview);
			this.interviews.push(newInterview);
			this.presentations.push(newInterview);
			this.approvals.push(newInterview);

			this.openSideForm = false;
		},
		handleDeleteInterview(item) {
			this.interviews = this.interviews.filter(i => i.date !== item.date);
			this.presentations = this.presentations.filter(i => i.date !== item.date);
			this.approvals = this.approvals.filter(i => i.date !== item.date);
			this.newInterviews = this.newInterviews.filter(i => i.date !== item.date);
		},
		isFormValid() {
			switch (this.step) {
				case 1:
					if (typeof this.recruiterAccepted !== "boolean") {
						this.openToast({ message: "Faltam campos obrigatórios", color: "red" });

						return false;
					}
					break;
			}

			if (!this.$refs[`form${this.step}`].validate()) {
				this.openToast({ message: "Faltam campos obrigatórios", color: "red" });

				return false;
			}

			return true;
		},
		async handleAddEvent() {
			this.loading = true;

			const events = [];

			if (!this.isFormValid()) return;

			switch (this.step) {
				case 1:
					if (!this.lead.events.find(e => e.type === "recruiter" && e.subType === "acceptance")) {
						events.push({
							type: "recruiter",
							subType: "acceptance",
							date: dayjs(`${this.date} ${this.time}`),
							accepted: this.recruiterAccepted,
							observations: this.recruiterAcceptedObservations,
							recruiter: this.recruiter,
						});
					}
					break;
				case 2:
					for (const contact of this.newContacts) {
						events.push({
							type: "contact",
							subType: contact.subType,
							date: contact.date,
							accepted: contact.accepted,
							declinedBy: contact.declinedBy,
							justification: contact.justification,
							observations: contact.observations,
							recruiter: contact.recruiter,
						});
					}
					break;
				case 3:
					for (const interview of this.newInterviews) {
						events.push({
							type: "interview",
							subType: interview.subType,
							date: interview.date,
							scheduledDate: interview.scheduledDate,
							accepted: interview.accepted,
							declinedBy: interview.declinedBy,
							justification: interview.justification,
							observations: interview.observations,
							recruiter: interview.recruiter,
						});
					}
					break;
				case 4:
					for (const interview of this.newInterviews) {
						events.push({
							type: "presentation",
							subType: interview.subType,
							date: interview.date,
							scheduledDate: interview.scheduledDate,
							accepted: interview.accepted,
							declinedBy: interview.declinedBy,
							justification: interview.justification,
							observations: interview.observations,
							recruiter: interview.recruiter,
						});
					}
					break;
				case 5:
					for (const approval of this.newInterviews) {
						events.push({
							type: "approval",
							subType: approval.subType,
							date: approval.date,
							scheduledDate: approval.scheduledDate,
							accepted: approval.accepted,
							declinedBy: approval.declinedBy,
							justification: approval.justification,
							observations: approval.observations,
							recruiter: approval.recruiter,
						});
					}
					break;
				case 6:
					events.push({
						type: "recruited",
						subType: "done",
						date: dayjs(`${this.date} ${this.time}`),
						accepted: true,
						recruiter: this.recruiter,
					});
					break;
			}

			if (events.length) {
				await addEvents(this.lead._id, events);
			}

			if (this.step === 5 && !this.lead.commercialDirector && this.commercialDirector) {
				await editLead({ ...this.lead, commercialDirector: this.commercialDirector });
			}

			this.newContacts = [];
			this.newInterviews = [];
			this.date = dayjs().format("YYYY-MM-DD");
			this.time = dayjs().format("HH:mm");

			this.onClose();

			this.loading = false;
		},
		async handlePatchUser() {
			this.loading = true;

			await patchUser({ _id: this.consultant, lead: this.lead._id, source: this.lead.source });

			this.handleAddEvent();

			this.loading = false;
		},
	},
});
</script>
