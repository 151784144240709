<template>
	<DataTable
		type="findings"
		:featuresMap="{
			add: features.addFinding,
			edit: features.editFinding,
			delete: features.deleteFinding,
			listTotals: features.listFindingTotals,
		}"
		title="Angariações"
		:filtersConfig="filtersConfig"
		:getItems="getFindings"
		:getFilters="getFindingsFilters"
		:deleteItem="deleteFinding"
		:actions="['comments', 'changes', 'edit', 'delete']"
		:profileOverwrite="profileOverwrite"
		sortByOverwrite="findingDate"
	>
		<template v-slot:detail="{ selectedItem, closeDetail }">
			<FindingDetail :finding="selectedItem" :onClose="closeDetail" />
		</template>
	</DataTable>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";

import DataTable from "../components/DataTable.vue";
import FindingDetail from "../components/FindingDetail";

import { getFindings, getFindingsFilters, deleteFinding } from "../api/findings";

export default Vue.extend({
	name: "Findings",
	props: ["profileOverwrite"],
	components: { DataTable, FindingDetail },
	computed: mapState(["features"]),
	data() {
		return {
			filtersConfig: [
				{ type: "autocomplete", label: "Consultores", value: "consultants", condition: !this.profileOverwrite },
				{
					type: "autocomplete",
					label: "Equipas",
					value: "teams",
					items: "config.teams",
					itemValue: "key",
					condition: !this.profileOverwrite,
				},
				{
					type: "autocomplete",
					label: "Diretores Comerciais",
					value: "commercialDirectors",
					condition: !this.profileOverwrite,
				},
				{ type: "divider" },
				{ type: "autocomplete", label: "Estado", value: "state", items: "config.states", itemValue: "key" },
				{ type: "textField", label: "Morada", value: "address" },
				{ type: "textField", label: "Código Postal", value: "postalCode", twoCol: true },
				{
					type: "autocomplete",
					label: "Cidade",
					value: "city",
					items: "cities",
					itemText: "_id",
					itemValue: "_id",
					twoCol: true,
				},
				{
					type: "autocomplete",
					label: "Tipo de Imóvel",
					value: "houseType",
					items: "config.houseTypes",
					itemValue: "key",
				},
				{ type: "autocomplete", label: "Tipologia", value: "rooms", itemText: "_id", itemValue: "_id" },
				{ type: "textField", label: "Área mínima", value: "minArea", textType: "number", twoCol: true },
				{ type: "textField", label: "Área máxima", value: "maxArea", textType: "number", twoCol: true },
			],
		};
	},
	methods: { getFindings, getFindingsFilters, deleteFinding },
});
</script>
