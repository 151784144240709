<template>
	<div>
		<div style="position: fixed; bottom: 80px; left: 20px; z-index: 2">
			<v-sheet
				v-show="open"
				:color="$vuetify.theme.dark ? '#333' : '#f2f2f2'"
				:style="`border-radius: 4px; border: 1px solid grey; width: 315px`"
			>
				<v-date-picker
					v-model="selectedDate"
					:events="tasks.map(t => formatDate(t.date, 'YYYY-MM-DD'))"
					color="primary"
					style="margin: 10px 10px 0px 10px"
				/>
				<Tasks
					:handleGetTasksOverwrite="handleGetTasks"
					:tasksOverwrite="tasks"
					:maxHeight="maxHeight"
					:selectedDate="selectedDate"
				/>
			</v-sheet>
			<v-icon v-if="open" color="grey" style="transform: rotate(180deg); float: left; left: 15px; bottom: 3px">
				mdi-triangle
			</v-icon>
		</div>
		<v-badge
			:value="tasks.filter(t => dayjs().isAfter(t.date)).length"
			:content="tasks.filter(t => dayjs().isAfter(t.date)).length"
			overlap
			color="red"
			style="position: fixed; bottom: 20px; left: 20px; z-index: 2"
		>
			<v-btn color="primary darken-1" fab @click="() => (open = !open)">
				<v-icon v-if="open">mdi-close</v-icon>
				<v-icon v-else>mdi-clipboard-text</v-icon>
			</v-btn>
		</v-badge>
	</div>
</template>

<script>
import Vue from "vue";
import dayjs from "dayjs";

import Tasks from "./Tasks";

import { getTasks } from "../api/tasks";

import { formatDate } from "../utils/utils";

export default Vue.extend({
	name: "ToDo",
	components: { Tasks },
	async created() {
		setInterval(() => {
			const currentTasks = this.tasks.filter(t => dayjs().isSame(t.date, "minute"));

			if (currentTasks.length) this.open = true;
		}, 1000 * 45);

		window.addEventListener("keydown", e => {
			if (
				/textarea|select|input/i.test(event.target.nodeName) ||
				["text", "password"].includes(event.target.type)
			) {
				return;
			}

			if (e.key === "Escape") this.open = false;
		});
	},
	watch: {
		group: function () {
			this.search = "";
			this.refs = [];
		},
	},
	data() {
		return {
			open: false,

			selectedDate: formatDate(dayjs(), "YYYY-MM-DD"),

			tasks: [],

			maxHeight: 0,
		};
	},
	mounted() {
		window.onresize = this.setMaxHeight;
	},
	methods: {
		dayjs,
		formatDate,
		setMaxHeight() {
			if (this.timer) {
				clearTimeout(this.timer);
				this.timer = null;
			}
			this.timer = setTimeout(() => {
				this.maxHeight = window.innerHeight - 165 - 385;
			}, 500);
		},
		async handleGetTasks() {
			const response = await getTasks(null, true);

			if (response.status === 200) {
				this.tasks = response.data;

				if (this.tasks[this.tasks.length - 1]) {
					this.selectedDate = formatDate(this.tasks[this.tasks.length - 1].date, "YYYY-MM-DD");
				}
			}

			this.setMaxHeight();
		},
	},
});
</script>
