<template>
	<div style="height: 100%">
		<Dashboard v-if="token" />
		<Login v-else />
	</div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";

import Login from "./Login";
import Dashboard from "./Dashboard";

export default Vue.extend({
	name: "Home",
	components: {
		Login,
		Dashboard,
	},
	computed: mapState(["token"]),
});
</script>
