import { api } from "../utils/request";

async function getGoals(active, group, sortBy, sortDesc) {
	let query = "";
	query += active ? `${query ? "&" : "?"}active=${active}` : "";
	query += group ? `${query ? "&" : "?"}group=${group}` : "";
	query += sortBy ? `${query ? "&" : "?"}sortBy=${sortBy}` : "";
	query += sortDesc ? `${query ? "&" : "?"}sortDesc=${sortDesc}` : "";

	const res = await api({
		method: "get",
		url: `/api/goals${query}`,
	});

	return res;
}

async function addGoal(goal) {
	const res = await api({
		method: "post",
		url: "/api/goals",
		data: goal,
	});

	return res;
}

async function editGoal(goal) {
	const res = await api({
		method: "put",
		url: `/api/goals/${goal._id}`,
		data: goal,
	});

	return res;
}

async function patchGoal(goal) {
	const res = await api({
		method: "patch",
		url: `/api/goals/${goal._id}`,
		data: goal,
	});

	return res;
}

async function deleteGoal(id) {
	const res = await api({
		method: "delete",
		url: `/api/goals/${id}`,
	});

	return res;
}

async function editComment(comment) {
	const res = await api({
		method: "put",
		url: `/api/goals/comments/${comment._id}`,
		data: comment,
	});

	return res;
}

async function deleteComment(id) {
	const res = await api({
		method: "delete",
		url: `/api/goals/comments/${id}`,
	});

	return res;
}

export { getGoals, addGoal, editGoal, patchGoal, deleteGoal, editComment, deleteComment };
