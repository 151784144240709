import { api } from "../utils/request";

async function getInvoices(page, perPage, sortBy, sortDesc, filters) {
	const res = await api({
		method: "post",
		url: "/api/invoices/search",
		data: { page, perPage, sortBy, sortDesc, filters },
	});

	return res;
}

async function addInvoice(invoice) {
	const res = await api({
		method: "post",
		url: "/api/invoices",
		data: invoice,
	});

	return res;
}

async function editInvoice(invoice) {
	const res = await api({
		method: "put",
		url: `/api/invoices/${invoice._id}`,
		data: invoice,
	});

	return res;
}

async function patchInvoice(invoice) {
	const res = await api({
		method: "patch",
		url: `/api/invoices/${invoice._id}`,
		data: invoice,
	});

	return res;
}

async function patchInvoices(invoices) {
	const res = await api({
		method: "patch",
		url: "/api/invoices",
		data: invoices,
	});

	return res;
}

async function deleteInvoice(id) {
	const res = await api({
		method: "delete",
		url: `/api/invoices/${id}`,
	});

	return res;
}

async function generateInvoice(id) {
	const res = await api({
		method: "post",
		url: `/api/invoices/${id}/generate`,
	});

	return res;
}

export { getInvoices, addInvoice, editInvoice, patchInvoice, patchInvoices, deleteInvoice, generateInvoice };
