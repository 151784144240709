<style>
.funnelTitle {
	color: grey;
}
.funnelNumber {
	font-size: 1.5em;
	font-weight: bold;
}

.label__value {
	font-weight: bold;
	color: var(--textColor) !important;
}

.label__title {
	color: #8f8467 !important;
}

.label__percentage {
	font-weight: normal !important;
	color: var(--textColor) !important;
}

.label__segment-percentages {
	background-color: var(--backgroundColor) !important;
}

.segment-percentage__list li {
	color: var(--textColor) !important;
}

.percentage__list-label {
	color: #8f8467 !important;
}

.svg-funnel-js__subLabel {
	color: var(--textColor) !important;
}
</style>

<template>
	<div v-if="loading" style="display: flex; gap: 10px">
		<v-skeleton-loader
			type="list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-two-line"
			width="150px"
		/>
		<v-skeleton-loader
			type="image,image,image"
			:width="($vuetify.breakpoint.width < 2150 ? $vuetify.breakpoint.width : 2150) - 185"
		/>
	</div>
	<div v-else style="display: flex; gap: 10px">
		<v-card style="width: 150px; padding: 0px 10px; text-align: left; border-left: 4px solid #8f8467">
			<span class="funnelTitle">Leads</span>
			<br />
			<span class="funnelNumber">{{ sums[0] }}</span>
			<br />
			<span class="funnelTitle">Agendados</span>
			<br />
			<span class="funnelNumber">{{ sums[1] }}</span>
			<br />
			<span class="funnelTitle">Entrevistados</span>
			<br />
			<span class="funnelNumber">{{ sums[2] }}</span>
			<br />
			<span class="funnelTitle">Recrutados</span>
			<br />
			<span class="funnelNumber">{{ sums[3] }}</span>
			<br />
			<span class="funnelTitle">Taxa de Conversão</span>
			<br />
			<span class="funnelNumber">
				{{ !isNaN((sums[3] / sums[0]) * 100) ? ((sums[3] / sums[0]) * 100).toFixed(2) : 0 }}%
			</span>
		</v-card>
		<v-card style="padding: 40px 0px">
			<VueFunnelGraph
				v-if="values.find(v => v.length)"
				:width="($vuetify.breakpoint.width < 2150 ? $vuetify.breakpoint.width : 2150) - 185"
				:height="500"
				:labels="labels"
				:values="values"
				:colors="colors"
				:sub-labels="subLabels"
				direction="horizontal"
				gradient-direction="horizontal"
				:animated="true"
				:display-percentage="true"
				:style="funnelStyles"
			/>
		</v-card>
	</div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";
import { VueFunnelGraph } from "vue-funnel-graph-js-node-sass-6";

import { getLeadTotals } from "../../api/stats";

export default Vue.extend({
	name: "FunnelGraph",
	props: ["personal", "recruiters", "selectedSources"],
	components: {
		VueFunnelGraph,
	},
	created() {
		this.handleGetTotals();

		this.setColors(this.$vuetify.theme.dark);
	},
	watch: {
		"$vuetify.theme.dark": function (darkMode) {
			this.setColors(darkMode);
		},
		selectedOffices: function () {
			this.handleGetTotals();
		},
		dateRange: function () {
			this.handleGetTotals();
		},
		personal: function () {
			this.handleGetTotals();
		},
		recruiters: function () {
			this.handleGetTotals();
		},
		selectedSources: function () {
			this.handleGetTotals();
		},
	},
	computed: mapState(["selectedOffices", "dateRange"]),
	data() {
		return {
			loading: false,

			labels: ["Leads", "Agendados", "Entrevistados", "Recrutados"],
			subLabels: [],
			values: [[], [], [], []],
			colors: [[]],
			sums: [0, 0, 0, 0],

			stats: [],

			funnelStyles: "top: 50%; transform: translateY(-50%)",
		};
	},
	methods: {
		async handleGetTotals() {
			this.loading = true;

			const response = await getLeadTotals("funnel", this.personal, this.recruiters, this.selectedSources);

			if (response.status === 200) {
				this.subLabels = [];
				this.colors = [];
				this.values = [[], [], [], []];
				this.sums = [0, 0, 0, 0];

				let i = 0;
				const blueToPick = ["rgb(100, 50, 250)", "rgb(50, 50, 250)"];
				const redToPick = ["rgb(250, 100, 50)", "rgb(250, 50, 50)"];
				for (const recruiter of response.data) {
					this.subLabels.push(recruiter._id.name);
					this.colors.push([blueToPick[i], redToPick[i]]);

					this.values[0].push(recruiter.recruiterAssigned);
					this.values[1].push(recruiter.interviewScheduled);
					this.values[2].push(recruiter.interviewDone);
					this.values[3].push(recruiter.recruited);

					this.sums[0] += recruiter.recruiterAssigned;
					this.sums[1] += recruiter.interviewScheduled;
					this.sums[2] += recruiter.interviewDone;
					this.sums[3] += recruiter.recruited;

					i = i ? 0 : 1;
				}
			}

			this.loading = false;
		},
		setColors(darkMode) {
			this.textColor = darkMode ? "#ffffff" : "#000000";
			this.backgroundColor = darkMode ? "#111" : "#f2f2f2";

			this.funnelStyles += `top: 50%; transform: translateY(-50%); --textColor: ${this.textColor}; --backgroundColor: ${this.backgroundColor}`;
		},
	},
});
</script>
