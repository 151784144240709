import { api } from "../utils/request";

async function addGmailAuth(code) {
	const res = await api({
		method: "post",
		url: "/api/gmail/auth",
		data: { code },
	});

	return res;
}

async function generateGmailDrafts() {
	const res = await api({
		method: "post",
		url: "/api/gmail/generate",
	});

	return res;
}

async function generateDCGmailDrafts() {
	const res = await api({
		method: "post",
		url: "/api/gmail/commercialDirectors",
	});

	return res;
}

async function generateSalaryReceipts(files) {
	const res = await api({
		method: "post",
		url: "/api/gmail/salary",
		data: files,
		headers: { "Content-Type": "multipart/form-data" },
	});

	return res;
}

async function generateFilesDraft(files) {
	const res = await api({
		method: "post",
		url: "/api/gmail/files",
		data: files,
	});

	return res;
}

async function generateReferencesDrafts(active) {
	let query = "";

	if (active) query += `${query ? "&" : "?"}active=${active}`;

	const res = await api({
		method: "post",
		url: `/api/gmail/references${query}`,
	});

	return res;
}

export {
	addGmailAuth,
	generateGmailDrafts,
	generateDCGmailDrafts,
	generateSalaryReceipts,
	generateFilesDraft,
	generateReferencesDrafts,
};
